import React from "react";
import { useShell } from "../../../shell/context";
import { useForgotPasswordByEmailScreen } from "./useForgotPasswordByEmailScreen";
import { ForgotPasswordByEmailView } from "../../../components/forgot-password/ForgotPasswordByEmailView";
import { KDPForgotPasswordByEmailView } from "../../../components/forgot-password/ForgotPasswordByEmailView.kdp";

export function ForgotPasswordByEmailScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useForgotPasswordByEmailScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPForgotPasswordByEmailView {...props} />;
    default:
      return <ForgotPasswordByEmailView {...props} />;
  }
}
