import { useCallback, useState } from "react";
import { useWorkflowNavigate, useWorkflowWebsocket } from "../shell/hooks";
import { workflowGet, WorkflowEvent } from "../states/interaction/api";
import { checkLoginLinkVerified } from "../states/interaction/steps";
import { WorkflowAuth, makeWorkflowAuthMapper } from "../states/workflows";
import { useInteractionStep } from "./interaction";
import { usePolling } from "./polling";

function filterWorkflowAuthLoginLink(
  workflow: WorkflowAuth
): WorkflowAuth | null {
  return workflow.current === "latte.NodeAuthenticateEmailLoginLink"
    ? workflow
    : null;
}

interface LoginLinkResult {
  error: unknown;
  setError: (value: unknown) => void;
  isProceeding: boolean;
}

export function useLoginLinkHandler(
  TAG: string,
  workflow: WorkflowAuth,
  screenIsActive: boolean
): LoginLinkResult {
  const [error, setError] = useState<unknown>(null);

  const loginLinkWorkflow = filterWorkflowAuthLoginLink(workflow);

  const navigateWorkflow = useWorkflowNavigate();

  const { trigger: proceed, isLoading: isProceeding } = useInteractionStep(
    workflow,
    checkLoginLinkVerified
  );

  const immediateProceedHandler = useCallback(
    (tag1: string) => {
      console.log(TAG, tag1, "immediateProceedHandler", "func-begin", {
        screenIsActive,
        isProceeding,
        current: loginLinkWorkflow?.current,
      });
      if (
        screenIsActive &&
        !isProceeding &&
        loginLinkWorkflow != null &&
        loginLinkWorkflow.current === "latte.NodeAuthenticateEmailLoginLink"
      ) {
        console.log(TAG, tag1, "immediateProceedHandler", "proceed-before", {
          screenIsActive,
          isProceeding,
          current: loginLinkWorkflow.current,
        });
        setError(null);
        proceed(loginLinkWorkflow).then(
          async (workflow) => navigateWorkflow(workflow),
          (err) => setError(err)
        );
      }
    },
    [
      TAG,
      isProceeding,
      loginLinkWorkflow,
      navigateWorkflow,
      proceed,
      screenIsActive,
    ]
  );

  const wsMessageHandler = useCallback(
    (_workflowEvent: WorkflowEvent) => {
      immediateProceedHandler("wsMessageHandler");
    },
    [immediateProceedHandler]
  );

  const pollingHandler = useCallback(async () => {
    console.log(TAG, "pollingHandler", "func-begin", {
      loginLinkWorkflow,
    });
    if (loginLinkWorkflow != null) {
      const mapper = makeWorkflowAuthMapper(
        loginLinkWorkflow.initiationIntent,
        loginLinkWorkflow.phoneNumber
      );
      console.log(TAG, "pollingHandler", "workflowGet-before", {
        loginLinkWorkflow,
      });
      try {
        const newWorkflow = await workflowGet(
          mapper,
          loginLinkWorkflow.workflowID,
          loginLinkWorkflow.instanceID
        );
        console.log(TAG, "pollingHandler", "workflowGet-then", {
          loginLinkWorkflow,
        });
        if (newWorkflow.loginLinkSubmitted === true) {
          immediateProceedHandler("pollingHandler");
        }
      } catch (err: unknown) {
        console.error(TAG, "pollingHandler", "workflowGet-catch", err);
      }
    }
  }, [TAG, immediateProceedHandler, loginLinkWorkflow]);

  // We tried fetchAndProceed on websocket open, but websocket takes indefinite time to open so that is not a viable option.
  // We tried fetchAndProceed on websocket error, but due to timing issue, the workflow we fetched is not up-to-date.
  // We tried fetchAndProceed on visibilitychange, but also due to timing issue, the workflow we fetched is not up-to-date.
  useWorkflowWebsocket(loginLinkWorkflow?.workflowID, wsMessageHandler);
  usePolling(pollingHandler, 2000);

  return { error, setError, isProceeding };
}
