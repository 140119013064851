import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../components/Button";
import { Dialog } from "../../../components/Dialog";
import { FormField } from "../../../components/FormField";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import { MobileScreenLayout } from "../../../components/MobileScreenLayout";
import { OTPInput } from "../../../components/OTPInput";
import { VerifyPhoneOTPScreenProps } from "./useVerifyPhoneOTPScreen";

export function VerifyPhoneOTPScreen(
  props: VerifyPhoneOTPScreenProps
): React.ReactElement {
  const {
    otp,
    otpInputRef,
    otpLength,
    errorDialogState,
    errorDialogContent,
    errorMessage,
    rateLimitState,
    isResending,
    isLoading,
    handleOTPOnChange,
    handleResend,
    handleFormSubmit,
    remainingSeconds,
    maskedPhoneNumber,
  } = props;

  const descriptionValues = useMemo(
    () => ({
      phoneNumber: (
        <span className="inline-block font-semibold text-body-1">
          {maskedPhoneNumber}
        </span>
      ),
    }),
    [maskedPhoneNumber]
  );
  const resendValues = useMemo(
    () => ({ remainingSeconds }),
    [remainingSeconds]
  );

  return (
    <form className="h-full" noValidate={true} onSubmit={handleFormSubmit}>
      <MobileScreenLayout>
        <MobileScreenLayout.Body>
          <h1 className="mb-1 font-heading text-heading-2">
            <FormattedMessage
              id="mobile.screens.auth.verifyPhoneOTP.title"
              defaultMessage="Verification"
            />
          </h1>
          <p className="mb-12 text-body-1">
            <FormattedMessage
              id="mobile.screens.auth.verifyPhoneOTP.description"
              defaultMessage="The verification code has been sent to {phoneNumber}"
              values={descriptionValues}
            />
          </p>

          <FormField className="py-2">
            <OTPInput
              className="w-full h-11"
              value={otp}
              onChange={handleOTPOnChange}
              maxLength={otpLength}
              required={true}
              autoFocus={true}
              disabled={rateLimitState !== "ok"}
              readOnly={isLoading}
              inputRef={otpInputRef}
            />
            <div className="h-14">
              {errorMessage ? (
                <FormField.ErrorMessage>{errorMessage}</FormField.ErrorMessage>
              ) : null}
            </div>
          </FormField>

          <Button
            variant="text"
            disabled={
              remainingSeconds > 0 ||
              isLoading ||
              rateLimitState === "blocked" ||
              isResending
            }
            onPress={handleResend}
          >
            {remainingSeconds === 0 ? (
              <FormattedMessage
                id="mobile.screens.auth.verifyPhoneOTP.resend.action"
                defaultMessage="Resend"
              />
            ) : (
              <FormattedMessage
                id="mobile.screens.auth.verifyPhoneOTP.resend.cooldown"
                defaultMessage="Resend in {remainingSeconds}s"
                values={resendValues}
              />
            )}
          </Button>
        </MobileScreenLayout.Body>
      </MobileScreenLayout>

      {isResending ? <LoadingOverlay /> : null}

      <Dialog state={errorDialogState} isDismissable={false}>
        {errorDialogContent}
      </Dialog>
    </form>
  );
}
