import React from "react";
import { useShell } from "../../../../shell/context";
import { useLoginSelectMFAMethodScreen } from "./useLoginSelectMFAMethodScreen";
import { LoginSelectMFAMethodScreen as Screen } from "./LoginSelectMFAMethodScreen";
import { LoginSelectMFAMethodScreen as KDPScreen } from "./LoginSelectMFAMethodScreen.kdp";

export function LoginSelectMFAMethodScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useLoginSelectMFAMethodScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPScreen {...props} />;
    default:
      return <Screen {...props} />;
  }
}
