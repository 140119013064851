import React from "react";
import { Outlet } from "react-router-dom";
import { WebWorkflowProvider } from "../../../shell/WebWorkflowProvider";
import { initWorkflowSetupPassword } from "../../../states/interaction/intents";

export function SetupPasswordInitScreen(): React.ReactElement {
  return (
    <WebWorkflowProvider initializer={initWorkflowSetupPassword}>
      <Outlet />
    </WebWorkflowProvider>
  );
}
