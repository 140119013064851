import { useDeclareScreenName } from "../../../tracking/hooks";
import { useWorkflow } from "../../../shell/workflow";
import { WorkflowResetPassword } from "../../../states/workflows";
import { useCallback } from "react";
import { useWorkflowNavigate } from "../../../shell/hooks";
import { Brand } from "../../../shell/brand";
import { AppScreen, getScreenName } from "../../../tracking/screen";

export interface ResetPasswordSuccessScreenProps {
  finishURI: string | undefined;
  handleActionOnPress: () => void;
}

export function useResetPasswordSuccessScreen(
  bu: Brand | null
): ResetPasswordSuccessScreenProps {
  useDeclareScreenName(getScreenName(bu, AppScreen.ResetPasswordSuccess));

  const workflow = useWorkflow<WorkflowResetPassword>(
    "latte.NodeDoResetPasswordByCode"
  );
  const navigateWorkflow = useWorkflowNavigate();

  const handleActionOnPress = useCallback(() => {
    document.dispatchEvent(
      new CustomEvent("latte:event", {
        detail: { type: "resetPasswordCompleted" },
      })
    );
    if (!workflow.finishURI) {
      // Should not have button
      console.error("reset password success: No redirect uri available");
    } else {
      (async () => {
        await navigateWorkflow(workflow);
      })().catch((err) => console.error(err));
    }
  }, [navigateWorkflow, workflow]);

  return { finishURI: workflow.finishURI, handleActionOnPress };
}
