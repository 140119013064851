import React, { useCallback, useState } from "react";
import { defineMessage } from "react-intl";
import {
  OverlayTriggerState,
  useOverlayTriggerState,
} from "@react-stately/overlays";
import { useShell } from "../../shell/context";
import { useAppNavigate, useWorkflowNavigate } from "../../shell/hooks";
import { excludeWorkflowParams, routes } from "../../shell/routes";
import { WorkflowError } from "../../states/interaction/errors";
import { verifyMFAPassword } from "../../states/interaction/steps";
import { WorkflowAuth } from "../../states/workflows";
import { AppError } from "../../errors";
import { useErrorMessage } from "../errors";
import { useInteractionStep } from "../interaction";
import { REDIRECT_URI } from "../../search";
import { AccountLockedError } from "../../components/AccountLockedError";
import { ForgotPasswordNavigationState } from "../forgot-password/useSendForgotPasswordCodeV2";
import { incorrectPasswordWarningThreshold } from "../../config";

const warningThreshold = incorrectPasswordWarningThreshold;

export interface LoginVerifyMFAPasswordState {
  password: string;
  onChangePassword: (password: string) => void;
  warningThreshold: number;
  failedAttempts: number;
  isMaximumAttemptExceeded: boolean;
  error: unknown;
  errorMessage: string;
  errorDialogState: OverlayTriggerState;
  errorDialogContent: React.ReactNode;
  handleVerifyMFAPassword: (e: React.FormEvent) => void;
  isLoading: boolean;
  handleForgotPasswordRedirect: () => void;
}

export const useLoginVerifyMFAPassword = (
  workflow: WorkflowAuth
): LoginVerifyMFAPasswordState => {
  const navigateWorkflow = useWorkflowNavigate();
  const navigate = useAppNavigate();
  const platform = useShell().platform;

  const [error, setError] = useState<unknown>(null);
  const errorMessage = useErrorMessage(error);
  const errorDialogState = useOverlayTriggerState({
    defaultOpen: false,
  });
  const [errorDialogContent, setErrorDialogContent] =
    useState<React.ReactNode>(null);

  const [failedAttempts, setFailedAttempts] = useState<number>(0);
  const [isMaximumAttemptExceeded, setIsMaximumAttemptExceeded] =
    useState(false);

  const [password, setPassword] = useState("");
  const onChangePassword = useCallback(
    (password: string) => {
      setPassword(password);
      if (!isMaximumAttemptExceeded) {
        setError(null);
      }
    },
    [isMaximumAttemptExceeded]
  );

  const handleForgotPasswordRedirect = useCallback(() => {
    const query = new URLSearchParams(window.location.search);
    query.delete(REDIRECT_URI);
    const newState: ForgotPasswordNavigationState = {
      phoneNumber: workflow.phoneNumber,
      maskedEmail: workflow.maskedEmail ?? "",
      backWorkflow: null,
    };
    navigate(
      {
        pathname:
          platform === "mobile"
            ? routes.mobile.recovery.forgotPassword.email
            : routes.web.recovery.forgotPassword.email,
        search: excludeWorkflowParams(query).toString(),
      },
      { state: newState }
    );
  }, [navigate, platform, workflow.maskedEmail, workflow.phoneNumber]);

  const { trigger, isLoading } = useInteractionStep(
    workflow,
    verifyMFAPassword
  );

  const handleError = useCallback(
    (err: unknown) => {
      if (err instanceof WorkflowError) {
        const currentAttemptCount = failedAttempts + 1;
        setFailedAttempts(currentAttemptCount);

        if (err.reason === "RateLimited" || err.reason === "AccountLockout") {
          switch (platform) {
            case "mobile":
              errorDialogState.open();
              setErrorDialogContent(<AccountLockedError />);
              break;
            case "web":
              setIsMaximumAttemptExceeded(true);
              setError(
                new AppError(
                  {
                    message: defineMessage({
                      id: "mobile.errors.app.loginPasswordMaxAttemptExceeded",
                      defaultMessage:
                        "You have exceeded password attempt limit. Your account will be locked for 5 minutes.",
                    }),
                  },
                  {
                    message: defineMessage({
                      id: "web.errors.app.loginPasswordMaxAttemptExceeded",
                      defaultMessage:
                        "You have exceeded password attempt limit. Your account will be locked for 5 minutes.",
                    }),
                  }
                )
              );
              break;
          }
        } else if (currentAttemptCount >= warningThreshold) {
          setError(
            new AppError(
              {
                message: defineMessage({
                  id: "mobile.errors.app.multipleInvalidPasswordAttempts",
                  defaultMessage:
                    "Incorrect password. Please reset your password if you lost it.",
                }),
              },
              {
                message: defineMessage({
                  id: "web.errors.app.multipleInvalidPasswordAttempts",
                  defaultMessage:
                    "Incorrect password. Please reset your password if you lost it.",
                }),
              }
            )
          );
        } else {
          setError(err);
        }
      } else {
        setError(err);
      }
    },
    [failedAttempts, platform, errorDialogState]
  );

  const handleVerifyMFAPassword = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (isMaximumAttemptExceeded) {
        return;
      }

      (async () => {
        setError(null);

        try {
          await navigateWorkflow(await trigger(workflow, password));
        } catch (err: unknown) {
          handleError(err);
        }
      })().catch(() => {});
    },
    [
      isMaximumAttemptExceeded,
      trigger,
      workflow,
      password,
      navigateWorkflow,
      handleError,
    ]
  );

  return {
    password,
    onChangePassword,
    warningThreshold,
    failedAttempts,
    isMaximumAttemptExceeded,
    error,
    errorMessage,
    errorDialogState,
    errorDialogContent,
    handleVerifyMFAPassword,
    isLoading,
    handleForgotPasswordRedirect,
  };
};
