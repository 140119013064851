import React from "react";
import { useShell } from "../../../shell/context";
import { useVerifyEmailOTPScreen } from "./useVerifyEmailOTPScreen";
import { VerifyEmailOTPScreen as Screen } from "./VerifyEmailOTPScreen";
import { VerifyEmailOTPScreen as KDPScreen } from "./VerifyEmailOTPScreen.kdp";

export function VerifyEmailOTPScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useVerifyEmailOTPScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPScreen {...props} />;
    default:
      return <Screen {...props} />;
  }
}
