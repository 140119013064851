import { usePreventScroll } from "@react-aria/overlays";
import { Helmet } from "react-helmet";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ScreenStack } from "../components/ScreenStack";
import { ShellContextProvider } from "./context";
import { NavigationBar } from "./NavigationBar";
import { useTrackScreenView } from "../tracking/hooks";
import { defineMessage, useIntl } from "react-intl";
import { useUserAgent } from "../hooks/user-agent";
import { AppCancelError } from "../errors";
import { SemVer } from "../utils/semver";

function TrackPageView() {
  // We cannot call useTrackScreenView in MobileShell because
  // We need ShellContextProvider.
  useTrackScreenView();
  return null;
}

function useBlockUnsupportedOS() {
  const ua = useUserAgent();
  const os = ua.getOS();
  if (os.name === "iOS" && os.version != null) {
    const version = SemVer.parse(os.version);
    if (version == null) {
      // Don't know what version, ignore it
      return;
    }
    if (
      version.compare(new SemVer(14)) >= 0 &&
      version.compare(new SemVer(14, 5)) < 0
    ) {
      const title = defineMessage({
        id: "mobile.errors.unsupportedOS.title",
        defaultMessage: "iOS Version not supported",
      });
      const message = defineMessage({
        id: "mobile.errors.unsupportedOS.message",
        defaultMessage: "Please upgrade iOS version to 14.5 or above.",
      });
      throw new AppCancelError(
        {
          title,
          message,
        },
        {
          title,
          message,
        }
      );
    }
  }
}

interface IMobileShellContext {
  onBack?: () => void;
  registerOnBack: (fn: () => void) => () => void;
}

export const MobileShellContext = React.createContext<
  IMobileShellContext | undefined
>(undefined);

export function useMobileShellContext(): IMobileShellContext {
  const ctx = useContext(MobileShellContext);
  if (ctx == null) {
    throw new Error("MobileShellContext: no provider");
  }
  return ctx;
}

export function useBackCallback(fn?: () => void): void {
  const { registerOnBack } = useMobileShellContext();

  useEffect(() => {
    if (fn == null) {
      return () => {};
    }
    return registerOnBack(fn);
  }, [fn, registerOnBack]);
}

export const MobileShell: React.FC = () => {
  const visualViewport = window.visualViewport!;
  const [keyboardHeight, setKeyboardHeight] = useState(
    window.innerHeight - visualViewport.height
  );
  const intl = useIntl();

  const [onBack, setOnBack] = useState<(() => void) | undefined>(undefined);
  const registerOnBack = useCallback((fn: () => void) => {
    setOnBack(() => fn);
    return () => {
      setOnBack((currentFn) => {
        if (currentFn === fn) {
          return undefined;
        }
        return currentFn;
      });
    };
  }, []);

  useBlockUnsupportedOS();

  useEffect(() => {
    const handler = () => {
      setKeyboardHeight(window.innerHeight - visualViewport.height);
    };

    visualViewport.addEventListener("resize", handler);
    visualViewport.addEventListener("scroll", handler);
    return () => {
      visualViewport.removeEventListener("resize", handler);
      visualViewport.removeEventListener("scroll", handler);
    };
  }, [visualViewport]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--keyboard-height",
      `${keyboardHeight}px`
    );
  }, [keyboardHeight]);

  usePreventScroll();

  const mobileShellContextValue = useMemo<IMobileShellContext>(() => {
    return {
      onBack,
      registerOnBack,
    };
  }, [onBack, registerOnBack]);

  return (
    <MobileShellContext.Provider value={mobileShellContextValue}>
      <ShellContextProvider platform="mobile">
        <Helmet>
          <title>
            {intl.formatMessage({
              id: "mobile.title",
              defaultMessage: "One ID | New World Development",
            })}
          </title>
        </Helmet>
        <TrackPageView />
        <div className="mx-auto max-w-3xl h-full flex flex-col">
          <div className="bg-white">
            <NavigationBar className="h-11" />
          </div>
          <ScreenStack className="flex-1 min-h-0" type="screen" skip={1} />
        </div>
      </ShellContextProvider>
    </MobileShellContext.Provider>
  );
};
