import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { ReactComponent as IconMessage } from "../../assets/message.svg";
import { AppNavigate } from "../../components/AppNavigate";
import { Button } from "../../components/Button";
import { MobileScreenLayout } from "../../components/MobileScreenLayout";
import { routes } from "../../shell/routes";
import { ForgotPasswordBySMSProceedViewProps } from "./viewmodel";

export function ForgotPasswordBySMSProceedView(
  props: ForgotPasswordBySMSProceedViewProps
): React.ReactElement {
  const {
    currentWorkflow,
    maskedPhoneNumber,
    canBack,
    handleActionOnPress,
    handleBackActionOnPress,
  } = props;
  const { search } = useLocation();

  const descriptionValues = useMemo(
    () => ({
      maskedPhoneNumber: (
        <>
          <wbr />
          <b className="inline-block whitespace-normal">{maskedPhoneNumber}</b>
          <wbr />
        </>
      ),
    }),
    [maskedPhoneNumber]
  );

  if (
    currentWorkflow !== "latte.NodeSendForgotPasswordCode" ||
    !maskedPhoneNumber
  ) {
    return (
      <AppNavigate
        to={{
          pathname: routes.mobile.recovery.forgotPassword.email,
          search: search,
        }}
        replace={true}
      />
    );
  }

  return (
    <MobileScreenLayout>
      <MobileScreenLayout.Body>
        <div className="flex flex-col justify-center h-full text-center">
          <IconMessage className="self-center text-c-button-primary w-25 h-25" />

          <h1 className="my-2 font-heading text-heading-2">
            <FormattedMessage
              id="mobile.screens.forgotPasswordProceed.sms.title"
              defaultMessage="We Just Sent You a SMS"
            />
          </h1>

          <p className="text-body-1 text-c-text-disabled px-2 whitespace-pre-wrap">
            <FormattedMessage
              id="mobile.screens.forgotPasswordProceed.sms.description"
              defaultMessage={
                "We just sent you a link at {maskedPhoneNumber}.\nPlease click the link from your SMS to reset your password."
              }
              values={descriptionValues}
            />
          </p>
        </div>
      </MobileScreenLayout.Body>
      <MobileScreenLayout.FixedFooter>
        <Button
          className="w-full"
          type="button"
          preventFocusOnPress={true}
          onPress={handleActionOnPress}
        >
          <FormattedMessage
            id="mobile.screens.forgotPasswordProceed.sms.action"
            defaultMessage="Check SMS"
          />
        </Button>
        {canBack ? (
          <Button
            className="w-full mt-2"
            variant="secondary"
            onPress={handleBackActionOnPress}
          >
            <FormattedMessage
              id="mobile.screens.forgotPasswordProceed.sms.back.action"
              defaultMessage="Back"
            />
          </Button>
        ) : null}
      </MobileScreenLayout.FixedFooter>
    </MobileScreenLayout>
  );
}
