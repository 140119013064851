import React from "react";
import { useShell } from "../../../shell/context";
import { useVerifyPhoneOTPScreen } from "./useVerifyPhoneOTPScreen";
import { VerifyPhoneOTPScreen as Screen } from "./VerifyPhoneOTPScreen";
import { VerifyPhoneOTPScreen as KDPScreen } from "./VerifyPhoneOTPScreen.kdp";

export function VerifyPhoneOTPScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useVerifyPhoneOTPScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPScreen {...props} />;
    default:
      return <Screen {...props} />;
  }
}
