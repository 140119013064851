import React from "react";
import { Outlet } from "react-router-dom";
import { WebWorkflowProvider } from "../../../shell/WebWorkflowProvider";
import { initWorkflowResetPassword } from "../../../states/interaction/intents";

export function WebResetPasswordInitScreen(): React.ReactElement {
  return (
    <WebWorkflowProvider initializer={initWorkflowResetPassword}>
      <Outlet />
    </WebWorkflowProvider>
  );
}
