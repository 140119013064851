import React from "react";
import { useShell } from "../../../shell/context";
import { useResetPasswordSuccessScreen } from "./useResetPasswordSuccessScreen";
import { ResetPasswordSuccessScreen as Screen } from "./ResetPasswordSuccessScreen";
import { ResetPasswordSuccessScreen as KDPScreen } from "./ResetPasswordSuccessScreen.kdp";

export function ResetPasswordSuccessScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useResetPasswordSuccessScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPScreen {...props} />;
    default:
      return <Screen {...props} />;
  }
}
