import { Transition } from "@headlessui/react";
import { Overlay } from "@react-aria/overlays";
import { OverlayTriggerState } from "@react-stately/overlays";
import React from "react";
import { DialogLayout } from "./DialogLayout";

interface DialogProps extends React.PropsWithChildren {
  state: OverlayTriggerState;
  isDismissable?: boolean;
}

export const Dialog: React.FC<DialogProps> = (props) => {
  const { state, isDismissable, children } = props;

  return (
    <Overlay>
      <Transition
        show={state.isOpen}
        className="fixed inset-0 isolate bg-black/50 z-50"
        enter="transition-opacity duration-300 ease-out"
        leave="transition-opacity duration-300 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveTo="opacity-0"
      >
        <DialogLayout.Body state={state} isDismissable={isDismissable}>
          {children}
        </DialogLayout.Body>
      </Transition>
    </Overlay>
  );
};
