import { WorkflowResult } from "./interaction/api";
import { AuthUserInitiate } from "./interaction/intents";
import { maskPhoneNumber } from "../utils/phone";

export interface Workflow {
  workflowID: string;
  instanceID: string;
  root: string;
  current: string;
  redirectURI?: string;
}

export interface WorkflowAuth extends Workflow {
  initiationIntent: AuthUserInitiate;
  intents: string[];
  phoneNumber: string;
  maskedPhoneNumber: string;

  resendResetTimestamp?: number;
  maskedEmail?: string;
  failedAttemptRateLimitExceeded?: boolean;
  loginLinkSubmitted?: boolean;
}

export interface WorkflowVerifyLoginLink extends Workflow {
  code: string;
}

export interface WorkflowVerifyUser extends Workflow {
  email: string;
  maskedEmail?: string;
  failedAttemptRateLimitExceeded?: boolean;
  resendResetTimestamp?: number;
  finishURI?: string;
}

export type ForgotPasswordChannel = "email" | "sms";

export interface WorkflowForgotPasswordV2 extends Workflow {
  channel: ForgotPasswordChannel;
  phoneNumber?: string;
  maskedEmail?: string;
  finishURI?: string;
}

export interface WorkflowReauthForgotPassword extends Workflow {
  email: string;
  phoneNumber: string;
  channel: ForgotPasswordChannel;
  finishURI?: string;
  backWorkflow: WorkflowReauthForgotPassword | null;
}

export interface WorkflowResetPassword extends Workflow {
  initiationIntent: "reset" | "setup";
  current:
    | "latte.IntentResetPassword"
    | "latte.NodeValidatedResetPasswordCode"
    | "latte.NodeDoResetPasswordByCode";
  finishURI?: string;
  userEmail?: string;
  userPhone?: string;
  userID?: string;
}

export interface WorkflowChangePassword extends Workflow {
  finishURI?: string;
}

export interface WorkflowChangeEmail extends Workflow {
  finishURI?: string;
  phoneNumber: string;
  maskedPhoneNumber: string;
}

export interface WorkflowMigrate extends Workflow {
  email?: string;
  maskedPhoneNumber?: string;
}

export interface WorkflowReauth extends Workflow {
  email: string;
  phoneNumber: string;
}

export function makeWorkflowAuthMapper(
  initiationIntent: AuthUserInitiate,
  phoneNumber: string
): (workflow: WorkflowResult) => WorkflowAuth {
  return (workflow) => {
    const data = workflow.data;
    const maskedEmails: string[] =
      (data.masked_emails as string[] | undefined) ?? [];

    return {
      workflowID: workflow.workflowID,
      instanceID: workflow.instanceID,
      root: workflow.root,
      current: workflow.current,
      redirectURI:
        workflow.action.type === "finish"
          ? workflow.action.redirect_uri
          : undefined,
      intents: workflow.intents,

      initiationIntent,
      phoneNumber,
      maskedPhoneNumber: maskPhoneNumber(phoneNumber),

      resendResetTimestamp: new Date(String(data.can_resend_at)).getTime(),
      maskedEmail: data.masked_email
        ? String(data.masked_email)
        : maskedEmails[0],
      failedAttemptRateLimitExceeded: Boolean(
        data.failed_attempt_rate_limit_exceeded
      ),
      loginLinkSubmitted: Boolean(data.login_link_submitted),
    };
  };
}

export function makeWorkflowVerifyLoginLinkMapper(
  code: string
): (workflow: WorkflowResult) => WorkflowVerifyLoginLink {
  return (workflow) => {
    return {
      workflowID: workflow.workflowID,
      instanceID: workflow.instanceID,
      root: workflow.root,
      current: workflow.current,
      code,
    };
  };
}

export function makeWorkflowMigrateMapper(
  email: string | undefined
): (workflow: WorkflowResult) => WorkflowMigrate {
  return (workflow) => {
    const data = workflow.data;
    const phoneNumber = (
      data.identity_migrate_specs as any[] | undefined
    )?.find((x) => x.type === "login_id" && x.login_id?.type === "phone")
      .login_id.value;

    return {
      workflowID: workflow.workflowID,
      instanceID: workflow.instanceID,
      root: workflow.root,
      current: workflow.current,
      redirectURI:
        workflow.action.type === "finish"
          ? workflow.action.redirect_uri
          : undefined,

      email: email,
      maskedPhoneNumber: phoneNumber
        ? maskPhoneNumber(String(phoneNumber))
        : undefined,
    };
  };
}

export function makeWorkflowReauthMapper({
  email,
  phoneNumber,
}: {
  email: string;
  phoneNumber: string;
}): (workflow: WorkflowResult) => WorkflowReauth {
  return (workflow) => {
    return {
      workflowID: workflow.workflowID,
      instanceID: workflow.instanceID,
      root: workflow.root,
      current: workflow.current,
      redirectURI:
        workflow.action.type === "finish"
          ? workflow.action.redirect_uri
          : undefined,
      email,
      phoneNumber,
    };
  };
}

export function makeWorkflowVerifyUserMapper({
  email,
  finishURI,
}: {
  email: string;
  finishURI?: string;
}): (workflow: WorkflowResult) => WorkflowVerifyUser {
  return (workflow) => {
    const data = workflow.data;
    return {
      workflowID: workflow.workflowID,
      instanceID: workflow.instanceID,
      root: workflow.root,
      current: workflow.current,
      redirectURI: workflow.action.type === "finish" ? finishURI : undefined,
      finishURI,

      email,
      maskedEmail: data.masked_email ? String(data.masked_email) : undefined,
      resendResetTimestamp: data.can_resend_at
        ? new Date(String(data.can_resend_at)).getTime()
        : undefined,
      failedAttemptRateLimitExceeded: data.failed_attempt_rate_limit_exceeded
        ? Boolean(data.failed_attempt_rate_limit_exceeded)
        : undefined,
    };
  };
}

export function makeWorkflowForgotPasswordV2Mapper({
  channel,
  phoneNumber,
  maskedEmail,
  finishURI,
}: {
  channel: ForgotPasswordChannel;
  phoneNumber: string;
  maskedEmail?: string;
  finishURI?: string;
}): (workflow: WorkflowResult) => WorkflowForgotPasswordV2 {
  return (workflow) => {
    return {
      workflowID: workflow.workflowID,
      instanceID: workflow.instanceID,
      root: workflow.root,
      current: workflow.current,
      redirectURI: workflow.action.type === "finish" ? finishURI : undefined,
      phoneNumber: phoneNumber,
      maskedEmail: maskedEmail,
      channel: channel,
      finishURI,
    };
  };
}

export function makeWorkflowReauthForgotPasswordMapper({
  channel,
  finishURI,
  email,
  phoneNumber,
  backWorkflow,
}: {
  channel: ForgotPasswordChannel;
  finishURI?: string;
  email: string;
  phoneNumber: string;
  backWorkflow: WorkflowReauthForgotPassword | null;
}): (workflow: WorkflowResult) => WorkflowReauthForgotPassword {
  return (workflow) => {
    const data = workflow.data;
    return {
      workflowID: workflow.workflowID,
      instanceID: workflow.instanceID,
      root: workflow.root,
      current: workflow.current,
      redirectURI: workflow.action.type === "finish" ? finishURI : undefined,
      maskedLoginID: data.masked_login_id
        ? String(data.masked_login_id)
        : undefined,
      channel: channel,
      finishURI,
      email,
      phoneNumber,
      backWorkflow,
    };
  };
}

export function makeWorkflowResetPasswordMapper(
  initiationIntent: "reset" | "setup",
  finishURI: string | undefined
): (workflow: WorkflowResult) => WorkflowResetPassword {
  return (workflow) => {
    const data = workflow.data;
    return {
      workflowID: workflow.workflowID,
      instanceID: workflow.instanceID,
      root: workflow.root,
      initiationIntent,
      current: workflow.current as WorkflowResetPassword["current"],
      redirectURI: workflow.action.type === "finish" ? finishURI : undefined,
      finishURI,
      userEmail: data.email ? String(data.email) : undefined,
      userPhone: data.phone ? String(data.phone) : undefined,
      userID: data.user_id ? String(data.user_id) : undefined,
    };
  };
}

export function makeWorkflowChangePasswordMapper(
  finishURI?: string
): (workflow: WorkflowResult) => WorkflowChangePassword {
  return (workflow) => {
    return {
      workflowID: workflow.workflowID,
      instanceID: workflow.instanceID,
      root: workflow.root,
      current: workflow.current,
      redirectURI: workflow.action.type === "finish" ? finishURI : undefined,
      finishURI,
    };
  };
}

export function makeWorkflowChangeEmailMapper(
  phoneNumber: string,
  finishURI?: string
): (workflow: WorkflowResult) => WorkflowChangeEmail {
  return (workflow) => {
    const data = workflow.data;
    return {
      workflowID: workflow.workflowID,
      instanceID: workflow.instanceID,
      root: workflow.root,
      current: workflow.current,
      redirectURI: workflow.action.type === "finish" ? finishURI : undefined,
      finishURI,

      phoneNumber,
      maskedPhoneNumber: maskPhoneNumber(phoneNumber),
      maskedEmail: data.masked_email ? String(data.masked_email) : undefined,
      resendResetTimestamp: data.can_resend_at
        ? new Date(String(data.can_resend_at)).getTime()
        : undefined,
      failedAttemptRateLimitExceeded: data.failed_attempt_rate_limit_exceeded
        ? Boolean(data.failed_attempt_rate_limit_exceeded)
        : undefined,
    };
  };
}
