import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { CaptchaField } from "../../../components/CaptchaField";
import { FormField } from "../../../components/FormField";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import { OTPInput } from "../../../components/OTPInput";
import { Button } from "../../../components/web/Button";
import { Dialog } from "../../../components/web/Dialog";
import { WebScreenLayout } from "../../../components/WebScreenLayout";
import { useVerifyCaptcha } from "../../../hooks/auth/useVerifyCaptcha";
import { useVerifyPhoneOTP } from "../../../hooks/auth/useVerifyPhoneOTP";
import { useRemainingSeconds } from "../../../hooks/timer";
import { useWorkflow } from "../../../shell/workflow";
import { useInteractionIsLoading } from "../../../states/states";
import { WorkflowAuth } from "../../../states/workflows";
import { useDeclareScreenName } from "../../../tracking/hooks";

// eslint-disable-next-line complexity
export function WebVerifyPhoneOTPScreen(): React.ReactElement {
  const workflow = useWorkflow<WorkflowAuth>(
    // NOTE(louis): The order DOES matter here.
    // We MUST order the expects in the reverse order we expect to see them in this screen.
    // This ensures we gets the "latest" workflow.
    "latte.NodeVerifyPhoneSMS",
    "latte.NodeAuthenticateOOBOTPPhone",
    "latte.IntentVerifyCaptcha"
  );

  const displayOTPField = workflow.current !== "latte.IntentVerifyCaptcha";
  const displayCaptcha = workflow.intents.includes("latte.IntentVerifyCaptcha");

  const screenName = useMemo(() => {
    if (displayCaptcha && !displayOTPField) {
      return "Auth-Captcha";
    }
    return "Auth-OTP";
  }, [displayCaptcha, displayOTPField]);
  useDeclareScreenName(screenName);

  const {
    otp,
    otpInputRef,
    otpLength,
    errorDialogState: otpErrorDialogState,
    errorDialogContent: otpErrorDialogContent,
    errorMessage: optErrorMessage,
    rateLimitState,
    isResending,
    isVerifying,
    handleOTPOnChange,
    handleResend,
    handleVerify,
  } = useVerifyPhoneOTP(workflow);

  const {
    onCaptchaSuccess,
    onCaptchaFailure,
    errorDialogContent: captchaErrorDialogContent,
    errorDialogState: captchaErrorDialogState,
  } = useVerifyCaptcha(workflow);

  const isLoading = useInteractionIsLoading();

  const handleFormSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      handleVerify();
    },
    [handleVerify]
  );

  const descriptionValues = useMemo(
    () => ({
      phoneNumber: (
        <span className="inline-block font-semibold text-body-1">
          {workflow.maskedPhoneNumber}
        </span>
      ),
    }),
    [workflow.maskedPhoneNumber]
  );

  const remainingSeconds = useRemainingSeconds(workflow.resendResetTimestamp!);
  const resendValues = useMemo(
    () => ({ remainingSeconds }),
    [remainingSeconds]
  );

  return (
    <form className="h-full" noValidate={true} onSubmit={handleFormSubmit}>
      <WebScreenLayout>
        <WebScreenLayout.Body>
          <h1 className="mb-3 font-heading text-heading-2">
            <FormattedMessage
              id="web.screens.auth.verifyPhoneOTP.title"
              defaultMessage="SMS Verification"
            />
          </h1>
          <div className="mb-12">
            <p className="text-center text-body-1">
              <FormattedMessage
                id="web.screens.auth.verifyPhoneOTP.description1"
                defaultMessage="Please enter the code sent to {phoneNumber} below to continue."
                values={descriptionValues}
              />
            </p>
          </div>

          {displayOTPField ? (
            <>
              <FormField className="w-full mb-6">
                <OTPInput
                  className="w-full h-11"
                  value={otp}
                  onChange={handleOTPOnChange}
                  maxLength={otpLength}
                  required={true}
                  readOnly={isLoading}
                  autoFocus={true}
                  disabled={rateLimitState !== "ok"}
                  inputRef={otpInputRef}
                />
                {optErrorMessage ? (
                  <FormField.ErrorMessage>
                    {optErrorMessage}
                  </FormField.ErrorMessage>
                ) : null}
              </FormField>

              <div className="flex flex-row w-full mb-12">
                <p className="mr-1 text-body-2">
                  <FormattedMessage
                    id="web.screens.auth.verifyPhoneOTP.resend.description"
                    defaultMessage="Don’t receive the code?"
                  />
                </p>
                <Button
                  variant="text"
                  disabled={
                    remainingSeconds > 0 ||
                    isLoading ||
                    rateLimitState === "blocked" ||
                    isResending
                  }
                  onPress={handleResend}
                >
                  {remainingSeconds === 0 ? (
                    <FormattedMessage
                      id="web.screens.auth.verifyPhoneOTP.resend.action"
                      defaultMessage="Resend code"
                    />
                  ) : (
                    <FormattedMessage
                      id="web.screens.auth.verifyPhoneOTP.resend.cooldown"
                      defaultMessage="Resend ({remainingSeconds}s)"
                      values={resendValues}
                    />
                  )}
                </Button>
              </div>
            </>
          ) : null}

          {displayCaptcha ? (
            <div className="mb-12">
              <CaptchaField
                onSuccess={onCaptchaSuccess}
                onFailure={onCaptchaFailure}
              />
            </div>
          ) : null}

          <Button
            variant="primary"
            disabled={
              !displayOTPField ||
              otp.length < otpLength ||
              rateLimitState === "blocked" ||
              isLoading
            }
            className="w-full"
            loading={isVerifying}
            onPress={handleVerify}
          >
            <FormattedMessage
              id="web.screens.auth.verifyPhoneOTP.action"
              defaultMessage="Continue"
            />
          </Button>
        </WebScreenLayout.Body>
      </WebScreenLayout>

      {isResending ? <LoadingOverlay /> : null}

      <Dialog state={otpErrorDialogState} isDismissable={false}>
        {otpErrorDialogContent}
      </Dialog>

      <Dialog state={captchaErrorDialogState} isDismissable={false}>
        {captchaErrorDialogContent}
      </Dialog>
    </form>
  );
}
