import {
  LoginVerifyMFAPasswordState,
  useLoginVerifyMFAPassword,
} from "../../../../hooks/auth/useLoginVerifyMFAPassword";
import { Brand } from "../../../../shell/brand";
import { useWorkflow } from "../../../../shell/workflow";
import { useInteractionIsLoading } from "../../../../states/states";
import { WorkflowAuth } from "../../../../states/workflows";
import { useDeclareScreenName } from "../../../../tracking/hooks";
import { AppScreen, getScreenName } from "../../../../tracking/screen";

export interface LoginVerifyMFAPasswordScreenProps
  extends LoginVerifyMFAPasswordState {
  phoneNumber: string;
}

export function useLoginVerifyMFAPasswordScreen(
  brand: Brand | null
): LoginVerifyMFAPasswordScreenProps {
  useDeclareScreenName(getScreenName(brand, AppScreen.LoginVerifyMFAPassword));

  const workflow = useWorkflow<WorkflowAuth>("latte.NodeAuthenticatePassword");

  const state = useLoginVerifyMFAPassword(workflow);
  const isInteractionLoading = useInteractionIsLoading();
  const isLoading = state.isLoading || isInteractionLoading;

  return {
    ...state,
    isLoading,
    phoneNumber: workflow.phoneNumber,
  };
}
