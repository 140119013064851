import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../../components/web/Button";
import { WebScreenLayout } from "../../../../components/WebScreenLayout";
import { ForgotPasswordByEmailProps } from "./useForgotPasswordByEmailScreen";

export function WebForgotPasswordByEmailScreen(
  props: ForgotPasswordByEmailProps
): React.ReactElement {
  const { maskedEmail, errorMessage, handleSendForgotPasswordCode, isLoading } =
    props;

  return (
    <WebScreenLayout>
      <WebScreenLayout.Body>
        <h1 className="font-heading text-heading-2">
          <FormattedMessage
            id="web.screens.forgotPassword.email.title"
            defaultMessage="Forgot your password?"
          />
        </h1>
        <p className="mt-3 text-center text-body-1">
          <FormattedMessage
            id="web.screens.forgotPassword.email.description"
            defaultMessage="Press Reset Password by Email and we will send you an email at {maskedEmail} to reset the password."
            values={{
              maskedEmail: (
                <>
                  <wbr />
                  <b className="inline-block whitespace-normal">
                    {maskedEmail}
                  </b>
                  <wbr />
                </>
              ),
            }}
          />
        </p>

        {errorMessage ? (
          <p className="mt-4 text-body-2 text-error">{errorMessage}</p>
        ) : null}
        <Button
          className="w-full mt-12"
          type="button"
          onPress={handleSendForgotPasswordCode}
          preventFocusOnPress={true}
          loading={isLoading}
        >
          <FormattedMessage
            id="web.screens.forgotPassword.email.action"
            defaultMessage="Reset Password by Email"
          />
        </Button>
      </WebScreenLayout.Body>
    </WebScreenLayout>
  );
}
