import { useState, useCallback, useMemo } from "react";
import { useAppNavigate } from "../../shell/hooks";
import { routes } from "../../shell/routes";
import { reauthSendForgotPasswordCode } from "../../states/interaction/steps";
import { useErrorMessage } from "../errors";
import { useInteractionStep } from "../interaction";
import { useLocation } from "react-router-dom";
import {
  ForgotPasswordChannel,
  WorkflowReauthForgotPassword,
} from "../../states/workflows";
import { useInteractionIsLoading } from "../../states/states";
import { maskEmail } from "../../utils/email";
import { maskPhoneNumber } from "../../utils/phone";

export interface ReauthForgotPasswordNavigationState {
  email: string;
  phoneNumber: string;
  backWorkflow: WorkflowReauthForgotPassword | null;
}

export interface SendReauthForgotPasswordCodeState {
  maskedLoginID: string;
  errorMessage: string;
  handleSendForgotPasswordCode: () => void;
  handleBack?: () => void;
  isLoading: boolean;
}

function getProceedPathname(channel: ForgotPasswordChannel) {
  return channel === "email"
    ? routes.mobile.reauth.forgotPasswordProceed.email
    : routes.mobile.reauth.forgotPasswordProceed.sms;
}

export const useSendReauthForgotPasswordCode = (
  workflow: WorkflowReauthForgotPassword,
  channel: ForgotPasswordChannel
): SendReauthForgotPasswordCodeState => {
  const location = useLocation();
  const navigate = useAppNavigate();

  const [error, setError] = useState<unknown>(null);
  const errorMessage = useErrorMessage(error);

  const { trigger } = useInteractionStep(
    workflow,
    reauthSendForgotPasswordCode
  );

  const isLoading = useInteractionIsLoading();

  const handleSendForgotPasswordCode = useCallback(() => {
    setError(null);

    Promise.resolve()
      .then(async () => trigger(workflow))
      .then(
        (workflow) => {
          const originalState = location.state ?? {};
          navigate(
            {
              pathname: getProceedPathname(channel),
              search: window.location.search,
            },
            { replace: true, state: { ...originalState, workflow } }
          );
        },
        (err) => {
          setError(err);
        }
      );
  }, [trigger, workflow, location.state, navigate, channel]);

  const handleBack = useMemo(() => {
    if (workflow.backWorkflow == null) {
      return undefined;
    }
    const backWorkflow = workflow.backWorkflow;
    return () => {
      const originalState = location.state ?? {};
      const pathname = getProceedPathname(backWorkflow.channel);
      navigate(
        {
          pathname,
          search: window.location.search,
        },
        { replace: true, state: { ...originalState, workflow: backWorkflow } }
      );
    };
  }, [workflow.backWorkflow, location.state, navigate]);

  const maskedLoginID = useMemo(() => {
    if (channel === "email") {
      return maskEmail(workflow.email);
    }
    return maskPhoneNumber(workflow.phoneNumber);
  }, [channel, workflow.email, workflow.phoneNumber]);

  return {
    maskedLoginID: maskedLoginID,
    errorMessage,
    handleSendForgotPasswordCode,
    handleBack,
    isLoading,
  };
};
