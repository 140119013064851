import React from "react";
import { useAbortInteraction } from "../hooks/interaction";
import { Button } from "./Button";
import css from "./DialogErrorContent.module.css";

interface DialogErrorContentProps {
  title: React.ReactNode;
  description: React.ReactNode;
  confirmButtonText: React.ReactNode;
  error?: unknown;
}

export const DialogErrorContent: React.FC<DialogErrorContentProps> = ({
  title,
  description,
  confirmButtonText,
  error,
}) => {
  const handleOKOnPress = useAbortInteraction(error);

  return (
    <div>
      <h2 className={css["title"]}>{title}</h2>
      <p className={css["description"]}>{description}</p>

      <Button className={css["action"]} onPress={handleOKOnPress}>
        {confirmButtonText}
      </Button>
    </div>
  );
};
