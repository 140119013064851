import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../components/Button";
import { MobileScreenLayout } from "../../components/MobileScreenLayout";
import { ForgotPasswordBySMSViewProps } from "./viewmodel";

export function KDPForgotPasswordBySMSView(
  props: ForgotPasswordBySMSViewProps
): React.ReactElement {
  const {
    maskedPhoneNumber,
    errorMessage,
    handleSendForgotPasswordCode,
    isLoading,
  } = props;

  return (
    <MobileScreenLayout>
      <MobileScreenLayout.Body>
        <h1 className="mb-2 font-heading text-heading-2">
          <FormattedMessage
            id="mobile.screens.forgotPassword.sms.title"
            defaultMessage="Forgot your password?"
          />
        </h1>
        <p className="mb-6 text-body-1">
          <FormattedMessage
            id="mobile.screens.forgotPassword.sms.description"
            defaultMessage="Press Reset Password by SMS and we will send you a SMS at {maskedPhoneNumber} to reset the password"
            values={{
              maskedPhoneNumber: (
                <>
                  <wbr />
                  <b className="inline-block whitespace-normal">
                    {maskedPhoneNumber}
                  </b>
                  <wbr />
                </>
              ),
            }}
          />
        </p>
        {errorMessage ? (
          <p className="mt-4 text-body-2 text-error">{errorMessage}</p>
        ) : null}
      </MobileScreenLayout.Body>

      <MobileScreenLayout.FixedFooter>
        <Button
          className="w-full"
          type="button"
          preventFocusOnPress={true}
          loading={isLoading}
          onPress={handleSendForgotPasswordCode}
        >
          <FormattedMessage
            id="mobile.screens.forgotPassword.sms.action"
            defaultMessage="Reset Password by SMS"
          />
        </Button>
      </MobileScreenLayout.FixedFooter>
    </MobileScreenLayout>
  );
}
