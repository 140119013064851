import React, { useCallback, useState } from "react";
import { useErrorMessage } from "../../../hooks/errors";
import { useInteractionStep } from "../../../hooks/interaction";
import { useValidateEmail } from "../../../hooks/validate-email";
import { useWorkflowNavigate } from "../../../shell/hooks";
import { useWorkflow } from "../../../shell/workflow";
import { changeEmail } from "../../../states/interaction/steps";
import { useInteractionIsLoading } from "../../../states/states";
import { WorkflowChangeEmail } from "../../../states/workflows";
import { useDeclareScreenName } from "../../../tracking/hooks";
import { validateEmail } from "../../../utils/email";
import { Brand } from "../../../shell/brand";
import { AppScreen, getScreenName } from "../../../tracking/screen";

export interface ChangeEmailScreenProps {
  email: string;
  maskedPhoneNumber: string;
  errorMessage: string;
  immediateEmailError: unknown;
  isLoading: boolean;

  handleEmailOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

  handleOnFocus: () => void;
  handleOnBlur: () => void;
  handleFormSubmit: (e: React.FormEvent) => void;
}

export function useChangeEmailScreen(
  brand: Brand | null
): ChangeEmailScreenProps {
  useDeclareScreenName(getScreenName(brand, AppScreen.ChangeEmail));

  const workflow = useWorkflow<WorkflowChangeEmail>("latte.NodeChangeEmail");
  const navigateWorkflow = useWorkflowNavigate();

  const [email, setEmail] = useState("");
  const { maskedPhoneNumber } = workflow;

  const [isFocused, setIsFocused] = useState(false);
  const handleOnFocus = useCallback(() => setIsFocused(true), []);
  const handleOnBlur = useCallback(() => setIsFocused(false), []);
  const { deboundedError: emailError, error: immediateEmailError } =
    useValidateEmail(email, 1000, isFocused);

  const [error, setError] = useState<unknown>(null);
  const errorMessage = useErrorMessage(emailError ?? error);

  const handleEmailOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
      setError(null);
    },
    []
  );

  const { trigger } = useInteractionStep(workflow, changeEmail);
  const isLoading = useInteractionIsLoading();

  const handleFormSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      setError(null);
      Promise.resolve()
        .then(() => validateEmail(email))
        .then(async () => trigger(workflow, email))
        .then(
          async (workflow) => navigateWorkflow(workflow),
          (err) => {
            console.error("change email:", err);
            setError(err);
          }
        );
    },
    [email, navigateWorkflow, trigger, workflow]
  );

  return {
    email,
    maskedPhoneNumber,
    errorMessage,
    immediateEmailError,
    isLoading,
    handleEmailOnChange,
    handleOnFocus,
    handleOnBlur,
    handleFormSubmit,
  };
}
