import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../../components/web/Button";
import { WebScreenLayout } from "../../../../components/WebScreenLayout";
import { useLoginVerifyMFAEmail } from "../../../../hooks/auth/useLoginVerifyMFAEmail";
import { useWorkflow } from "../../../../shell/workflow";
import { WorkflowAuth } from "../../../../states/workflows";
import { useDeclareScreenName } from "../../../../tracking/hooks";

export function WebLoginVerifyMFAEmailScreen(): React.ReactElement {
  useDeclareScreenName("Auth-Mfa-Email");

  // On web, previous screen is unmounted.
  // So by nature, this screen is active.
  const screenIsActive = true;

  const workflow = useWorkflow<WorkflowAuth>(
    "latte.NodeAuthenticateEmailLoginLink"
  );

  const { errorMessage, handleDismiss } = useLoginVerifyMFAEmail(
    "WebLoginVerifyMFAEmailScreen",
    workflow,
    screenIsActive
  );

  const descriptionValues = useMemo(
    () => ({
      emailAddress: (
        <span className="inline-block font-semibold text-body-1">
          {workflow.maskedEmail}
        </span>
      ),
    }),
    [workflow.maskedEmail]
  );

  return (
    <WebScreenLayout>
      <WebScreenLayout.Body>
        <h1 className="mb-3 font-heading text-heading-2">
          <FormattedMessage
            id="web.screens.login.verifyMFAEmail.title"
            defaultMessage="Email Magic Link Log In"
          />
        </h1>
        <div className="mb-8 sm:mb-12">
          <p className="text-center text-body-1">
            <FormattedMessage
              id="web.screens.login.verifyMFAEmail.description1"
              defaultMessage="We emailed a magic link to {emailAddress}"
              values={descriptionValues}
            />
          </p>
          <p className="text-center text-body-1">
            <FormattedMessage
              id="web.screens.login.verifyMFAEmail.description2"
              defaultMessage="Click the link to log in."
            />
          </p>
        </div>

        <div className="mb-8 border-t sm:mb-12 border-t-c-button-disabled-bg">
          <h1 className="mt-8 mb-1 text-center sm:mt-4 text-heading-4 text-c-text-disabled">
            <FormattedMessage
              id="web.screens.login.verifyMFAEmail.reminder.title"
              defaultMessage="Don’t Close this tab!"
            />
          </h1>
          <p className="text-center text-body-2 text-c-text-disabled">
            <FormattedMessage
              id="web.screens.login.verifyMFAEmail.reminder.description"
              defaultMessage="Please return to this tab after clicked your magic link."
              values={descriptionValues}
            />
          </p>
        </div>

        <Button variant="text" className="underline" onPress={handleDismiss}>
          <FormattedMessage
            id="web.screens.login.verifyMFAPassword.chooseOtherMethod"
            defaultMessage="Choose other log in methods"
          />
        </Button>

        {errorMessage ? (
          <p className="mt-4 text-body-2 text-error">{errorMessage}</p>
        ) : null}
      </WebScreenLayout.Body>
    </WebScreenLayout>
  );
}
