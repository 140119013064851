import { useButton } from "@react-aria/button";
import React, { useRef } from "react";
import cn from "classnames";
import { ReactComponent as ChevronRight } from "../assets/forward.svg";
import { ReactComponent as IconLock } from "../assets/login-password-large.svg";
import css from "./Button.module.css";

interface ResetPasswordButtonProps extends React.PropsWithChildren {
  className?: string;
  disabled?: boolean;
  onPress?: () => void;
}

export const ResetPasswordButton: React.FC<ResetPasswordButtonProps> = (
  props
) => {
  const { className, disabled, onPress, children } = props;

  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps, isPressed } = useButton(
    { isDisabled: disabled, onPress },
    ref
  );

  return (
    <button
      ref={ref}
      className={cn(
        className,
        css["button"],
        "w-full bg-c-reset-password px-5 py-3 rounded-2xl shadow-card-button",
        isPressed && css["button--pressed"]
      )}
      type="button"
      {...buttonProps}
    >
      <span className="flex items-center w-full space-x-1">
        <IconLock className="w-6 h-6 m-1 text-c-text" />
        <span className="flex-1 font-bold text-start text-body-2 text-c-text">
          {children}
        </span>
        <ChevronRight className="w-6 h-6 text-c-button-primary" />
      </span>
    </button>
  );
};
