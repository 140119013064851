import React from "react";
import { useAbortInteraction } from "../../hooks/interaction";
import { Button } from "./Button";

interface DialogErrorContentProps {
  title: React.ReactNode;
  description: React.ReactNode;
  confirmButtonText: React.ReactNode;
  error?: unknown;
}

export const DialogErrorContent: React.FC<DialogErrorContentProps> = ({
  title,
  description,
  confirmButtonText,
  error,
}) => {
  const handleOKOnPress = useAbortInteraction(error);
  return (
    <div>
      <h2 className="mb-6 text-heading-3">{title}</h2>
      <p className="text-body-1 mb-6 min-h-[120px]">{description}</p>

      <Button className="w-full" onPress={handleOKOnPress}>
        {confirmButtonText}
      </Button>
    </div>
  );
};
