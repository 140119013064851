import React from "react";
import { Outlet } from "react-router-dom";
import { WebWorkflowProvider } from "../../../../shell/WebWorkflowProvider";
import { initWorkflowVerifyLoginLink } from "../../../../states/interaction/intents";

export function WebLoginLinkInitScreen(): React.ReactElement {
  return (
    <WebWorkflowProvider initializer={initWorkflowVerifyLoginLink}>
      <Outlet />
    </WebWorkflowProvider>
  );
}
