import React from "react";
import { FocusScope } from "@react-aria/focus";
import { ReactComponent as Loader } from "../assets/loader.svg";
import { useShell } from "../shell/context";

const LoadingOverlayBase: React.FC = () => {
  return (
    <FocusScope contain={true} restoreFocus={true} autoFocus={true}>
      <div className="fixed inset-0 flex items-center justify-center">
        <Loader
          tabIndex={0}
          className="text-c-loader stroke-c-loader-track w-10 h-10 animate-spin outline-none"
        />
      </div>
    </FocusScope>
  );
};

const LoadingOverlayKDP: React.FC = () => {
  return (
    <FocusScope contain={true} restoreFocus={true} autoFocus={true}>
      <div className="fixed inset-0 flex items-center justify-center bg-white/80">
        <Loader
          tabIndex={0}
          className="text-c-loader stroke-c-loader-track w-[4.5rem] h-[4.5rem] [stroke-width]:[2rem] animate-spin outline-none"
        />
      </div>
    </FocusScope>
  );
};

export const LoadingOverlay: React.FC = () => {
  switch (useShell().brand) {
    case "KDP":
      return <LoadingOverlayKDP />;
    default:
      return <LoadingOverlayBase />;
  }
};
