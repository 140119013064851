import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../components/Button";
import { MobileScreenLayout } from "../../../components/MobileScreenLayout";

import { ReactComponent as IconSuccessBase } from "../../../assets/success.svg";
import { ChangePasswordSuccessScreenProps } from "./useChangePasswordSuccessScreen";

export function ChangePasswordSuccessScreen(
  props: ChangePasswordSuccessScreenProps
): React.ReactElement {
  const { workflow, handleActionOnPress } = props;

  return (
    <MobileScreenLayout>
      <MobileScreenLayout.Body>
        <div className="flex flex-col items-center justify-center h-full text-center">
          <IconSuccessBase className="self-center mb-2 text-c-button-primary w-25 h-25" />
          <h1 className="mb-2 font-heading text-heading-2">
            <FormattedMessage
              id="mobile.screens.changePasswordSuccess.title"
              defaultMessage="Successfully changed password"
            />
          </h1>

          <p className="text-body-1 text-c-button-text-disabled">
            <FormattedMessage
              id="mobile.screens.changePasswordSuccess.description"
              defaultMessage="Your OneLogin password has been updated successfully."
            />
          </p>
        </div>
      </MobileScreenLayout.Body>
      {workflow.finishURI ? (
        <MobileScreenLayout.FixedFooter>
          <Button
            className="w-full"
            type="button"
            preventFocusOnPress={true}
            onPress={handleActionOnPress}
          >
            <FormattedMessage
              id="mobile.screens.changePasswordSuccess.action"
              defaultMessage="Back to My Profile"
            />
          </Button>
        </MobileScreenLayout.FixedFooter>
      ) : null}
    </MobileScreenLayout>
  );
}
