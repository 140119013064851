import React, { useCallback, useRef, useState } from "react";
import cn from "classnames";
import { FormField } from "./FormField";
import { useButton } from "@react-aria/button";
import { ReactComponent as Eye } from "../assets/eye.svg";
import { ReactComponent as EyeSlash } from "../assets/eye-slash.svg";

interface PasswordInputProps {
  className?: string;
  required?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  autoComplete?: string;
  placeholder?: string;

  value: string;
  onChange: (value: string) => void;
}

export const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  const {
    className,
    required,
    readOnly,
    autoFocus,
    autoComplete,
    placeholder,
    value,
    onChange,
  } = props;

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const Icon = isPasswordVisible ? Eye : EyeSlash;

  const toggleButtonRef = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      onPress: useCallback(() => setIsPasswordVisible((x) => !x), []),
      isDisabled: readOnly,
      // @ts-expect-error
      preventFocusOnPress: true,
      excludeFromTabOrder: true,
    },
    toggleButtonRef
  );

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <div className={cn("flex-1 flex", className)}>
      <FormField.TextInput
        type={isPasswordVisible ? "text" : "password"}
        value={value}
        onChange={handleOnChange}
        required={required}
        readOnly={readOnly}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        placeholder={placeholder}
      />
      <button
        type="button"
        className="text-c-password-input-toggle pl-1 h-full"
        {...buttonProps}
        ref={toggleButtonRef}
      >
        <Icon className="w-6 h-6" />
      </button>
    </div>
  );
};
