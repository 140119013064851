import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { ReactComponent as IconMessage } from "../../../../assets/message.svg";
import { AppNavigate } from "../../../../components/AppNavigate";
import { Button } from "../../../../components/web/Button";
import { WebScreenLayout } from "../../../../components/WebScreenLayout";
import { routes } from "../../../../shell/routes";
import { ForgotPasswordBySMSProceedScreenProps } from "./useForgotPasswordBySMSProceedScreen";

export function WebForgotPasswordBySMSProceedScreen(
  props: ForgotPasswordBySMSProceedScreenProps
): React.ReactElement {
  const {
    currentWorkflow,
    maskedPhoneNumber,
    canBack,
    handleBackActionOnPress,
  } = props;
  const { search } = useLocation();

  const descriptionValues = useMemo(
    () => ({
      maskedPhoneNumber: (
        <>
          <wbr />
          <strong className="inline-block whitespace-normal">
            {maskedPhoneNumber}
          </strong>
          <wbr />
        </>
      ),
    }),
    [maskedPhoneNumber]
  );

  if (
    currentWorkflow !== "latte.NodeSendForgotPasswordCode" ||
    !maskedPhoneNumber
  ) {
    return (
      <AppNavigate
        to={{
          pathname: routes.web.auth.default,
          search: search,
        }}
        replace={true}
      />
    );
  }

  return (
    <WebScreenLayout>
      <WebScreenLayout.Body>
        <div className="flex flex-col justify-center h-full text-center">
          <IconMessage className="self-center text-c-button-primary w-25 h-25" />

          <h1 className="mt-3 font-heading text-heading-2">
            <FormattedMessage
              id="web.screens.forgotPasswordProceed.sms.title"
              defaultMessage="We Just Sent You a SMS"
            />
          </h1>

          <p className="mt-3 text-body-1">
            <FormattedMessage
              id="web.screens.forgotPasswordProceed.sms.description"
              defaultMessage={
                "We just sent you a link at {maskedPhoneNumber}. Please click the link from your SMS to reset your password."
              }
              values={descriptionValues}
            />
          </p>

          {canBack ? (
            <Button
              className="w-full mt-12 sm:mt-3"
              variant="secondary"
              onPress={handleBackActionOnPress}
            >
              <FormattedMessage
                id="web.screens.forgotPasswordProceed.sms.action"
                defaultMessage="Back to Login"
              />
            </Button>
          ) : null}
        </div>
      </WebScreenLayout.Body>
    </WebScreenLayout>
  );
}
