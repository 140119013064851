import React from "react";
import { useShell } from "../../../shell/context";
import { useResetPasswordScreen } from "./useResetPasswordScreen";
import { ResetPasswordScreen as Screen } from "./ResetPasswordScreen";
import { ResetPasswordScreen as KDPScreen } from "./ResetPasswordScreen.kdp";

export function ResetPasswordScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useResetPasswordScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPScreen {...props} />;
    default:
      return <Screen {...props} />;
  }
}
