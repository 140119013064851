import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../../components/Button";
import { PasswordInput } from "../../../components/PasswordInput";
import { PasswordRequirement } from "../../../components/PasswordRequirement";
import { FormField } from "../../../components/FormField";
import { MobileScreenLayout } from "../../../components/MobileScreenLayout";
import { ChangePasswordScreenProps } from "./useChangePasswordScreen";

export function ChangePasswordScreen(
  props: ChangePasswordScreenProps
): React.ReactElement {
  const {
    oldPassword,
    newPassword,
    passwordChecks,
    errorMessage,
    showErrorAtOldPasswordField,
    isLoading,
    handleOldPasswordOnChange,
    handleNewPasswordOnChange,
    handleOnSubmit,
  } = props;

  const intl = useIntl();

  return (
    <form className="h-full" noValidate={true} onSubmit={handleOnSubmit}>
      <MobileScreenLayout>
        <MobileScreenLayout.Body>
          <h1 className="font-heading text-heading-2 mb-1">
            <FormattedMessage
              id="mobile.screens.changePassword.title"
              defaultMessage="Update Your Password"
            />
          </h1>
          <p className="text-body-1 mb-6">
            <FormattedMessage
              id="mobile.screens.changePassword.description"
              defaultMessage="Your new password should contain at least 8 characters including both number(s) and letter(s)."
            />
          </p>

          <FormField className="mb-4">
            <FormField.Label>
              <FormattedMessage
                id="mobile.screens.changePassword.oldPassword.label"
                defaultMessage="Current Password"
              />
            </FormField.Label>
            <FormField.InputContainer>
              <PasswordInput
                value={oldPassword}
                onChange={handleOldPasswordOnChange}
                required={true}
                readOnly={isLoading}
                autoComplete="current-password"
                placeholder={intl.formatMessage({
                  id: "mobile.screens.changePassword.oldPassword.placeholder",
                  defaultMessage: "Enter Password",
                })}
              />
            </FormField.InputContainer>
            {errorMessage && showErrorAtOldPasswordField ? (
              <FormField.ErrorMessage>{errorMessage}</FormField.ErrorMessage>
            ) : null}
          </FormField>

          <FormField className="mb-4">
            <FormField.Label>
              <FormattedMessage
                id="mobile.screens.changePassword.newPassword.label"
                defaultMessage="New Password"
              />
            </FormField.Label>
            <FormField.InputContainer>
              <PasswordInput
                value={newPassword}
                onChange={handleNewPasswordOnChange}
                required={true}
                readOnly={isLoading}
                autoComplete="new-password"
                placeholder={intl.formatMessage({
                  id: "mobile.screens.changePassword.newPassword.placeholder",
                  defaultMessage: "Enter New Password",
                })}
              />
            </FormField.InputContainer>
            {errorMessage && !showErrorAtOldPasswordField ? (
              <FormField.ErrorMessage>{errorMessage}</FormField.ErrorMessage>
            ) : null}
          </FormField>

          <PasswordRequirement checks={passwordChecks} />

          <Button
            className="w-full mt-6"
            type="submit"
            preventFocusOnPress={true}
            disabled={!passwordChecks.ok}
            loading={isLoading}
          >
            <FormattedMessage
              id="mobile.screens.changePassword.action"
              defaultMessage="Continue"
            />
          </Button>
        </MobileScreenLayout.Body>
      </MobileScreenLayout>
    </form>
  );
}
