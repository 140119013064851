import React, { useMemo } from "react";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
import { WebScreenLayout } from "../../../components/WebScreenLayout";
import { ReactComponent as IconSuccess } from "../../../assets/success.svg";
import { ReactComponent as IconAppK11 } from "../../../assets/app-k11.svg";
import { ReactComponent as IconAppAL } from "../../../assets/app-al.svg";
import {
  SetupPasswordUserInfo,
  SetupPasswordUserInfoView,
} from "../../../components/web/SetupPasswordUserInfoView";
import { useWorkflow } from "../../../shell/workflow";
import { WorkflowResetPassword } from "../../../states/workflows";
import { alAppLink, k11AppLink } from "../../../config";
import { useDeclareScreenName } from "../../../tracking/hooks";

function AppLink({
  Icon,
  Label,
  href,
  classname,
  data_latte_bu,
}: {
  Icon: React.ReactNode;
  Label: React.ReactNode;
  href: string;
  classname?: string;
  data_latte_bu?: string;
}): React.ReactElement {
  return (
    <a
      className={cn(classname, "block")}
      href={href}
      data-latte-event="bu_link_click"
      data-latte-bu={data_latte_bu}
    >
      <div className="flex items-center justify-center w-20 h-20 bg-white rounded-lg shadow-app-icon">
        {Icon}
      </div>
      <p className="mt-4 text-icon-text text-center">{Label}</p>
    </a>
  );
}

export function SetupPasswordSuccessScreen(): React.ReactElement {
  useDeclareScreenName("Setup-Password-Success");

  const workflow = useWorkflow<WorkflowResetPassword>(
    "latte.NodeDoResetPasswordByCode"
  );

  const userInfo = useMemo<SetupPasswordUserInfo>(() => {
    return {
      phone: workflow.userPhone,
      email: workflow.userEmail,
    };
  }, [workflow]);

  return (
    <WebScreenLayout>
      <WebScreenLayout.Body>
        <div className="h-full flex flex-col justify-center text-center items-center">
          <IconSuccess className="text-c-button-primary w-25 h-25 self-center mb-2" />
          <h1 className="font-heading text-heading-2 mb-4">
            <FormattedMessage
              id="web.screens.setupPasswordSuccess.title"
              defaultMessage="Your password has been setup successfully"
            />
          </h1>

          <p className="text-body-2 text-c-text mb-4">
            <FormattedMessage
              id="web.screens.setupPasswordSuccess.userInfo.description"
              defaultMessage="Your ONE ID account is now ready, here are your ONE ID details"
            />
          </p>

          <SetupPasswordUserInfoView userInfo={userInfo} className="mb-8" />

          <p className="text-body-2 text-c-text mb-4">
            <FormattedMessage
              id="web.screens.setupPasswordSuccess.description"
              defaultMessage="You can now log in one of our apps with your credentials to enjoy a range of exclusive offers."
            />
          </p>

          <section className="flex items-center space-x-4">
            <AppLink
              Icon={<IconAppK11 />}
              Label={
                <FormattedMessage
                  id="web.screens.setupPasswordSuccess.appIcon.k11"
                  defaultMessage="K11 HK"
                />
              }
              href={k11AppLink}
              data_latte_bu="k11"
            />
            <AppLink
              Icon={<IconAppAL />}
              Label={
                <FormattedMessage
                  id="web.screens.setupPasswordSuccess.appIcon.al"
                  defaultMessage="Artisanal Living"
                />
              }
              href={alAppLink}
              data_latte_bu="al"
            />
          </section>
        </div>
      </WebScreenLayout.Body>
    </WebScreenLayout>
  );
}
