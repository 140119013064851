import { Brand } from "../../shell/brand";
import { checkNWDEmail } from "../nwd-api";
import { Locale } from "../../intl/IntlProvider";
import { withLoading } from "../states";
import {
  ForgotPasswordChannel,
  makeWorkflowAuthMapper,
  makeWorkflowChangeEmailMapper,
  makeWorkflowChangePasswordMapper,
  makeWorkflowForgotPasswordV2Mapper,
  makeWorkflowMigrateMapper,
  makeWorkflowReauthForgotPasswordMapper,
  makeWorkflowReauthMapper,
  makeWorkflowResetPasswordMapper,
  makeWorkflowVerifyLoginLinkMapper,
  makeWorkflowVerifyUserMapper,
  WorkflowAuth,
  WorkflowChangeEmail,
  WorkflowChangePassword,
  WorkflowForgotPasswordV2,
  WorkflowMigrate,
  WorkflowReauth,
  WorkflowReauthForgotPassword,
  WorkflowResetPassword,
  WorkflowVerifyLoginLink,
  WorkflowVerifyUser,
} from "../workflows";
import { resetPasswordAPI, workflowInput, workflowBatchInput } from "./api";
import { initWorkflowForgotPasswordV2 } from "./intents";

function expect<T extends string>(
  result: { current: string },
  ...current: T[]
): T {
  if (!(current as string[]).includes(result.current)) {
    throw new Error(
      `unexpected workflow state: ${result.current} != ${current.join()}`
    );
  }
  return result.current as T;
}

export async function resendPhoneOTP(
  workflow: WorkflowAuth
): Promise<WorkflowAuth> {
  const mapper = makeWorkflowAuthMapper(
    workflow.initiationIntent,
    workflow.phoneNumber
  );
  return withLoading(async () => {
    expect(
      workflow,
      "latte.NodeVerifyPhoneSMS",
      "latte.NodeAuthenticateOOBOTPPhone"
    );

    const result = await workflowInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      "latte.InputResendOOBOTPCode"
    );
    expect(result, workflow.current);

    return result;
  });
}

export async function verifyPhoneOTP(
  workflow: WorkflowAuth,
  code: string
): Promise<WorkflowAuth> {
  const mapper = makeWorkflowAuthMapper(
    workflow.initiationIntent,
    workflow.phoneNumber
  );

  return withLoading(async () => {
    let resultKind: string;
    switch (
      expect(
        workflow,
        "latte.NodeVerifyPhoneSMS",
        "latte.NodeAuthenticateOOBOTPPhone"
      )
    ) {
      case "latte.NodeVerifyPhoneSMS":
        resultKind = "latte.IntentCreateLoginID";
        break;
      case "latte.NodeAuthenticateOOBOTPPhone":
        resultKind = "latte.NodeAuthenticateOOBOTPPhoneEnd";
        break;
    }

    const result = await workflowInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      "latte.InputTakeOOBOTPCode",
      { code }
    );
    expect(result, resultKind);

    return result;
  });
}

export async function verifyCaptcha(
  workflow: WorkflowAuth,
  token: string
): Promise<WorkflowAuth> {
  const mapper = makeWorkflowAuthMapper(
    workflow.initiationIntent,
    workflow.phoneNumber
  );

  return withLoading(async () => {
    expect(workflow, "latte.IntentVerifyCaptcha");

    const result = await workflowInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      "latte.InputTakeCaptchaToken",
      { token }
    );

    return result;
  });
}

export async function requestPasswordMFA(
  workflow: WorkflowAuth
): Promise<WorkflowAuth> {
  const mapper = makeWorkflowAuthMapper(
    workflow.initiationIntent,
    workflow.phoneNumber
  );

  return withLoading(async () => {
    expect(workflow, "latte.NodeAuthenticateOOBOTPPhoneEnd");
    const result = await workflowInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      "latte.InputSelectAuthenticatorType",
      { authenticator_type: "password" }
    );
    expect(result, "latte.NodeAuthenticatePassword");

    return result;
  });
}

export async function verifyMFAPassword(
  workflow: WorkflowAuth,
  password: string
): Promise<WorkflowAuth> {
  const mapper = makeWorkflowAuthMapper(
    workflow.initiationIntent,
    workflow.phoneNumber
  );

  return withLoading(async () => {
    expect(workflow, "latte.NodeAuthenticatePassword");
    const result = await workflowInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      "latte.InputTakePassword",
      { password }
    );

    return result;
  });
}

export async function verifyReauthPassword(
  workflow: WorkflowReauth,
  password: string
): Promise<WorkflowReauth> {
  const mapper = makeWorkflowReauthMapper({
    email: workflow.email,
    phoneNumber: workflow.phoneNumber,
  });

  return withLoading(async () => {
    expect(workflow, "latte.NodeAuthenticatePassword");
    const result = await workflowInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      "latte.InputTakePassword",
      { password }
    );

    return result;
  });
}

export async function requestEmailOTP(
  workflow: WorkflowAuth
): Promise<WorkflowAuth> {
  const mapper = makeWorkflowAuthMapper(
    workflow.initiationIntent,
    workflow.phoneNumber
  );

  return withLoading(async () => {
    expect(workflow, "latte.NodeAuthenticateOOBOTPPhoneEnd");
    const result = await workflowInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      "latte.InputSelectAuthenticatorType",
      { authenticator_type: "oob_otp_email" }
    );
    expect(result, "latte.NodeAuthenticateEmailLoginLink");

    return result;
  });
}

export async function resendEmailOTP(
  workflow: WorkflowAuth
): Promise<WorkflowAuth> {
  const mapper = makeWorkflowAuthMapper(
    workflow.initiationIntent,
    workflow.phoneNumber
  );

  return withLoading(async () => {
    expect(workflow, "latte.NodeAuthenticateEmailLoginLink");
    const result = await workflowInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      "latte.InputResendOOBOTPCode"
    );
    expect(result, "latte.NodeAuthenticateEmailLoginLink");

    return result;
  });
}

export async function checkLoginLinkVerified(
  workflow: WorkflowAuth
): Promise<WorkflowAuth> {
  const mapper = makeWorkflowAuthMapper(
    workflow.initiationIntent,
    workflow.phoneNumber
  );

  return withLoading(async () => {
    expect(workflow, "latte.NodeAuthenticateEmailLoginLink");
    const result = await workflowInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      "latte.InputCheckLoginLinkVerified"
    );

    return result;
  });
}

export async function verifyLoginLink(
  workflow: WorkflowVerifyLoginLink
): Promise<WorkflowVerifyLoginLink> {
  const mapper = makeWorkflowVerifyLoginLinkMapper(workflow.code);

  return withLoading(async () => {
    expect(workflow, "latte.IntentVerifyLoginLink");
    const result = await workflowInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      "latte.InputTakeLoginLinkCode",
      { code: workflow.code }
    );
    expect(result, "latte.NodeVerifiedLoginLink");
    return result;
  });
}

export async function setupMFA(
  workflow: WorkflowAuth,
  locale: Locale,
  email: string,
  password: string,
  emailCheckBrands: Brand[]
): Promise<WorkflowAuth> {
  const mapper = makeWorkflowAuthMapper(
    workflow.initiationIntent,
    workflow.phoneNumber
  );

  return withLoading(async () => {
    await checkNWDEmail({ brands: emailCheckBrands, email, locale });
    const result = await workflowBatchInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      [
        {
          kind: "latte.InputTakeLoginID",
          data: { login_id: email },
        },
        {
          kind: "latte.InputTakeNewPassword",
          data: { new_password: password },
        },
      ]
    );
    return result;
  });
}

export async function migrateSetupMFA(
  workflow: WorkflowMigrate,
  email: string,
  password: string
): Promise<WorkflowMigrate> {
  const mapper = makeWorkflowMigrateMapper(workflow.email);

  return withLoading(async () => {
    const result = await workflowBatchInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      [
        {
          kind: "latte.InputTakeLoginID",
          data: { login_id: email },
        },
        {
          kind: "latte.InputTakeNewPassword",
          data: { new_password: password },
        },
      ]
    );
    return result;
  });
}

export async function forgotPasswordV2(
  channel: ForgotPasswordChannel,
  phoneNumber: string,
  maskedEmail?: string
): Promise<WorkflowForgotPasswordV2> {
  return withLoading(async () => {
    const workflow = await initWorkflowForgotPasswordV2(
      new URLSearchParams(window.location.search),
      { channel, phoneNumber, maskedEmail }
    );
    expect(workflow, "latte.IntentForgotPasswordV2");

    const mapper = makeWorkflowForgotPasswordV2Mapper({
      channel,
      finishURI: workflow.finishURI,
      phoneNumber: phoneNumber,
      maskedEmail: maskedEmail,
    });

    const result = await workflowBatchInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      [
        { kind: "latte.InputTakeLoginID", data: { login_id: phoneNumber } },
        {
          kind: "latte.InputTakeForgotPasswordChannel",
          data: { channel: channel },
        },
      ]
    );
    expect(result, "latte.NodeSendForgotPasswordCode");

    return result;
  });
}

export async function reauthSendForgotPasswordCode(
  workflow: WorkflowReauthForgotPassword
): Promise<WorkflowReauthForgotPassword> {
  return withLoading(async () => {
    const mapper = makeWorkflowReauthForgotPasswordMapper({
      channel: workflow.channel,
      finishURI: workflow.finishURI,
      email: workflow.email,
      phoneNumber: workflow.phoneNumber,
      backWorkflow: workflow.backWorkflow,
    });

    const result = await workflowBatchInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      [
        {
          kind: "latte.InputSendForgotPasswordCode",
          data: {},
        },
      ]
    );

    return result;
  });
}

export async function resetPassword(
  workflow: WorkflowResetPassword,
  password: string
): Promise<WorkflowResetPassword> {
  const mapper = makeWorkflowResetPasswordMapper(
    workflow.initiationIntent,
    workflow.finishURI
  );

  return withLoading(async () => {
    expect(workflow, "latte.NodeValidatedResetPasswordCode");

    const result = await resetPasswordAPI(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      password
    );
    expect(result, "latte.NodeDoResetPasswordByCode");

    return result;
  });
}

export async function changePassword(
  workflow: WorkflowChangePassword,
  oldPassword: string,
  newPassword: string
): Promise<WorkflowChangePassword> {
  const mapper = makeWorkflowChangePasswordMapper(workflow.finishURI);

  return withLoading(async () => {
    expect(workflow, "latte.NodeChangePassword");

    const result = await workflowInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      "latte.InputChangePassword",
      { old_password: oldPassword, new_password: newPassword }
    );
    expect(
      result,
      "latte.NodeDoUpdateAuthenticator",
      "latte.NodeChangePasswordEnd"
    );

    return result;
  });
}

export async function verifyEmailOTP(
  workflow: WorkflowVerifyUser,
  code: string
): Promise<WorkflowVerifyUser> {
  const mapper = makeWorkflowVerifyUserMapper({
    email: workflow.email,
    finishURI: workflow.finishURI,
  });

  return withLoading(async () => {
    expect(workflow, "latte.NodeVerifyEmail");

    let expected: string[];
    switch (workflow.root) {
      case "latte.IntentChangeEmail":
        expected = [
          // In https://github.com/authgear/2023C01-latte-customization/issues/212
          // Authenticator is auto created when identity is updated.
          // In https://github.com/authgear/2023C01-latte-customization/issues/281
          // The workflow is updated to remove the create authenticator intent.
          // To be compatible before AND after the change,
          // we expect the 2 cases here.
          "latte.NodeDoCreateAuthenticator",
          "latte.NodeVerifiedIdentity",
        ];
        break;
      case "latte.IntentVerifyUser":
      default:
        expected = ["latte.NodeVerifiedIdentity"];
        break;
    }

    const result = await workflowInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      "latte.InputTakeOOBOTPCode",
      { code }
    );
    expect(result, ...expected);

    return result;
  });
}

export async function resendVerifyEmailOTP(
  workflow: WorkflowVerifyUser
): Promise<WorkflowVerifyUser> {
  const mapper = makeWorkflowVerifyUserMapper({
    email: workflow.email,
    finishURI: workflow.finishURI,
  });
  return withLoading(async () => {
    expect(workflow, "latte.NodeVerifyEmail");

    const result = await workflowInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      "latte.InputResendOOBOTPCode"
    );
    expect(result, workflow.current);

    return result;
  });
}

export async function changeEmail(
  workflow: WorkflowChangeEmail,
  email: string
): Promise<WorkflowChangeEmail> {
  const mapper = makeWorkflowChangeEmailMapper(
    workflow.phoneNumber,
    workflow.finishURI
  );

  return withLoading(async () => {
    expect(workflow, "latte.NodeChangeEmail");

    const result = await workflowInput(
      mapper,
      workflow.workflowID,
      workflow.instanceID,
      "latte.InputTakeLoginID",
      { login_id: email }
    );
    // If the email is unchanged, the node is latte.NodeVerifiedIdentity.
    expect(result, "latte.NodeVerifyEmail", "latte.NodeVerifiedIdentity");

    return result;
  });
}
