import React from "react";
import cn from "classnames";
import { useIsLocaleLoaded } from "../intl/IntlProvider";
import { WebScreenLoadingIndicator } from "./web/ScreenLoadingIndicator";

interface WebScreenLayoutProps extends React.PropsWithChildren {}

export function WebScreenLayout(
  props: WebScreenLayoutProps
): React.ReactElement {
  const { children } = props;

  return <div className="sm:py-2 sm:w-min px-5 mx-auto">{children}</div>;
}

const WebScreenLayoutBody = ({ children }: React.PropsWithChildren) => {
  const isLocaleLoaded = useIsLocaleLoaded();
  return (
    <div
      className={cn(
        "bg-white px-4 py-6 shadow-web-card rounded-lg sm:w-[518px] w-full",
        "flex flex-col items-center",
        "sm:px-15 sm:py-15 sm:w-[518px]"
      )}
    >
      {isLocaleLoaded ? children : <WebScreenLoadingIndicator />}
    </div>
  );
};
WebScreenLayout.Body = WebScreenLayoutBody;
