import React from "react";
import { useShell } from "../../../shell/context";
import { ForgotPasswordByEmailView } from "../../../components/forgot-password/ForgotPasswordByEmailView";
import { KDPForgotPasswordByEmailView } from "../../../components/forgot-password/ForgotPasswordByEmailView.kdp";
import { useReauthForgotPasswordByEmailScreen } from "./useReauthForgotPasswordByEmailScreen";
import { MobileWorkflowProvider } from "../../../shell/MobileWorkflowProvider";
import { initWorkflowReauthForgotPasswordByEmail } from "../../../states/interaction/intents";

export function ReauthForgotPasswordByEmailScreen(): React.ReactElement {
  return (
    <MobileWorkflowProvider
      initializer={initWorkflowReauthForgotPasswordByEmail}
    >
      <ScreenLoaded />
    </MobileWorkflowProvider>
  );
}

function ScreenLoaded() {
  const { brand } = useShell();
  const props = useReauthForgotPasswordByEmailScreen(brand);

  switch (brand) {
    case "KDP":
      return (
        <KDPForgotPasswordByEmailView
          maskedEmail={props.maskedLoginID}
          errorMessage={props.errorMessage}
          handleSendForgotPasswordCode={props.handleSendForgotPasswordCode}
          isLoading={props.isLoading}
        />
      );
    default:
      return (
        <ForgotPasswordByEmailView
          maskedEmail={props.maskedLoginID}
          errorMessage={props.errorMessage}
          handleSendForgotPasswordCode={props.handleSendForgotPasswordCode}
          isLoading={props.isLoading}
        />
      );
  }
}
