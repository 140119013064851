import React from "react";
import { useChangePasswordSuccessScreen } from "./useChangePasswordSuccessScreen";
import { ChangePasswordSuccessScreen as Screen } from "./ChangePasswordSuccessScreen";
import { ChangePasswordSuccessScreen as KDPScreen } from "./ChangePasswordSuccessScreen.kdp";
import { useShell } from "../../../shell/context";

export function ChangePasswordSuccessScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useChangePasswordSuccessScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPScreen {...props} />;
    default:
      return <Screen {...props} />;
  }
}
