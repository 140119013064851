import React from "react";
import { useShell } from "../../../shell/context";
import { ForgotPasswordBySMSView } from "../../../components/forgot-password/ForgotPasswordBySMSView";
import { KDPForgotPasswordBySMSView } from "../../../components/forgot-password/ForgotPasswordBySMSView.kdp";
import { useReauthForgotPasswordBySMSScreen } from "./useReauthForgotPasswordBySMSScreen";
import { MobileWorkflowProvider } from "../../../shell/MobileWorkflowProvider";
import { initWorkflowReauthForgotPasswordBySMS } from "../../../states/interaction/intents";

export function ReauthForgotPasswordBySMSScreen(): React.ReactElement {
  return (
    <MobileWorkflowProvider initializer={initWorkflowReauthForgotPasswordBySMS}>
      <ScreenLoaded />
    </MobileWorkflowProvider>
  );
}

function ScreenLoaded() {
  const { brand } = useShell();
  const props = useReauthForgotPasswordBySMSScreen(brand);
  switch (brand) {
    case "KDP":
      return (
        <KDPForgotPasswordBySMSView
          maskedPhoneNumber={props.maskedLoginID}
          errorMessage={props.errorMessage}
          handleSendForgotPasswordCode={props.handleSendForgotPasswordCode}
          isLoading={props.isLoading}
        />
      );
    default:
      return (
        <ForgotPasswordBySMSView
          maskedPhoneNumber={props.maskedLoginID}
          errorMessage={props.errorMessage}
          handleSendForgotPasswordCode={props.handleSendForgotPasswordCode}
          isLoading={props.isLoading}
        />
      );
  }
}
