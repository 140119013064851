import React from "react";
import { FormattedMessage } from "react-intl";
import { DialogErrorContent } from "./DialogErrorContent";

export const MaximumAttemptExceededError: React.FC = () => {
  return (
    <DialogErrorContent
      title={
        <FormattedMessage
          id="mobile.components.maximumAttemptExceededError.title"
          defaultMessage="Reminder"
        />
      }
      description={
        <FormattedMessage
          id="mobile.components.maximumAttemptExceededError.description"
          defaultMessage="You have exceeded the maximum of attempts for resending a verification code, please try again later."
        />
      }
      confirmButtonText={
        <FormattedMessage
          id="mobile.components.maximumAttemptExceededError.confirm"
          defaultMessage="OK"
        />
      }
    />
  );
};
