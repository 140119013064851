import React, { useEffect } from "react";
import { runAfterTransition, focusWithoutScrolling } from "@react-aria/utils";

export function useAutoFocus(
  ref: React.RefObject<HTMLElement>,
  autoFocus: boolean | undefined
): void {
  // autoFocus attribute focuses too early, breaking transitions.
  useEffect(() => {
    if (autoFocus) {
      runAfterTransition(() => {
        if (ref.current != null) {
          focusWithoutScrolling(ref.current);
        }
      });
    }
  }, [ref, autoFocus]);
}
