import { useButton } from "@react-aria/button";
import cn from "classnames";
import React, { useCallback, useRef } from "react";
import { useMatches } from "react-router-dom";
import { ReactComponent as ChevronLeft } from "../assets/chevron-left.svg";
import { completeInteraction } from "../states/interaction/complete";
import { useInteractionIsLoading } from "../states/states";
import { useAppCanGoBack, useIsCancellable } from "./hooks";
import { useMobileShellContext } from "./MobileShell";

interface NavigationBarProps {
  className?: string;
}

interface BackButtonProps {
  className?: string;
  disabled?: boolean;
  onGoBack: () => void;
}

export const BackButton: React.FC<BackButtonProps> = (props) => {
  const { className, disabled, onGoBack } = props;

  const handleOnPress = useCallback(() => {
    onGoBack();
  }, [onGoBack]);

  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps, isPressed } = useButton(
    { isDisabled: disabled, onPress: handleOnPress },
    ref
  );

  return (
    <button
      ref={ref}
      className={cn(
        "h-full px-4 inline-flex items-center justify-center relative outline-none",
        "transition-opacity duration-100 will-change-[opacity]",
        isPressed && "opacity-30",
        className
      )}
      type="button"
      {...buttonProps}
    >
      <ChevronLeft className="text-c-navigation-button w-5 h-5 m-0.5" />
    </button>
  );
};

export const NavigationBar: React.FC<NavigationBarProps> = (props) => {
  const { className } = props;

  const { onBack } = useMobileShellContext();
  const isLoading = useInteractionIsLoading();
  const isCancellable = useIsCancellable();
  const hidesBackButtonWhen = useMatches()
    .map((m) => (m.handle as any)?.hidesBackButtonWhen)
    .find((x) => x != null);

  let shouldHideBackButton: boolean;
  switch (hidesBackButtonWhen) {
    case "always":
      shouldHideBackButton = true;
      break;
    case "non-cancellable":
      shouldHideBackButton = !isCancellable;
      break;
    default:
      shouldHideBackButton = false;
  }

  const canGoBack = useAppCanGoBack();
  const handleOnGoBack = useCallback(() => {
    if (onBack != null) {
      onBack();
      return;
    }
    if (canGoBack) {
      history.back();
    } else {
      (async () => {
        await completeInteraction({ error: "cancel" });
      })().catch((err) => console.error(err));
    }
  }, [onBack, canGoBack]);

  return (
    <div className={className}>
      {(canGoBack || isCancellable) && !shouldHideBackButton ? (
        <BackButton disabled={isLoading} onGoBack={handleOnGoBack} />
      ) : null}
    </div>
  );
};
