import cn from "classnames";
import React, { useRef } from "react";
import { useAutoFocus } from "../hooks/auto-focus";
import css from "./FormField.module.css";

export function FormField(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  return <div {...props} />;
}

function FormFieldLabel(props: React.LabelHTMLAttributes<HTMLLabelElement>) {
  const { className, ...rest } = props;

  return <label className={cn(css["label"], className)} {...rest} />;
}

function FormFieldInputContainer(props: React.HTMLAttributes<HTMLDivElement>) {
  const { className, ...rest } = props;

  return <div className={cn(css["input-container"], className)} {...rest} />;
}

function FormFieldTextInput(
  props: React.InputHTMLAttributes<HTMLInputElement>
) {
  const { className, autoFocus, ...rest } = props;
  const ref = useRef<HTMLInputElement | null>(null);

  useAutoFocus(ref, autoFocus);

  return (
    <input ref={ref} className={cn(css["text-input"], className)} {...rest} />
  );
}

function FormFieldErrorMessage(
  props: React.InputHTMLAttributes<HTMLInputElement>
) {
  const { className, ...rest } = props;

  return <div className={cn(css["error-message"], className)} {...rest} />;
}

FormField.Label = FormFieldLabel;
FormField.InputContainer = FormFieldInputContainer;
FormField.TextInput = FormFieldTextInput;
FormField.ErrorMessage = FormFieldErrorMessage;
