import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../components/Button";
import { MobileScreenLayout } from "../../../components/MobileScreenLayout";
import FeedbackSuccess from "../../../assets/kdp-feedback-success.png";
import { ResetPasswordSuccessScreenProps } from "./useResetPasswordSuccessScreen";

export function ResetPasswordSuccessScreen(
  props: ResetPasswordSuccessScreenProps
): React.ReactElement {
  const { finishURI, handleActionOnPress } = props;
  return (
    <MobileScreenLayout>
      <MobileScreenLayout.Body>
        <div className="h-full flex flex-col justify-center text-center items-center pb-44">
          <img
            className="mb-8 self-center w-24 h-24 pointer-events-none"
            src={FeedbackSuccess}
          />
          <h1 className="font-heading text-heading-2 mb-4">
            <FormattedMessage
              id="mobile.screens.resetPasswordSuccess.title"
              defaultMessage="Your password has been reset successfully"
            />
          </h1>

          <p className="text-body-1">
            <FormattedMessage
              id="mobile.screens.resetPasswordSuccess.description"
              defaultMessage="You can now login with the new password."
            />
          </p>
        </div>
      </MobileScreenLayout.Body>
      {finishURI ? (
        <MobileScreenLayout.FixedFooter>
          <Button
            className="w-full"
            type="button"
            preventFocusOnPress={true}
            onPress={handleActionOnPress}
          >
            <FormattedMessage
              id="mobile.screens.resetPasswordSuccess.action"
              defaultMessage="Continue"
            />
          </Button>
        </MobileScreenLayout.FixedFooter>
      ) : null}
    </MobileScreenLayout>
  );
}
