import { useEffect } from "react";
import { setIsReady, useIsReady } from "../states/states";

export function useReady(): void {
  const isReady = useIsReady();
  useEffect(() => {
    if (isReady) {
      return;
    }
    setIsReady(true);
    document.dispatchEvent(
      new CustomEvent("latte:event", {
        detail: {
          type: "ready",
        },
      })
    );
  }, [isReady]);
}
