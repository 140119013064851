import React from "react";
import { FormattedMessage } from "react-intl";
import { DialogErrorContent } from "./DialogErrorContent";

export const AccountLockedError: React.FC = () => {
  return (
    <DialogErrorContent
      title={
        <FormattedMessage
          id="mobile.components.maximumAttemptExceededError.title"
          defaultMessage="Reminder"
        />
      }
      description={
        <FormattedMessage
          id="mobile.errors.app.loginPasswordMaxAttemptExceeded"
          defaultMessage="You have exceeded password attempt limit. Your account will be locked for 5 minutes."
        />
      }
      confirmButtonText={
        <FormattedMessage
          id="mobile.components.maximumAttemptExceededError.confirm"
          defaultMessage="OK"
        />
      }
    />
  );
};
