import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { WorkflowInitializer, WorkflowProvider } from "./workflow";
import { Workflow } from "../states/workflows";
import { useAppNavigate } from "./hooks";
import { useSearchParams } from "react-router-dom";
import { makeWorkflowURL } from "./routes";
import { WebScreenLoadingIndicator } from "../components/web/ScreenLoadingIndicator";

interface WebWorkflowProviderProps extends React.PropsWithChildren {
  initializer: WorkflowInitializer;
}

export const WebWorkflowProvider: React.FC<WebWorkflowProviderProps> = ({
  children,
  initializer,
}) => {
  const navigate = useAppNavigate();
  const [searchParams] = useSearchParams();
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const isActiveGetter = useCallback(() => {
    return isMounted.current;
  }, []);

  const loadingComponent = useMemo(
    () => (
      <div className="flex-1 self-stretch flex items-center justify-center">
        <WebScreenLoadingIndicator />
      </div>
    ),
    []
  );

  const onInitialized = useCallback(
    (workflow: Workflow) => {
      navigate(makeWorkflowURL("web", workflow, searchParams), {
        replace: true,
        state: { workflow },
      });
    },
    [navigate, searchParams]
  );

  return (
    <WorkflowProvider
      isActive={isActiveGetter}
      initializer={initializer}
      LoadingComponent={loadingComponent}
      onInitialized={onInitialized}
    >
      {children}
    </WorkflowProvider>
  );
};
