import React from "react";
import { useShell } from "../../../shell/context";
import { useReauthForgotPasswordByEmailProceedScreen } from "./useReauthForgotPasswordByEmailProceedScreen";
import { KDPForgotPasswordByEmailProceedView } from "../../../components/forgot-password/ForgotPasswordByEmailProceedView.kdp";
import { ForgotPasswordByEmailProceedView } from "../../../components/forgot-password/ForgotPasswordByEmailProceedView";

export function ReauthForgotPasswordByEmailProceedScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useReauthForgotPasswordByEmailProceedScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPForgotPasswordByEmailProceedView {...props} />;
    default:
      return <ForgotPasswordByEmailProceedView {...props} />;
  }
}
