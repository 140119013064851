import {
  SendForgotPasswordCodeV2State,
  useSendEmailForgotPasswordCodeV2,
} from "../../../hooks/forgot-password/useSendForgotPasswordCodeV2";
import { Brand } from "../../../shell/brand";
import { useInteractionIsLoading } from "../../../states/states";
import { useDeclareScreenName } from "../../../tracking/hooks";
import { AppScreen, getScreenName } from "../../../tracking/screen";

export interface ForgotPasswordByEmailProps
  extends SendForgotPasswordCodeV2State {
  isLoading: boolean;
}

export function useForgotPasswordByEmailScreen(
  bu: Brand | null
): ForgotPasswordByEmailProps {
  useDeclareScreenName(getScreenName(bu, AppScreen.ForgotPasswordByEmail));

  const state = useSendEmailForgotPasswordCodeV2("email");
  const isLoading = useInteractionIsLoading();

  return {
    ...state,
    isLoading,
  };
}
