import { middlewareEndpoint } from "../config";

export class DecodeTokenError extends Error {
  readonly response: Response;

  constructor(response: Response) {
    super(`DecodeTokenError: status ${response.status}`);
    this.response = response;
  }
}

export async function decodeTokenAPI(token: string): Promise<string> {
  const httpResp = await fetch(new URL("/token/decode", middlewareEndpoint), {
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
    },
    body: token,
  });
  if (!httpResp.ok) {
    throw new DecodeTokenError(httpResp);
  }
  return httpResp.text();
}
