import { useInteractionIsLoading } from "../../../states/states";
import { useDeclareScreenName } from "../../../tracking/hooks";
import {
  ResetPasswordState,
  useResetPassword,
} from "../../../hooks/reset-password/useResetPassword";
import { useWorkflow } from "../../../shell/workflow";
import { WorkflowResetPassword } from "../../../states/workflows";
import { routes } from "../../../shell/routes";
import {
  PasswordChecks,
  checkPassword,
} from "../../../states/interaction/password";
import { useMemo } from "react";
import { Brand } from "../../../shell/brand";
import { AppScreen, getScreenName } from "../../../tracking/screen";

export interface ResetPasswordScreenProps extends ResetPasswordState {
  isLoading: boolean;
  passwordChecks: PasswordChecks;
}

export function useResetPasswordScreen(
  bu: Brand | null
): ResetPasswordScreenProps {
  useDeclareScreenName(getScreenName(bu, AppScreen.ResetPassword));

  const workflow = useWorkflow<WorkflowResetPassword>(
    "latte.IntentResetPassword",
    "latte.NodeValidatedResetPasswordCode"
  );

  const state = useResetPassword(
    workflow,
    routes.mobile.recovery.resetPasswordSuccess
  );
  const { password } = state;
  const passwordChecks = useMemo(() => checkPassword(password), [password]);

  const isLoading = useInteractionIsLoading();

  return {
    ...state,
    isLoading,
    passwordChecks,
  };
}
