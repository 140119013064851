import {
  useSendReauthForgotPasswordCode,
  SendReauthForgotPasswordCodeState,
} from "../../../hooks/reauth/useSendReauthForgotPasswordCode";
import { useBackCallback } from "../../../shell/MobileShell";
import { Brand } from "../../../shell/brand";
import { useWorkflow } from "../../../shell/workflow";
import { WorkflowReauthForgotPassword } from "../../../states/workflows";
import { useDeclareScreenName } from "../../../tracking/hooks";
import { AppScreen, getScreenName } from "../../../tracking/screen";

export interface ReauthForgotPasswordBySMSProps
  extends SendReauthForgotPasswordCodeState {}

export function useReauthForgotPasswordBySMSScreen(
  bu: Brand | null
): ReauthForgotPasswordBySMSProps {
  useDeclareScreenName(getScreenName(bu, AppScreen.ForgotPasswordBySMS));

  const workflow = useWorkflow<WorkflowReauthForgotPassword>(
    "latte.NodeForgotPasswordWithLoginID"
  );

  const state = useSendReauthForgotPasswordCode(workflow, "sms");
  useBackCallback(state.handleBack);

  return state;
}
