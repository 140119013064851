import { useState, useCallback, useMemo } from "react";
import { useDeclareScreenName } from "../../../tracking/hooks";
import { useLocation } from "react-router-dom";
import { useAppNavigate } from "../../../shell/hooks";
import { routes } from "../../../shell/routes";
import { WorkflowForgotPasswordV2 } from "../../../states/workflows";
import { ForgotPasswordNavigationState } from "../../../hooks/forgot-password/useSendForgotPasswordCodeV2";
import { maskEmail } from "../../../utils/email";
import { useForgotPasswordProceed } from "../../../hooks/forgot-password/useForgotPasswordProceed";
import { Brand } from "../../../shell/brand";
import { AppScreen, getScreenName } from "../../../tracking/screen";

export interface ForgotPasswordByEmailProceedScreenProps {
  currentWorkflow: string | undefined;
  maskedEmail: string | undefined;
  canBack: boolean;
  handleActionOnPress: () => void;
  handleBackActionOnPress: () => void;
  handleResetBySMSOnPress: () => void;
}

export function useForgotPasswordByEmailProceedScreen(
  bu: Brand | null
): ForgotPasswordByEmailProceedScreenProps {
  useDeclareScreenName(
    getScreenName(bu, AppScreen.ForgotPasswordByEmailProceed)
  );

  const navigate = useAppNavigate();
  const { state } = useLocation();

  const [workflow] = useState<WorkflowForgotPasswordV2 | undefined>(
    state?.workflow
  );

  const { canBack, handleActionOnPress, handleBackActionOnPress } =
    useForgotPasswordProceed({
      finishURI: workflow?.finishURI,
      channel: "email",
    });

  const handleResetBySMSOnPress = useCallback(() => {
    if (!workflow) {
      console.error("cannot reset by sms because previous workflow is missing");
      return;
    }
    const newState: ForgotPasswordNavigationState = {
      phoneNumber: workflow.phoneNumber!,
      maskedEmail: workflow.maskedEmail ?? "",
      backWorkflow: workflow,
    };
    navigate(
      {
        pathname: routes.mobile.recovery.forgotPassword.sms,
        search: location.search,
      },
      { state: newState, replace: true }
    );
  }, [navigate, workflow]);

  const furtherMaskedEmail = useMemo(
    () => maskEmail(workflow?.maskedEmail ?? ""),
    [workflow]
  );

  return {
    currentWorkflow: workflow?.current,
    maskedEmail: furtherMaskedEmail,
    canBack,
    handleActionOnPress,
    handleBackActionOnPress,
    handleResetBySMSOnPress,
  };
}
