import React, { useCallback, useMemo } from "react";
import {
  VerifyPhoneOTPState,
  useVerifyPhoneOTP,
} from "../../../hooks/auth/useVerifyPhoneOTP";
import { useRemainingSeconds } from "../../../hooks/timer";
import { useWorkflow } from "../../../shell/workflow";
import { useInteractionIsLoading } from "../../../states/states";
import { WorkflowAuth } from "../../../states/workflows";
import { useDeclareScreenName } from "../../../tracking/hooks";
import { useUIState } from "../../../shell/uistate";
import { isUserInitiate } from "../../../states/interaction/intents";
import { Brand } from "../../../shell/brand";
import { AppScreen, getScreenName } from "../../../tracking/screen";

export interface VerifyPhoneOTPScreenProps extends VerifyPhoneOTPState {
  isLoading: boolean;
  handleFormSubmit: (e: React.FormEvent) => void;
  remainingSeconds: number;
  maskedPhoneNumber: string;
}

export function useVerifyPhoneOTPScreen(
  brand: Brand | null
): VerifyPhoneOTPScreenProps {
  const { user_initiate } = useUIState();

  const screenName = useMemo(() => {
    const isLogin =
      user_initiate &&
      isUserInitiate(user_initiate) &&
      user_initiate === "login";
    const screen = isLogin
      ? AppScreen.LoginVerifyPhoneOTP
      : AppScreen.SignupVerifyPhoneOTP;
    return getScreenName(brand, screen);
  }, [brand, user_initiate]);

  useDeclareScreenName(screenName);

  const workflow = useWorkflow<WorkflowAuth>(
    "latte.NodeVerifyPhoneSMS",
    "latte.NodeAuthenticateOOBOTPPhone"
  );

  const state = useVerifyPhoneOTP(workflow);

  const isLoading = useInteractionIsLoading();

  const { handleVerify } = state;
  const handleFormSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      handleVerify();
    },
    [handleVerify]
  );

  const remainingSeconds = useRemainingSeconds(workflow.resendResetTimestamp!);

  return {
    ...state,
    isLoading,
    handleFormSubmit,
    remainingSeconds,
    maskedPhoneNumber: workflow.maskedPhoneNumber,
  };
}
