import {
  LoginSelectMFAMethodState,
  useLoginSelectMFAMethod,
} from "../../../../hooks/auth/useLoginSelectMFAMethod";
import { Brand } from "../../../../shell/brand";
import { useWorkflow } from "../../../../shell/workflow";
import { AuthUserInitiate } from "../../../../states/interaction/intents";
import { useInteractionIsLoading } from "../../../../states/states";
import { WorkflowAuth } from "../../../../states/workflows";
import { useDeclareScreenName } from "../../../../tracking/hooks";
import { AppScreen, getScreenName } from "../../../../tracking/screen";

export interface LoginSelectMFAMethodScreenProps
  extends LoginSelectMFAMethodState {
  isLoading: boolean;
  initiationIntent: AuthUserInitiate;
}

export function useLoginSelectMFAMethodScreen(
  brand: Brand | null
): LoginSelectMFAMethodScreenProps {
  useDeclareScreenName(getScreenName(brand, AppScreen.LoginSelectMFAMethod));

  const workflow = useWorkflow<WorkflowAuth>(
    "latte.NodeAuthenticateOOBOTPPhoneEnd",
    "latte.NodeAuthenticateEmailLoginLink"
  );

  const state = useLoginSelectMFAMethod(workflow);

  const isLoading = useInteractionIsLoading();

  return {
    ...state,
    isLoading,
    initiationIntent: workflow.initiationIntent,
  };
}
