import {
  OverlayTriggerState,
  useOverlayTriggerState,
} from "@react-stately/overlays";
import React, { useCallback, useMemo, useState } from "react";
import { DialogErrorContent } from "../../components/web/DialogErrorContent";
import { useErrorButtonText, useErrorMessage, useErrorTitle } from "../errors";
import { useInteractionStep } from "../../hooks/interaction";
import { useWorkflowNavigate } from "../../shell/hooks";
import { verifyCaptcha } from "../../states/interaction/steps";
import { WorkflowAuth } from "../../states/workflows";

export function useVerifyCaptcha(workflow: WorkflowAuth): {
  errorDialogState: OverlayTriggerState;
  errorDialogContent: React.ReactElement | null;
  onCaptchaSuccess: (token: string) => void;
  onCaptchaFailure: (err: unknown) => void;
} {
  const navigateWorkflow = useWorkflowNavigate();
  const errorDialogState = useOverlayTriggerState({
    defaultOpen: false,
  });

  const [error, setError] = useState<unknown>(null);

  const title = useErrorTitle(error);
  const message = useErrorMessage(error, true);
  const buttonText = useErrorButtonText(error);

  const { trigger: triggerVerifyCaptcha } = useInteractionStep(
    workflow,
    verifyCaptcha
  );

  const onCaptchaSuccess = useCallback(
    (token: string) => {
      if (workflow.current !== "latte.IntentVerifyCaptcha") {
        return;
      }
      triggerVerifyCaptcha(workflow, token)
        .then(async (result) => {
          return navigateWorkflow(result);
        })
        .catch((e) => {
          setError(e);
          errorDialogState.open();
        });
    },
    [errorDialogState, navigateWorkflow, triggerVerifyCaptcha, workflow]
  );

  const onCaptchaFailure = useCallback(
    (err: unknown) => {
      if (workflow.current !== "latte.IntentVerifyCaptcha") {
        return;
      }
      setError(err);
      errorDialogState.open();
    },
    [errorDialogState, workflow]
  );

  const errorDialogContent = useMemo(() => {
    if (error == null) {
      return null;
    }
    return (
      <DialogErrorContent
        title={title}
        description={message}
        confirmButtonText={buttonText}
      />
    );
  }, [buttonText, error, message, title]);

  return {
    errorDialogState,
    errorDialogContent,
    onCaptchaSuccess,
    onCaptchaFailure,
  };
}
