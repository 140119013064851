import { useState, useCallback, useMemo } from "react";
import { useDeclareScreenName } from "../../../tracking/hooks";
import { useLocation } from "react-router-dom";
import { useAppNavigate } from "../../../shell/hooks";
import { routes } from "../../../shell/routes";
import { maskEmail } from "../../../utils/email";
import { useForgotPasswordProceed } from "../../../hooks/forgot-password/useForgotPasswordProceed";
import { WorkflowReauthForgotPassword } from "../../../states/workflows";
import { ReauthForgotPasswordNavigationState } from "../../../hooks/reauth/useSendReauthForgotPasswordCode";
import { AppScreen, getScreenName } from "../../../tracking/screen";
import { Brand } from "../../../shell/brand";

export interface ReauthForgotPasswordByEmailProceedScreenProps {
  currentWorkflow: string | undefined;
  maskedEmail: string | undefined;
  canBack: boolean;
  handleActionOnPress: () => void;
  handleBackActionOnPress: () => void;
  handleResetBySMSOnPress: () => void;
}

export function useReauthForgotPasswordByEmailProceedScreen(
  bu: Brand | null
): ReauthForgotPasswordByEmailProceedScreenProps {
  useDeclareScreenName(
    getScreenName(bu, AppScreen.ForgotPasswordByEmailProceed)
  );

  const navigate = useAppNavigate();
  const { state } = useLocation();

  const [workflow] = useState<WorkflowReauthForgotPassword | undefined>(
    state?.workflow
  );

  const { canBack, handleActionOnPress, handleBackActionOnPress } =
    useForgotPasswordProceed({
      finishURI: workflow?.finishURI,
      channel: "email",
    });

  const handleResetBySMSOnPress = useCallback(() => {
    if (!workflow) {
      console.error(
        "useReauthForgotPasswordByEmailProceedScreen: workflow is missing"
      );
      return;
    }
    const newState: ReauthForgotPasswordNavigationState = {
      email: workflow.email,
      phoneNumber: workflow.phoneNumber,
      backWorkflow: workflow,
    };
    navigate(
      {
        pathname: routes.mobile.reauth.forgotPassword.sms,
        search: location.search,
      },
      { replace: true, state: newState }
    );
  }, [navigate, workflow]);

  const maskedEmail = useMemo(
    () => maskEmail(workflow?.email ?? ""),
    [workflow]
  );

  return {
    currentWorkflow: workflow?.current,
    maskedEmail,
    canBack,
    handleActionOnPress,
    handleBackActionOnPress,
    handleResetBySMSOnPress,
  };
}
