/* eslint-disable no-use-before-define */
import { authgearAPI, middlewareEndpoint } from "../../config";
import {
  Workflow as WorkflowModel,
  type WorkflowResetPassword,
} from "../workflows";
import { AuthgearError, WorkflowError } from "./errors";

interface AuthgearResponse {
  result?: WorkflowState;
  error?: AuthgearErrorShape;
}

interface AuthgearErrorShape {
  name: string;
  reason: string;
  message: string;
  info?: unknown;
}

export type WorkflowAction =
  | { type: "continue" }
  | { type: "finish"; redirect_uri?: string };

interface WorkflowState {
  action: WorkflowAction;
  workflow: Workflow;
}

interface Workflow {
  workflow_id?: string;
  instance_id?: string;
  intent: WorkflowObject;
  nodes?: WorkflowNode[];
}

interface WorkflowObject {
  kind: string;
  data?: unknown;
}

type WorkflowNode =
  | {
      type: "SUB_WORKFLOW";
      workflow: Workflow;
    }
  | {
      type: "SIMPLE";
      simple: WorkflowObject;
    };

export interface WorkflowResult {
  action: WorkflowAction;
  workflowID: string;
  instanceID: string;
  intents: string[];
  root: string;
  current: string;
  data: Record<string, unknown>;
}

function parseAuthgearResponse<T extends WorkflowModel>(
  resp: AuthgearResponse,
  mapper: WorkflowMapper<T>
): T {
  let workflow: T | undefined;
  if (resp.result != null) {
    workflow = mapper(parseWorkflow(resp.result));
  }

  let authgearError: AuthgearError | undefined;
  if (resp.error != null) {
    authgearError = new AuthgearError(
      resp.error.name,
      resp.error.reason,
      resp.error.message,
      resp.error.info
    );
  }

  if (workflow == null) {
    if (authgearError == null) {
      throw new Error(
        `unexpected response from server: ${JSON.stringify(resp)}`
      );
    }
    throw authgearError;
  }

  if (authgearError == null) {
    return workflow;
  }
  throw new WorkflowError(workflow, authgearError);
}

function parseWorkflow(state: WorkflowState): WorkflowResult {
  const intents = new Set<string>();
  let current = "";
  const data: Record<string, unknown> = {};

  const collect = (workflow: Workflow) => {
    current = workflow.intent.kind;
    intents.add(workflow.intent.kind);
    for (const [key, value] of Object.entries(workflow.intent.data ?? {})) {
      data[key] = value;
    }

    for (const n of workflow.nodes ?? []) {
      switch (n.type) {
        case "SUB_WORKFLOW":
          collect(n.workflow);
          break;
        case "SIMPLE":
          current = n.simple.kind;
          for (const [key, value] of Object.entries(n.simple.data ?? {})) {
            data[key] = value;
          }
          break;
      }
    }
  };
  collect(state.workflow);

  return {
    action: state.action,
    workflowID: state.workflow.workflow_id!,
    instanceID: state.workflow.instance_id!,
    intents: Array.from(intents),
    root: state.workflow.intent.kind,
    current,
    data,
  };
}

export type WorkflowMapper<T extends WorkflowModel> = (
  result: WorkflowResult
) => T;

async function callWorkflow<T extends WorkflowModel>(
  mapper: WorkflowMapper<T>,
  path: string,
  init: RequestInit = {}
): Promise<T> {
  const url = new URL(path, authgearAPI);
  const httpResp = await fetch(url, {
    credentials: "include",
    ...init,
  });

  const resp: AuthgearResponse = await httpResp.json();
  return parseAuthgearResponse(resp, mapper);
}

export interface NewWorkflowOptions {
  credentials?: RequestCredentials;
  search?: URLSearchParams;
  bindUserAgent?: boolean;
}

export async function workflowNewWithBatchInput<T extends WorkflowModel>(
  mapper: WorkflowMapper<T>,
  intent: {
    kind: string;
    data: unknown;
  },
  batchInput: {
    kind: string;
    data: unknown;
  }[],
  options: NewWorkflowOptions = {}
): Promise<T> {
  const { credentials = "include", search, bindUserAgent = true } = options;
  return callWorkflow(mapper, "/api/v2/workflows", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: "create",
      url_query: search?.toString() ?? undefined,
      intent,
      bind_user_agent: bindUserAgent,
      batch_input: batchInput,
    }),
    credentials,
  });
}

export async function workflowGet<T extends WorkflowModel>(
  mapper: WorkflowMapper<T>,
  workflowID: string,
  instanceID: string
): Promise<T> {
  return callWorkflow(mapper, "/api/v2/workflows", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: "get",
      workflow_id: workflowID,
      instance_id: instanceID,
    }),
  });
}

export async function workflowInput<T extends WorkflowModel>(
  mapper: WorkflowMapper<T>,
  workflowID: string,
  instanceID: string,
  input: string,
  data: unknown = {}
): Promise<T> {
  return callWorkflow(mapper, "/api/v2/workflows", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: "input",
      workflow_id: workflowID,
      instance_id: instanceID,
      input: { kind: input, data },
    }),
  });
}

export async function workflowBatchInput<T extends WorkflowModel>(
  mapper: WorkflowMapper<T>,
  workflowID: string,
  instanceID: string,
  batchInput: {
    kind: string;
    data: unknown;
  }[]
): Promise<T> {
  return callWorkflow(mapper, "/api/v2/workflows", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: "batch_input",
      workflow_id: workflowID,
      instance_id: instanceID,
      batch_input: batchInput,
    }),
  });
}

export interface WorkflowEvent {
  kind: "refresh";
}

export function workflowEvents(
  workflowID: string,
  eventCallback: (workflowEvent: WorkflowEvent) => void
): () => void {
  const wsHost = authgearAPI.replace(/^http/, "ws");
  const wsURL = new URL("/api/v1/workflows/" + workflowID + "/ws", wsHost);

  let stop = false;
  let ws: WebSocket | null = null;
  let retryCount = 0;

  function close() {
    if (ws == null || ws.readyState !== 1) {
      return;
    }
    ws.onclose = null;
    ws.close();
    ws = null;
  }

  function retry() {
    if (stop) {
      return;
    }
    retryCount++;
    const delay = Math.max(Math.pow(2, retryCount), 20) * 500;

    setTimeout(() => {
      close();
      if (!stop) {
        ws = setupWebSocket();
      }
    }, delay);
  }

  function setupWebSocket(): WebSocket {
    const ws = new WebSocket(wsURL);
    ws.onopen = () => {
      retryCount = 0;
    };
    ws.onclose = (e) => {
      if (e.code === 1000) {
        return;
      }
      retry();
    };
    ws.onerror = (e) => {
      console.error(e);
    };
    ws.onmessage = (e) => {
      const message = JSON.parse(e.data);
      eventCallback(message);
    };

    return ws;
  }

  ws = setupWebSocket();
  const pingHandle = setInterval(() => {
    if (ws != null && ws.readyState === 1) {
      ws.send("");
    }
  }, 10 * 1000);

  return () => {
    stop = true;
    close();
    clearInterval(pingHandle);
  };
}

export async function resetPasswordAPI(
  mapper: WorkflowMapper<WorkflowResetPassword>,
  workflowID: string,
  instanceID: string,
  newPassword: string
): Promise<WorkflowResetPassword> {
  const httpResp = await fetch(new URL("/reset_password", middlewareEndpoint), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      workflow_id: workflowID,
      instance_id: instanceID,
      new_password: newPassword,
    }),
  });

  const resp: AuthgearResponse = await httpResp.json();
  return parseAuthgearResponse(resp, mapper);
}

export async function checkRedirectURI(redirectURI: string): Promise<void> {
  const r = new URL(redirectURI);
  // clear query parameter and fragment
  r.search = "";
  r.hash = "";
  // allow latte://complete
  if (r.toString() === "latte://complete") {
    return;
  }

  // allow same origin as authgear api endpoint
  if (r.toString().startsWith(authgearAPI)) {
    return;
  }

  const url = new URL("/oauth2/redirect", authgearAPI);
  url.search = new URLSearchParams({ redirect_uri: redirectURI }).toString();
  const httpResp = await fetch(url, {
    method: "GET",
  });

  if (httpResp.status !== 200) {
    throw new Error("redirect uri is not allowed");
  }
}
