import { toByteArray, fromByteArray } from "base64-js";

export function base64URLToBase64(base64url: string): string {
  let base64 = base64url.replace(/-/g, "+").replace(/_/g, "/");
  if (base64.length % 4 !== 0) {
    const count = 4 - (base64.length % 4);
    base64 += "=".repeat(count);
  }
  return base64;
}

export function base64ToBase64URL(base64: string): string {
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

export function base64Decode(base64: string): Uint8Array {
  return toByteArray(base64);
}

export function base64URLDecode(base64URL: string): Uint8Array {
  return base64Decode(base64URLToBase64(base64URL));
}

export function base64Encode(bytes: Uint8Array): string {
  return fromByteArray(bytes);
}

export function base64URLEncode(bytes: Uint8Array): string {
  return base64ToBase64URL(base64Encode(bytes));
}
