import React from "react";
import { useReauthPasswordScreen } from "./useReauthPasswordScreen";
import { ReauthPasswordScreen as Screen } from "./ReauthPasswordScreen";
import { useShell } from "../../../shell/context";

export function ReauthPasswordScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useReauthPasswordScreen(brand);

  return <Screen {...props} />;
}
