import { useCallback } from "react";
import { useWorkflowNavigate } from "../../shell/hooks";
import { resendEmailOTP } from "../../states/interaction/steps";
import { WorkflowAuth } from "../../states/workflows";
import { useErrorMessage } from "../errors";
import { useInteractionStep } from "../interaction";
import { useInteractionIsLoading } from "../../states/states";
import { useLoginLinkHandler } from "../login-link";

export interface LoginVerifyMFAEmailState {
  error: unknown;
  errorMessage: string;
  handleDismiss: () => void;
  handleCheckEmail: () => void;
  handleResend: () => void;
  isProceeding: boolean;
  isResending: boolean;
}

export const useLoginVerifyMFAEmail = (
  TAG: string,
  workflow: WorkflowAuth,
  screenIsActive: boolean
): LoginVerifyMFAEmailState => {
  const navigateWorkflow = useWorkflowNavigate();

  const isLoading = useInteractionIsLoading();
  const { trigger: resendOTP, isLoading: isResending } = useInteractionStep(
    workflow,
    resendEmailOTP
  );
  const { error, setError, isProceeding } = useLoginLinkHandler(
    TAG,
    workflow,
    screenIsActive
  );
  const errorMessage = useErrorMessage(error);

  const handleDismiss = useCallback(() => {
    if (isLoading || isResending || isProceeding) {
      return;
    }
    history.back();
  }, [isLoading, isResending, isProceeding]);

  const handleCheckEmail = useCallback(() => {
    document.dispatchEvent(
      new CustomEvent("latte:event", {
        detail: { type: "openEmailClient" },
      })
    );
  }, []);

  const handleResend = useCallback(() => {
    (async () => {
      setError(null);
      try {
        await navigateWorkflow(await resendOTP(workflow));
      } catch (err: unknown) {
        setError(err);
      }
    })().catch(() => {});
  }, [setError, resendOTP, workflow, navigateWorkflow]);

  return {
    error,
    errorMessage,
    handleDismiss,
    handleCheckEmail,
    handleResend,
    isProceeding,
    isResending,
  };
};
