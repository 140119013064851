import React, { useCallback, useMemo, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useButton } from "@react-aria/button";
import { ReactComponent as IconTimes } from "../../../../assets/times.svg";
import { Button } from "../../../../components/Button";
import { DialogLayout } from "../../../../components/DialogLayout";
import { LoginVerifyMFAEmailScreenProps } from "./useLoginVerifyMFAEmailScreen";

export function LoginVerifyMFAEmailScreen(
  props: LoginVerifyMFAEmailScreenProps
): React.ReactElement {
  const {
    errorMessage,
    handleDismiss,
    handleCheckEmail,
    handleResend,
    remainingSeconds,
    maskedEmail,
    isLoading,
  } = props;

  const resendValues = useMemo(
    () => ({ remainingSeconds }),
    [remainingSeconds]
  );

  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      onPress: useCallback(() => {
        handleDismiss();
      }, [handleDismiss]),
      // @ts-expect-error
      preventFocusOnPress: true,
      excludeFromTabOrder: true,
    },
    closeButtonRef
  );

  return (
    <DialogLayout isDismissable={false}>
      <div className="flex flex-row items-center mb-8">
        <h2 className="flex-1 text-heading-3">
          <FormattedMessage
            id="mobile.screens.login.verifyMFAEmail.title"
            defaultMessage="Login by Email Verfication"
          />
        </h2>
        <button
          className="h-full"
          type="button"
          {...buttonProps}
          ref={closeButtonRef}
        >
          <IconTimes className="w-5 h-5 p-1 text-c-navigation-button" />
        </button>
      </div>
      <p className="mb-4 text-body-1">
        <FormattedMessage
          id="mobile.screens.login.verifyMFAEmail.description"
          defaultMessage="Verification link has been sent to below email address:"
        />
      </p>
      <p className="mb-7 font-semibold text-center text-body-1">
        {maskedEmail}
      </p>

      <Button className="w-full" onPress={handleCheckEmail}>
        <FormattedMessage
          id="mobile.screens.login.verifyMFAEmail.checkEmail"
          defaultMessage="Check Email"
        />
      </Button>

      <Button
        className="w-full"
        variant="secondary"
        disabled={remainingSeconds > 0 || isLoading}
        loading={isLoading}
        onPress={handleResend}
      >
        {remainingSeconds === 0 ? (
          <FormattedMessage
            id="mobile.screens.login.verifyMFAEmail.resend.action"
            defaultMessage="Resend"
          />
        ) : (
          <FormattedMessage
            id="mobile.screens.login.verifyMFAEmail.resend.cooldown"
            defaultMessage="Resend in {remainingSeconds}s"
            values={resendValues}
          />
        )}
      </Button>

      {errorMessage ? (
        <p className="mt-4 text-body-2 text-error">{errorMessage}</p>
      ) : null}
    </DialogLayout>
  );
}
