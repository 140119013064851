import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Workflow } from "../states/workflows";
import { useAppNavigate } from "./hooks";
import { useOneIDMemberID } from "../tracking/hooks";
import { useReady } from "../hooks/ready";

const WorkflowContext = React.createContext<Partial<Record<string, Workflow>>>(
  {}
);

export interface WorkflowInitializer {
  (searchParams: URLSearchParams, state: any): Promise<Workflow>;
  intent: string;
}
interface WorkflowProviderProps extends React.PropsWithChildren {
  initializer: WorkflowInitializer;
  isActive: () => boolean;
  LoadingComponent: React.ReactNode;
  onInitialized: (workflow: Workflow) => void;
}

function UseReady() {
  useReady();
  return null;
}

export const WorkflowProvider: React.FC<WorkflowProviderProps> = (props) => {
  const { initializer, children, isActive, LoadingComponent, onInitialized } =
    props;
  const [searchParams] = useSearchParams();

  const { state } = useLocation();
  const stateWorkflow: Workflow | null =
    state?.workflow?.root === initializer.intent ? state.workflow : null;

  const [workflows, setWorkflows] = useState<Partial<Record<string, Workflow>>>(
    () => {
      return stateWorkflow ? { [stateWorkflow.current]: stateWorkflow } : {};
    }
  );
  const navigate = useAppNavigate();

  const isLoading = useRef(false);
  const [error, setError] = useState<unknown>(null);
  useLayoutEffect(() => {
    if (Object.keys(workflows).length === 0 && !isLoading.current) {
      isLoading.current = true;
      initializer(searchParams, state).then(
        (workflow) => {
          if (!isActive()) {
            return;
          }
          setWorkflows((ws) => ({ ...ws, [workflow.current]: workflow }));
          onInitialized(workflow);
        },
        (err) => setError(err)
      );
    }
  }, [
    initializer,
    isActive,
    navigate,
    onInitialized,
    searchParams,
    workflows,
    state,
  ]);

  if (
    stateWorkflow != null &&
    workflows[stateWorkflow.current] !== stateWorkflow
  ) {
    setWorkflows((ws) => ({ ...ws, [stateWorkflow.current]: stateWorkflow }));
  }

  useOneIDMemberID(workflows);

  if (error != null) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw error;
  }

  return Object.keys(workflows).length > 0 ? (
    <WorkflowContext.Provider value={workflows}>
      <>
        <UseReady />
        {children}
      </>
    </WorkflowContext.Provider>
  ) : (
    <>{LoadingComponent}</>
  );
};

export function useWorkflow<T extends Workflow>(...expect: T["current"][]): T {
  const workflows = useContext(WorkflowContext);
  const [localWorkflow, setLocalworkflow] = useState(() =>
    Object.values(workflows).find(
      (w) => w != null && expect.includes(w.current)
    )
  );

  useLayoutEffect(() => {
    // Note(tung): We want to find a workflow from workflows which match one of the steps in "expect"
    const expectedWorkflow = expect
      .map((current) => workflows[current])
      // Use the first match
      .find((workflow) => workflow != null);

    if (expectedWorkflow != null) {
      setLocalworkflow(expectedWorkflow);
    }
  }, [workflows, expect]);

  if (localWorkflow == null || !expect.includes(localWorkflow.current)) {
    throw new Error(
      `unexpected workflow state: ${localWorkflow?.current} != ${expect.join()}`
    );
  }
  return localWorkflow as T;
}
