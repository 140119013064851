import { Workflow } from "../workflows";

export class AuthgearError extends Error {
  name: string;
  reason: string;
  info?: any;

  constructor(name: string, reason: string, message: string, info: unknown) {
    super(message);
    this.name = name;
    this.reason = reason;
    this.info = info;
  }

  stringify(): string {
    const obj = {
      name: this.name,
      reason: this.reason,
      info: this.info,
      message: this.message,
    };
    return JSON.stringify(obj);
  }

  static isWorkflowNotFound(err: unknown): err is AuthgearError {
    return err instanceof AuthgearError && err.reason === "WorkflowNotFound";
  }

  static isUserAgentUnmatched(err: unknown): err is AuthgearError {
    return err instanceof AuthgearError && err.reason === "UserAgentUnmatched";
  }
}

export class WorkflowError extends AuthgearError {
  workflow: Workflow;

  constructor(workflow: Workflow, raw: AuthgearError) {
    super(raw.name, raw.reason, `${workflow.current}: ${raw.reason}`, raw.info);
    this.workflow = workflow;
  }

  static is(err: unknown, reason: string): err is WorkflowError {
    return err instanceof WorkflowError && err.reason === reason;
  }
}
