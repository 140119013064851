import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../../components/web/Button";
import { WebScreenLayout } from "../../../../components/WebScreenLayout";
import { ForgotPasswordBySMSProps } from "./useForgotPasswordBySMSScreen";

export function WebForgotPasswordBySMSScreen(
  props: ForgotPasswordBySMSProps
): React.ReactElement {
  const {
    maskedPhoneNumber,
    errorMessage,
    handleSendForgotPasswordCode,
    isLoading,
  } = props;

  return (
    <WebScreenLayout>
      <WebScreenLayout.Body>
        <h1 className="font-heading text-heading-2">
          <FormattedMessage
            id="web.screens.forgotPassword.sms.title"
            defaultMessage="Forgot your password?"
          />
        </h1>
        <p className="mt-3 text-center text-body-1">
          <FormattedMessage
            id="web.screens.forgotPassword.sms.description"
            defaultMessage="Press Reset Password by SMS and we will send you a SMS at {maskedPhoneNumber} to reset the password."
            values={{
              maskedPhoneNumber: (
                <>
                  <wbr />
                  <b className="inline-block whitespace-normal">
                    {maskedPhoneNumber}
                  </b>
                  <wbr />
                </>
              ),
            }}
          />
        </p>

        {errorMessage ? (
          <p className="mt-4 text-body-2 text-error">{errorMessage}</p>
        ) : null}
        <Button
          className="w-full mt-12"
          type="button"
          onPress={handleSendForgotPasswordCode}
          preventFocusOnPress={true}
          loading={isLoading}
        >
          <FormattedMessage
            id="web.screens.forgotPassword.sms.action"
            defaultMessage="Reset Password by SMS"
          />
        </Button>
      </WebScreenLayout.Body>
    </WebScreenLayout>
  );
}
