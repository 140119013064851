export interface PasswordChecks {
  ok: boolean;
  minLength: [ok: boolean, minLength: number];
  alphabetic: boolean;
  numeric: boolean;
}

const minPasswordLength = 8;

export function checkPassword(password: string): PasswordChecks {
  const checks: Omit<PasswordChecks, "ok"> = {
    minLength: [password.length >= minPasswordLength, minPasswordLength],
    alphabetic: /[a-zA-Z]/.test(password),
    numeric: /[0-9]/.test(password),
  };
  return {
    ...checks,
    ok: checks.minLength[0] && checks.alphabetic && checks.numeric,
  };
}
