import React, { useCallback, useMemo, useState } from "react";
import {
  OverlayTriggerState,
  useOverlayTriggerState,
} from "@react-stately/overlays";
import { useAppNavigate, useWorkflowNavigate } from "../../shell/hooks";
import { WorkflowError } from "../../states/interaction/errors";
import { verifyReauthPassword } from "../../states/interaction/steps";
import { WorkflowReauth } from "../../states/workflows";
import { AppError } from "../../errors";
import { useErrorMessage } from "../errors";
import { useInteractionStep } from "../interaction";
import { AccountLockedError } from "../../components/AccountLockedError";
import { useUIState } from "../../shell/uistate";
import { Capability } from "../../shell/uistate_parse";
import { REDIRECT_URI } from "../../search";
import { excludeWorkflowParams, routes } from "../../shell/routes";
import { ReauthForgotPasswordNavigationState } from "./useSendReauthForgotPasswordCode";
import { incorrectPasswordWarningThreshold } from "../../config";
import { defineMessage } from "react-intl";

const warningThreshold = incorrectPasswordWarningThreshold;

export interface ReauthPasswordState {
  password: string;
  onChangePassword: (password: string) => void;
  error: unknown;
  errorMessage: string;
  errorDialogState: OverlayTriggerState;
  errorDialogContent: React.ReactNode;
  handleReauthByPassword: (e: React.FormEvent) => void;
  handleReauthByBiometric: () => void;
  handleForgotPassword: () => void;
  isLoading: boolean;
  canReauthByBiometric: boolean;
  warningThreshold: number;
  failedAttempts: number;
}

export function useReauthPassword(
  workflow: WorkflowReauth
): ReauthPasswordState {
  const navigateWorkflow = useWorkflowNavigate();
  const { capabilities } = useUIState();
  const navigate = useAppNavigate();

  const [error, setError] = useState<unknown>(null);
  const errorMessage = useErrorMessage(error);
  const errorDialogState = useOverlayTriggerState({
    defaultOpen: false,
  });
  const [errorDialogContent, setErrorDialogContent] =
    useState<React.ReactNode>(null);

  const [failedAttempts, setFailedAttempts] = useState<number>(0);

  const [password, setPassword] = useState("");
  const onChangePassword = useCallback((password: string) => {
    setPassword(password);
    setError(null);
  }, []);

  const { trigger, isLoading } = useInteractionStep(
    workflow,
    verifyReauthPassword
  );

  const handleError = useCallback(
    (err: unknown) => {
      if (err instanceof WorkflowError) {
        const currentAttemptCount = failedAttempts + 1;
        setFailedAttempts(currentAttemptCount);

        if (err.reason === "RateLimited" || err.reason === "AccountLockout") {
          errorDialogState.open();
          setErrorDialogContent(<AccountLockedError />);
        } else if (currentAttemptCount >= warningThreshold) {
          setError(
            new AppError(
              {
                message: defineMessage({
                  id: "mobile.errors.app.multipleInvalidPasswordAttempts",
                  defaultMessage:
                    "Incorrect password. Please reset your password if you lost it.",
                }),
              },
              {
                message: defineMessage({
                  id: "web.errors.app.multipleInvalidPasswordAttempts",
                  defaultMessage:
                    "Incorrect password. Please reset your password if you lost it.",
                }),
              }
            )
          );
        } else {
          setError(err);
        }
      } else {
        setError(err);
      }
    },
    [errorDialogState, failedAttempts]
  );

  const handleReauthByPassword = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      (async () => {
        setError(null);

        try {
          await navigateWorkflow(await trigger(workflow, password));
        } catch (err: unknown) {
          handleError(err);
        }
      })().catch(() => {});
    },
    [trigger, workflow, password, navigateWorkflow, handleError]
  );

  const handleReauthByBiometric = useCallback(() => {
    document.dispatchEvent(
      new CustomEvent("latte:event", {
        detail: { type: "reauthWithBiometric" },
      })
    );
  }, []);

  const handleForgotPassword = useCallback(() => {
    const query = new URLSearchParams(window.location.search);
    query.delete(REDIRECT_URI);
    const newState: ReauthForgotPasswordNavigationState = {
      email: workflow.email,
      phoneNumber: workflow.phoneNumber,
      backWorkflow: null,
    };
    navigate(
      {
        pathname: routes.mobile.reauth.forgotPassword.email,
        search: excludeWorkflowParams(query).toString(),
      },
      { state: newState }
    );
  }, [navigate, workflow]);

  const canReauthByBiometric = useMemo(() => {
    return capabilities?.includes(Capability.Biometric) ?? false;
  }, [capabilities]);

  return {
    password,
    onChangePassword,
    error,
    errorMessage,
    errorDialogState,
    errorDialogContent,
    handleReauthByPassword,
    handleReauthByBiometric,
    handleForgotPassword,
    isLoading,
    canReauthByBiometric,
    warningThreshold,
    failedAttempts,
  };
}
