import { MessageDescriptor } from "react-intl";
import { Platform } from "./shell/platform";

interface AppErrorMessages {
  title?: MessageDescriptor;
  message: MessageDescriptor;
}

export class AppError extends Error {
  readonly mobileMessage: AppErrorMessages;
  readonly webMessage: AppErrorMessages;
  constructor(mobileMessage: AppErrorMessages, webMessage: AppErrorMessages) {
    super(mobileMessage.message.id);
    this.mobileMessage = mobileMessage;
    this.webMessage = webMessage;
  }

  getTitle(platform: Platform): MessageDescriptor | undefined {
    return platform === "mobile"
      ? this.mobileMessage.title
      : this.webMessage.title;
  }
}

export class AppCancelError extends AppError {}
