import React from "react";
import { useIsLocaleLoaded } from "../intl/IntlProvider";
import { ScreenLoadingIndicator } from "./ScreenLoadingIndicator";

interface MobileScreenLayoutProps extends React.PropsWithChildren {}

export function MobileScreenLayout(
  props: MobileScreenLayoutProps
): React.ReactElement {
  const { children } = props;

  return <div className="w-full h-full flex flex-col">{children}</div>;
}

const MobileScreenLayoutBody = ({ children }: React.PropsWithChildren) => {
  const isLocaleLoaded = useIsLocaleLoaded();
  return (
    <div className="flex-1 overflow-auto px-5 pt-4 only:pb-[var(--inset-bottom)]">
      {isLocaleLoaded ? children : <ScreenLoadingIndicator />}
    </div>
  );
};
MobileScreenLayout.Body = MobileScreenLayoutBody;

const MobileScreenLayoutFixedFooter = ({
  children,
}: React.PropsWithChildren) => {
  const isLocaleLoaded = useIsLocaleLoaded();
  return (
    <div className="p-5 pb-0 bg-white">
      <div className="pb-[var(--inset-bottom)] ease-out duration-300">
        {isLocaleLoaded ? children : null}
      </div>
    </div>
  );
};
MobileScreenLayout.FixedFooter = MobileScreenLayoutFixedFooter;
