import { useMemo } from "react";
import {
  defineMessage,
  IntlShape,
  MessageDescriptor,
  useIntl,
} from "react-intl";
import { captureException } from "@sentry/react";
import { useShell } from "../shell/context";
import { Platform } from "../shell/platform";
import { isAuthenticationFlow } from "../shell/routes";
import { AuthgearError, WorkflowError } from "../states/interaction/errors";
import { NWDError } from "../states/nwd-api";
import { useTrackError } from "../tracking/hooks";
import { DecodeTokenError } from "../apis/token";
import { AppError } from "../errors";

export function formatAuthgearError(
  err: AuthgearError,
  intl: IntlShape,
  platform: Platform
): string {
  function platformSpecificMessage(
    mobileMessage: MessageDescriptor,
    webMessage: MessageDescriptor
  ) {
    return platform === "mobile"
      ? intl.formatMessage(mobileMessage)
      : intl.formatMessage(webMessage);
  }

  if (AuthgearError.isUserAgentUnmatched(err)) {
    return platformSpecificMessage(
      defineMessage({
        id: "mobile.errors.workflow.userAgentUnmatched",
        defaultMessage:
          "Copying and pasting the URL into another browser is not supported. Please continue in your original browser.",
      }),
      defineMessage({
        id: "web.errors.workflow.userAgentUnmatched",
        defaultMessage:
          "Copying and pasting the URL into another browser is not supported. Please continue in your original browser.",
      })
    );
  }

  // Send the unknown error to Sentry.
  captureException(err);

  return platformSpecificMessage(
    defineMessage({
      id: "mobile.errors.unknown",
      defaultMessage: "Something went wrong, please try again later.",
    }),
    defineMessage({
      id: "web.errors.unknown",
      defaultMessage: "Something went wrong, please try again later.",
    })
  );
}

// eslint-disable-next-line complexity
export function formatWorkflowError(
  err: WorkflowError,
  intl: IntlShape,
  platform: Platform
): string {
  function platformSpecificMessage(
    mobileMessage: MessageDescriptor,
    webMessage: MessageDescriptor
  ) {
    return platform === "mobile"
      ? intl.formatMessage(mobileMessage)
      : intl.formatMessage(webMessage);
  }

  const workflow = err.workflow;
  switch (err.reason) {
    case "ValidationFailed":
      // {
      //     "name": "Invalid",
      //     "reason": "ValidationFailed",
      //     "message": "invalid login ID",
      //     "code": 400,
      //     "info": {
      //         "causes": [
      //             {
      //                 "location": "/login_id",
      //                 "kind": "format",
      //                 "details": {
      //                     "format": "phone"
      //                 }
      //             }
      //         ]
      //     }
      // }
      if (err.info?.causes?.some((x: any) => x?.details?.format === "phone")) {
        return platformSpecificMessage(
          defineMessage({
            id: "mobile.errors.workflow.validationFailed.phone",
            defaultMessage: "Please enter a valid phone number.",
          }),
          defineMessage({
            id: "web.errors.workflow.validationFailed.phone",
            defaultMessage: "Please enter a valid phone number.",
          })
        );
      }
      if (err.info?.causes?.some((x: any) => x?.details?.format === "email")) {
        return platformSpecificMessage(
          defineMessage({
            id: "mobile.errors.workflow.validationFailed.email",
            defaultMessage: "Incorrect email. Please provide a valid email.",
          }),
          defineMessage({
            id: "web.errors.workflow.validationFailed.email",
            defaultMessage: "Incorrect email. Please provide a valid email.",
          })
        );
      }
      break;

    case "InvalidVerificationCode":
      return platformSpecificMessage(
        defineMessage({
          id: "mobile.errors.workflow.invalidVerificationCode",
          defaultMessage:
            "Verification code entered is invalid, please try again.",
        }),
        defineMessage({
          id: "web.errors.workflow.invalidVerificationCode",
          defaultMessage:
            "Verification code entered is invalid, please try again.",
        })
      );

    case "InvalidOTPCode":
      return platformSpecificMessage(
        defineMessage({
          id: "mobile.errors.workflow.invalidLoginLink",
          defaultMessage: "The login link has expired. Please sign in again.",
        }),
        defineMessage({
          id: "web.errors.workflow.invalidLoginLink",
          defaultMessage: "The login link has expired. Please sign in again.",
        })
      );

    case "InvalidCredentials":
      switch (workflow.current) {
        case "latte.NodeVerifyPhoneSMS":
        case "latte.NodeAuthenticateOOBOTPPhone":
          return platformSpecificMessage(
            defineMessage({
              id: "mobile.errors.workflow.invalidCredentials.code",
              defaultMessage:
                "Verification code entered is invalid, please try again.",
            }),
            defineMessage({
              id: "web.errors.workflow.invalidCredentials.code",
              defaultMessage:
                "Verification code entered is invalid, please try again.",
            })
          );

        case "latte.NodeChangePassword":
          return platformSpecificMessage(
            defineMessage({
              id: "mobile.errors.workflow.invalidCredentials.currentPassword",
              defaultMessage: "Not match with current password",
            }),
            defineMessage({
              id: "web.errors.workflow.invalidCredentials.currentPassword",
              defaultMessage: "Not match with current password",
            })
          );

        default:
          return platformSpecificMessage(
            defineMessage({
              id: "mobile.errors.workflow.invalidCredentials.password",
              defaultMessage: "Incorrect password, please try again.",
            }),
            defineMessage({
              id: "web.errors.workflow.invalidCredentials.password",
              defaultMessage: "Incorrect password, please try again.",
            })
          );
      }

    case "RateLimited":
      if (err.info?.bucket_name === "TrackFailedOTPAttemptBucket") {
        return platformSpecificMessage(
          defineMessage({
            id: "mobile.errors.workflow.rateLimited.verifyCode",
            defaultMessage:
              "You have exceeded the maximum of attempts for entering a verification code, please try again later.",
          }),
          defineMessage({
            id: "web.errors.workflow.rateLimited.verifyCode",
            defaultMessage:
              "You have exceeded the maximum of attempts for entering a verification code, please try again later.",
          })
        );
      }
      if (/SMS/.test(err.info?.bucket_name)) {
        return platformSpecificMessage(
          defineMessage({
            id: "mobile.errors.workflow.rateLimited.sms",
            defaultMessage:
              "You have exceeded the SMS receiving limit, please try again later.",
          }),
          defineMessage({
            id: "web.errors.workflow.rateLimited.sms",
            defaultMessage:
              "You have exceeded the SMS receiving limit, please try again later.",
          })
        );
      }
      if (/Email/.test(err.info?.bucket_name)) {
        return platformSpecificMessage(
          defineMessage({
            id: "mobile.errors.workflow.rateLimited.email",
            defaultMessage:
              "You have exceeded the email receiving limit, please try again later.",
          }),
          defineMessage({
            id: "web.errors.workflow.rateLimited.email",
            defaultMessage:
              "You have exceeded the email receiving limit, please try again later.",
          })
        );
      }
      return platformSpecificMessage(
        defineMessage({
          id: "mobile.errors.workflow.rateLimited.generic",
          defaultMessage: "Too many attempts, please try again later.",
        }),
        defineMessage({
          id: "web.errors.workflow.rateLimited.generic",
          defaultMessage: "Too many attempts, please try again later.",
        })
      );

    case "AccountLockout":
      return platformSpecificMessage(
        defineMessage({
          id: "mobile.errors.app.loginPasswordMaxAttemptExceeded",
          defaultMessage:
            "You have exceeded password attempt limit. Your account will be locked for 5 minutes.",
        }),
        defineMessage({
          id: "web.errors.app.loginPasswordMaxAttemptExceeded",
          defaultMessage:
            "You have exceeded password attempt limit. Your account will be locked for 5 minutes.",
        })
      );

    case "UserNotFound":
      switch (workflow.current) {
        case "latte.IntentForgotPassword":
          return platformSpecificMessage(
            defineMessage({
              id: "mobile.errors.workflow.userNotFound",
              defaultMessage:
                "We couldn’t find a registered account with the entered email",
            }),
            defineMessage({
              id: "web.errors.workflow.userNotFound",
              defaultMessage:
                "We couldn’t find a registered account with the entered email",
            })
          );
      }
      break;

    case "InvariantViolated":
      // Example error response
      //"error": {
      //    "name": "Invalid",
      //    "reason": "InvariantViolated",
      //    "message": "identity already exists",
      //    "code": 400,
      //    "info": {
      //        "IdentityTypeExisting": "login_id",
      //        "IdentityTypeIncoming": "login_id",
      //        "LoginIDTypeExisting": "phone",
      //        "LoginIDTypeIncoming": "phone",
      //        "cause": {
      //            "kind": "DuplicatedIdentity"
      //        }
      //    }
      //}
      if (err.info?.cause.kind === "DuplicatedIdentity") {
        if (err.info?.LoginIDTypeIncoming === "phone") {
          return platformSpecificMessage(
            defineMessage({
              id: "mobile.errors.workflow.duplicatedPhone",
              defaultMessage:
                "This phone number has been registered. Please sign in instead.",
            }),
            defineMessage({
              id: "web.errors.workflow.duplicatedPhone",
              defaultMessage:
                "This phone number has been registered. Please sign in instead.",
            })
          );
        }
        if (err.info?.LoginIDTypeIncoming === "email") {
          return platformSpecificMessage(
            defineMessage({
              id: "mobile.errors.workflow.duplicatedEmail",
              defaultMessage:
                "This email has been registered in One Login, please use another email.",
            }),
            defineMessage({
              id: "web.errors.workflow.duplicatedEmail",
              defaultMessage:
                "This email has been registered in One Login, please use another email.",
            })
          );
        }
      }
      break;

    case "PasswordPolicyViolated":
      return platformSpecificMessage(
        defineMessage({
          id: "mobile.errors.workflow.passwordPolicyViolated",
          defaultMessage: "Invalid new password",
        }),
        defineMessage({
          id: "web.errors.workflow.passwordPolicyViolated",
          defaultMessage: "Invalid new password",
        })
      );
    case "PasswordResetFailed":
      if (
        ["InvalidCode", "UsedCode", "ExpiredCode"].includes(
          err.info?.cause?.kind
        )
      ) {
        return platformSpecificMessage(
          defineMessage({
            id: "mobile.errors.workflow.invalidResetPasswordCode",
            defaultMessage: "The link you've visited is expired.",
          }),
          defineMessage({
            id: "web.errors.workflow.invalidResetPasswordCode",
            defaultMessage: "The link you've visited is expired.",
          })
        );
      }
      break;

    case "CaptchaFailed":
      return platformSpecificMessage(
        defineMessage({
          id: "mobile.errors.workflow.captchaVerificationFailed",
          defaultMessage:
            "We couldn’t verify the captcha response, please try again later.",
        }),
        defineMessage({
          id: "web.errors.workflow.captchaVerificationFailed",
          defaultMessage:
            "We couldn’t verify the captcha response, please try again later.",
        })
      );
  }

  // Send the unknown error to Sentry.
  captureException(err);

  return platformSpecificMessage(
    defineMessage({
      id: "mobile.errors.unknown",
      defaultMessage: "Something went wrong, please try again later.",
    }),
    defineMessage({
      id: "web.errors.unknown",
      defaultMessage: "Something went wrong, please try again later.",
    })
  );
}

function formatWorkflowExpiredError(
  intl: IntlShape,
  platform: Platform
): string {
  const isAuthFlow = isAuthenticationFlow(platform, location.pathname);
  if (isAuthFlow) {
    return platform === "mobile"
      ? intl.formatMessage({
          id: "mobile.errors.workflow.expired.auth",
          defaultMessage:
            "It seems that the current session has expired. Please verify again with a new verification code via SMS.",
        })
      : intl.formatMessage({
          id: "web.errors.workflow.expired.auth",
          defaultMessage:
            "It seems that the current session has expired. Please verify again with a new verification code via SMS.",
        });
  }

  return platform === "mobile"
    ? intl.formatMessage({
        id: "mobile.errors.workflow.expired",
        defaultMessage:
          "It seems that the current session has expired. Please try again.",
      })
    : intl.formatMessage({
        id: "web.errors.workflow.expired",
        defaultMessage:
          "It seems that the current session has expired. Please try again.",
      });
}

function formatNetworkError(intl: IntlShape, platform: Platform): string {
  return platform === "mobile"
    ? intl.formatMessage({
        id: "mobile.errors.app.network",
        defaultMessage:
          "It seems that there are network errors, please try again later.",
      })
    : intl.formatMessage({
        id: "web.errors.app.network",
        defaultMessage:
          "It seems that there are network errors, please try again later.",
      });
}

function formatDecodeTokenError(intl: IntlShape, platform: Platform): string {
  return platform === "mobile"
    ? intl.formatMessage({
        id: "mobile.errors.app.decodeInvalidToken",
        defaultMessage: "Please enter a valid phone number token.",
      })
    : intl.formatMessage({
        id: "web.errors.app.decodeInvalidToken",
        defaultMessage: "Please enter a valid phone number token.",
      });
}

export function useErrorMessage(
  err: unknown,
  throwUnrecoverableError: boolean = true
): string {
  const intl = useIntl();
  const platform = useShell().platform;
  // eslint-disable-next-line complexity
  const message = useMemo(() => {
    if (err == null) {
      return "";
    } else if (err instanceof AppError) {
      return platform === "mobile"
        ? intl.formatMessage(err.mobileMessage.message)
        : intl.formatMessage(err.webMessage.message);
    } else if (err instanceof WorkflowError) {
      return formatWorkflowError(err, intl, platform);
    } else if (err instanceof NWDError) {
      return err.message;
    } else if (err instanceof AuthgearError) {
      if (AuthgearError.isWorkflowNotFound(err)) {
        if (throwUnrecoverableError) {
          throw err;
        }
        return formatWorkflowExpiredError(intl, platform);
      }
      return formatAuthgearError(err, intl, platform);
    } else if (
      err instanceof TypeError &&
      /network|fetch/gi.test(err.message)
    ) {
      return formatNetworkError(intl, platform);
    } else if (err instanceof DecodeTokenError) {
      return formatDecodeTokenError(intl, platform);
    }

    // Send the unknown error to Sentry.
    captureException(err);

    return platform === "mobile"
      ? intl.formatMessage({
          id: "mobile.errors.unknown",
          defaultMessage: "Something went wrong, please try again later.",
        })
      : intl.formatMessage({
          id: "web.errors.unknown",
          defaultMessage: "Something went wrong, please try again later.",
        });
  }, [err, intl, platform, throwUnrecoverableError]);

  useTrackError(platform, message, err);

  return message;
}

export function useErrorTitle(err: unknown): string {
  const intl = useIntl();
  const platform = useShell().platform;
  // eslint-disable-next-line complexity
  return useMemo(() => {
    if (err == null) {
      return "";
    } else if (err instanceof AppError && err.getTitle(platform) != null) {
      return intl.formatMessage(err.getTitle(platform)!);
    } else if (err instanceof WorkflowError) {
      switch (err.reason) {
        case "ValidationFailed":
          if (
            err.info?.causes?.some((x: any) => x?.details?.format === "phone")
          ) {
            return platform === "mobile"
              ? intl.formatMessage({
                  id: "mobile.screens.error.title.invalidPhone",
                  defaultMessage: "Invalid phone number error",
                })
              : intl.formatMessage({
                  id: "web.screens.error.title.invalidPhone",
                  defaultMessage: "Invalid phone number error",
                });
          }
      }
    } else if (AuthgearError.isWorkflowNotFound(err)) {
      return platform === "mobile"
        ? intl.formatMessage({
            id: "mobile.screens.error.title.workflow.expired",
            defaultMessage: "Session Expired",
          })
        : intl.formatMessage({
            id: "web.screens.error.title.workflow.expired",
            defaultMessage: "Session Expired",
          });
    }

    return platform === "mobile"
      ? intl.formatMessage({
          id: "mobile.screens.error.title.unknown",
          defaultMessage: "Error",
        })
      : intl.formatMessage({
          id: "web.screens.error.title.unknown",
          defaultMessage: "Error",
        });
  }, [err, intl, platform]);
}

export function useErrorButtonText(err: unknown): string {
  const intl = useIntl();
  const platform = useShell().platform;
  return useMemo(() => {
    if (AuthgearError.isWorkflowNotFound(err)) {
      const isAuthFlow = isAuthenticationFlow(platform, location.pathname);
      if (isAuthFlow) {
        return platform === "mobile"
          ? intl.formatMessage({
              id: "mobile.screens.error.confirm.workflow.expired.auth",
              defaultMessage: "Back to login",
            })
          : intl.formatMessage({
              id: "web.screens.error.confirm.workflow.expired.auth",
              defaultMessage: "Back to login page",
            });
      }

      return platform === "mobile"
        ? intl.formatMessage({
            id: "mobile.screens.error.confirm.workflow.expired",
            defaultMessage: "Back",
          })
        : intl.formatMessage({
            id: "web.screens.error.confirm.workflow.expired",
            defaultMessage: "Back",
          });
    }

    return platform === "mobile"
      ? intl.formatMessage({
          id: "mobile.screens.error.confirm",
          defaultMessage: "Back",
        })
      : intl.formatMessage({
          id: "web.screens.error.confirm",
          defaultMessage: "Back",
        });
  }, [err, intl, platform]);
}
