import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../../../components/Button";
import { Dialog } from "../../../../components/Dialog";
import { FormField } from "../../../../components/FormField";
import { MobileScreenLayout } from "../../../../components/MobileScreenLayout";
import { PasswordInput } from "../../../../components/PasswordInput";
import { LoginVerifyMFAPasswordScreenProps } from "./useLoginVerifyMFAPasswordScreen";
import { ResetPasswordButton } from "../../../../components/ResetPasswordButton";

export function LoginVerifyMFAPasswordScreen(
  props: LoginVerifyMFAPasswordScreenProps
): React.ReactElement {
  const intl = useIntl();

  const {
    password,
    onChangePassword,
    warningThreshold,
    failedAttempts,
    errorMessage,
    errorDialogState,
    errorDialogContent,
    handleVerifyMFAPassword,
    isLoading,
    handleForgotPasswordRedirect,
    phoneNumber,
  } = props;

  return (
    <form
      className="h-full"
      noValidate={true}
      onSubmit={handleVerifyMFAPassword}
    >
      <MobileScreenLayout>
        <MobileScreenLayout.Body>
          <h1 className="mb-1 font-heading text-heading-2">
            <FormattedMessage
              id="mobile.screens.login.verifyMFAPassword.title"
              defaultMessage="Login with your Password"
            />
          </h1>
          <p className="mb-16 text-body-1">
            <FormattedMessage
              id="mobile.screens.login.verifyMFAPassword.description"
              defaultMessage="Please enter your password to continue"
            />
          </p>

          <input
            className="hidden"
            aria-hidden="true"
            readOnly={true}
            type="text"
            autoComplete="username"
            name=""
            value={phoneNumber}
          />

          <FormField className="pt-2">
            <FormField.InputContainer>
              <PasswordInput
                value={password}
                onChange={onChangePassword}
                required={true}
                readOnly={isLoading}
                autoFocus={true}
                autoComplete="current-password"
                placeholder={intl.formatMessage({
                  id: "mobile.screens.login.verifyMFAPassword.password.placeholder",
                  defaultMessage: "Password",
                })}
              />
            </FormField.InputContainer>

            <div className="h-8 mb-4">
              {errorMessage ? (
                <FormField.ErrorMessage>{errorMessage}</FormField.ErrorMessage>
              ) : null}
            </div>
          </FormField>

          {failedAttempts < warningThreshold ? (
            <Button
              variant="text"
              className="underline"
              onPress={handleForgotPasswordRedirect}
            >
              <FormattedMessage
                id="mobile.screens.login.verifyMFAPassword.forgotPassword"
                defaultMessage="Forgot Password?"
              />
            </Button>
          ) : (
            <ResetPasswordButton
              onPress={handleForgotPasswordRedirect}
              disabled={isLoading}
            >
              <FormattedMessage
                id="mobile.screens.login.verifyMFAPassword.resetPasswordNow"
                defaultMessage="Reset your password now"
              />
            </ResetPasswordButton>
          )}
        </MobileScreenLayout.Body>
        <MobileScreenLayout.FixedFooter>
          <Button
            className="w-full"
            type="submit"
            preventFocusOnPress={true}
            disabled={password.length === 0}
            loading={isLoading}
          >
            <FormattedMessage
              id="mobile.screens.login.verifyMFAPassword.action"
              defaultMessage="Login"
            />
          </Button>
        </MobileScreenLayout.FixedFooter>
      </MobileScreenLayout>
      <Dialog state={errorDialogState} isDismissable={false}>
        {errorDialogContent}
      </Dialog>
    </form>
  );
}
