import parsePhoneNumber from "libphonenumber-js/mobile";

export function maskPhoneNumber(phone: string): string {
  const phoneNumber = parsePhoneNumber(phone);
  if (phoneNumber == null) {
    return phone;
  }

  const national = [...phoneNumber.formatNational()];
  let remainingUnmasked = 3;
  for (let i = national.length - 1; i >= 0; i--) {
    if (national[i] !== " ") {
      if (remainingUnmasked > 0) {
        remainingUnmasked--;
      } else {
        national[i] = "*";
      }
    }
  }
  return [`+${phoneNumber.countryCallingCode}`, national.join("")].join(" ");
}
