import React, { useLayoutEffect } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Header } from "../components/web/Header";
import { useLoadTurnstile } from "../hooks/turnstile";
import { useLoadGTM, useTrackScreenView } from "../tracking/hooks";
import { ShellContextProvider } from "./context";
import { useIntl } from "react-intl";

function TrackPageView() {
  // We cannot call useTrackScreenView in WebShell because
  // We need ShellContextProvider.
  useTrackScreenView();
  return null;
}

export const WebShell: React.FC = () => {
  useLayoutEffect(() => {
    const element = document.documentElement;
    element.dataset.platform = "web";
    return () => {
      element.dataset.platform = "";
    };
  }, []);

  useLoadGTM();
  useLoadTurnstile();

  const intl = useIntl();

  return (
    <ShellContextProvider platform="web">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "web.title",
            defaultMessage: "One ID | New World Development",
          })}
        </title>
      </Helmet>
      <TrackPageView />
      <div className="bg-c-web-background h-full flex flex-col">
        <div className="mx-auto sm:py-8 py-5">
          <Header />
        </div>
        <Outlet />
      </div>
    </ShellContextProvider>
  );
};
