import React from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as IconSuccess } from "../../../../assets/success.svg";
import { WebScreenLayout } from "../../../../components/WebScreenLayout";
import { useDeclareScreenName } from "../../../../tracking/hooks";

export function WebLoginLinkSuccessScreen(): React.ReactElement {
  useDeclareScreenName("Auth-Mfa-Email-Verify-Success");

  return (
    <WebScreenLayout>
      <WebScreenLayout.Body>
        <IconSuccess className="self-center mb-3 text-c-button-primary w-25 h-25" />
        <h1 className="font-heading text-heading-2 mb-2">
          <FormattedMessage
            id="web.screens.loginLinkSuccess.title"
            defaultMessage="Verification is completed"
          />
        </h1>
        <p className="text-body-2 text-c-text-disabled">
          <FormattedMessage
            id="web.screens.loginLinkSuccess.description"
            defaultMessage="Please close this tab and go back to the website."
          />
        </p>
      </WebScreenLayout.Body>
    </WebScreenLayout>
  );
}
