import { useEffect, useState } from "react";
import { Turnstile } from "../@types/turnstile";
import { useRefValue } from "./ref-value";

export function useLoadTurnstile(): void {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
}

// Borrow from https://usehooks-ts.com/react-hook/use-interval
function useInterval(callback: () => void, delay: number | null) {
  const callbackRef = useRefValue(callback);

  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return () => {};
    }

    const id = setInterval(() => callbackRef.current(), delay);

    return () => {
      clearInterval(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay]);
}

export function useTurnstile(): Turnstile | undefined {
  const [delay, setDelay] = useState<number | null>(
    window.turnstile == null ? 500 : null
  );
  useInterval(() => {
    if (window.turnstile != null) {
      setDelay(null);
    }
  }, delay);
  return window.turnstile;
}
