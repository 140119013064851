import {
  useSendReauthForgotPasswordCode,
  SendReauthForgotPasswordCodeState,
} from "../../../hooks/reauth/useSendReauthForgotPasswordCode";
import { Brand } from "../../../shell/brand";
import { useWorkflow } from "../../../shell/workflow";
import { WorkflowReauthForgotPassword } from "../../../states/workflows";
import { useDeclareScreenName } from "../../../tracking/hooks";
import { AppScreen, getScreenName } from "../../../tracking/screen";

export interface ReauthForgotPasswordByEmailProps
  extends SendReauthForgotPasswordCodeState {}

export function useReauthForgotPasswordByEmailScreen(
  bu: Brand | null
): ReauthForgotPasswordByEmailProps {
  useDeclareScreenName(getScreenName(bu, AppScreen.ForgotPasswordByEmail));

  const workflow = useWorkflow<WorkflowReauthForgotPassword>(
    "latte.NodeForgotPasswordWithLoginID"
  );

  const state = useSendReauthForgotPasswordCode(workflow, "email");

  return state;
}
