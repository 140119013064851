import React from "react";
import { Outlet } from "react-router-dom";
import { MobileWorkflowProvider } from "../../shell/MobileWorkflowProvider";
import { initWorkflowVerifyUser } from "../../states/interaction/intents";

export function VerifyEmailInitScreen(): React.ReactElement {
  return (
    <MobileWorkflowProvider initializer={initWorkflowVerifyUser}>
      <Outlet />
    </MobileWorkflowProvider>
  );
}
