export const authgearAPI: string = import.meta.env.DEV
  ? import.meta.env.VITE_AUTHGEAR_ENDPOINT
  : window.__config.authgearEndpoint;

export const middlewareEndpoint: string = import.meta.env.DEV
  ? import.meta.env.VITE_MIDDLEWARE_ENDPOINT
  : window.__config.middlewareEndpoint;

export const mobileRoutePrefix: string = import.meta.env.DEV
  ? import.meta.env.VITE_MOBILE_ROUTE_PREFIX
  : window.__config.mobileRoutePrefix;

export const webRoutePrefix: string = import.meta.env.DEV
  ? import.meta.env.VITE_WEB_ROUTE_PREFIX
  : window.__config.webRoutePrefix;

export const nwdCheckEmailEndpoint: string = import.meta.env.DEV
  ? import.meta.env.VITE_CHECK_EMAIL_ENDPOINT
  : window.__config.checkEmailEndpoint;

export const sentryDSN: string = import.meta.env.DEV
  ? import.meta.env.VITE_SENTRY_DSN
  : window.__config.sentryDSN;

export const intlGCSBucketName: string = import.meta.env.DEV
  ? import.meta.env.VITE_INTL_GCS_BUCKET_NAME
  : window.__config.intlGCSBucketName;

export const turnstileSiteKey: string = import.meta.env.DEV
  ? import.meta.env.VITE_TURNSTILE_SITE_KEY
  : window.__config.turnstileSiteKey;

export const k11AppLink: string = import.meta.env.DEV
  ? import.meta.env.VITE_K11_APP_LINK
  : window.__config.k11AppLink;

export const alAppLink: string = import.meta.env.DEV
  ? import.meta.env.VITE_AL_APP_LINK
  : window.__config.alAppLink;

export const dpAppLink: string = import.meta.env.DEV
  ? import.meta.env.VITE_DP_APP_LINK
  : window.__config.dpAppLink;

export const incorrectPasswordWarningThreshold = 5;

declare global {
  interface Window {
    __config: {
      middlewareEndpoint: string;
      authgearEndpoint: string;
      mobileRoutePrefix: string;
      webRoutePrefix: string;
      checkEmailEndpoint: string;
      sentryDSN: string;
      intlGCSBucketName: string;
      turnstileSiteKey: string;
      k11AppLink: string;
      alAppLink: string;
      dpAppLink: string;
    };
  }
}
