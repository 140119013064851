import { decodeTokenAPI } from "../../apis/token";
import { ReauthForgotPasswordNavigationState } from "../../hooks/reauth/useSendReauthForgotPasswordCode";
import {
  CLIENT_ID,
  REDIRECT_URI,
  UI_LOCALES,
  X_STATE,
  X_REF,
} from "../../search";
import { parseUIStateFromSearchParams } from "../../shell/uistate";
import { stringifyUIState, UIState } from "../../shell/uistate_parse";
import {
  ForgotPasswordChannel,
  makeWorkflowAuthMapper,
  makeWorkflowChangeEmailMapper,
  makeWorkflowChangePasswordMapper,
  makeWorkflowForgotPasswordV2Mapper,
  makeWorkflowMigrateMapper,
  makeWorkflowReauthForgotPasswordMapper,
  makeWorkflowReauthMapper,
  makeWorkflowResetPasswordMapper,
  makeWorkflowVerifyLoginLinkMapper,
  makeWorkflowVerifyUserMapper,
  Workflow,
  WorkflowAuth,
  WorkflowChangeEmail,
  WorkflowChangePassword,
  WorkflowForgotPasswordV2,
  WorkflowMigrate,
  WorkflowReauth,
  WorkflowReauthForgotPassword,
  WorkflowResetPassword,
  WorkflowVerifyLoginLink,
  WorkflowVerifyUser,
} from "../workflows";
import {
  NewWorkflowOptions,
  workflowNewWithBatchInput,
  workflowGet,
  WorkflowMapper,
  WorkflowResult,
} from "./api";
import { AuthgearError } from "./errors";

const AUTH_USER_INITIATES = ["login", "signup", "kdp_binding"] as const;

const ALL_USER_INITIATES = [
  ...AUTH_USER_INITIATES,
  "migrate",
  "reauth",
] as const;

export type AuthUserInitiate = typeof AUTH_USER_INITIATES[number];
export type UserInitiate = typeof ALL_USER_INITIATES[number];

export function isUserInitiate(x: string): x is UserInitiate {
  return (ALL_USER_INITIATES as readonly string[]).includes(x);
}

export function isAuthUserInitiate(x: string): x is AuthUserInitiate {
  return (AUTH_USER_INITIATES as readonly string[]).includes(x);
}

// History of parameter parsing, we should ensure all of the below works

// Auth
// Version 1: ?state=phone%3Dxxx
// Version 2: ?x_state=phone%3Dxxx
// Version 3.1: ?x_state=token%3Dxxx
//   -> { "token": "xxx" }
//   -> { "phone": "yyy" }
// Version 3.2: ?x_state=x_secrets_token%3Dxxx
//   -> { "x_secrets_token": "xxx" }
//   -> { "phone": "yyy" }

// Migration
// Version 1: ?state=token%3Dxxx%26email%3Dyyy
// Version 2: ?x_state=token%3Dxxx%26email%3Dyyy
// Version 3: ?x_state=token%3Dxxx%26x_secrets_token%3Dyyy
//   -> { "x_secrets_token": "yyy" }
//   -> { "email": "zzz" }

// Other flows
// Version 1: ?phone=xxx
// Version 2: ?x_state=x_secrets_token%3Dxxx
//   -> { "x_secrets_token": "xxx" }
//   -> { "phone": "yyy" }

async function decodeXSecretsToken(
  xSecretsToken: string
): Promise<Record<string, string>> {
  const decoded = await decodeTokenAPI(xSecretsToken);
  const parsed = JSON.parse(decoded);
  return parsed;
}

// eslint-disable-next-line complexity
async function doInitWorkflowAuth(
  intent: "latte.IntentAuthenticate" | "latte.IntentProtectedAuthenticate",
  query: URLSearchParams
): Promise<WorkflowAuth> {
  const workflowID = query.get("_w");
  const instanceID = query.get("_i");
  // (Version 1, 2)
  const uiState = parseUIStateFromSearchParams(query);
  const userInitiate = uiState.user_initiate ?? "login";
  let phoneNumber: string | undefined = uiState.phone;
  // (Version 3.1) For historical reason, token can be the phone number
  const token = uiState.token;

  // (Version 3.2) xSecretsToken can be a json string including the phone number
  const xSecretsToken = uiState.x_secrets_token;

  if (!isAuthUserInitiate(userInitiate)) {
    throw new Error("Invalid initiation intent");
  }

  if (xSecretsToken) {
    const xTokenObj = await decodeXSecretsToken(xSecretsToken);
    phoneNumber = xTokenObj["phone"];
  } else if (token) {
    phoneNumber = await decodeTokenAPI(token);
  }

  if (!phoneNumber) {
    throw new Error("Missing phone number");
  }

  const mapper = makeWorkflowAuthMapper(userInitiate, phoneNumber);

  if (workflowID != null && instanceID != null) {
    try {
      return await workflowGet(mapper, workflowID, instanceID);
    } catch (e: unknown) {
      if (AuthgearError.isWorkflowNotFound(e)) {
        // Failed to load existing interaction session;
        // fallthrough and start new interaction session.
      } else {
        throw e;
      }
    }
  }

  const workflow = await workflowNewWithBatchInputWithCommonSearchParams(
    query,
    mapper,
    {
      kind: intent,
      data: {},
    },
    [
      {
        kind: "latte.InputTakeLoginID",
        data: {
          login_id: phoneNumber,
        },
      },
    ]
  );

  return workflow;
}

export async function initWorkflowAuth(
  query: URLSearchParams
): Promise<WorkflowAuth> {
  return doInitWorkflowAuth("latte.IntentAuthenticate", query);
}
initWorkflowAuth.intent = "latte.IntentAuthenticate";

export async function initWorkflowProtectedAuth(
  query: URLSearchParams
): Promise<WorkflowAuth> {
  return doInitWorkflowAuth("latte.IntentProtectedAuthenticate", query);
}
initWorkflowProtectedAuth.intent = "latte.IntentProtectedAuthenticate";

export async function initWorkflowVerifyLoginLink(
  query: URLSearchParams
): Promise<WorkflowVerifyLoginLink> {
  const code = query.get("code");
  if (!code) {
    throw new Error("Missing code");
  }

  const mapper = makeWorkflowVerifyLoginLinkMapper(code);
  const workflow = await workflowNewWithBatchInputWithCommonSearchParams(
    query,
    mapper,
    {
      kind: "latte.IntentVerifyLoginLink",
      data: {},
    },
    []
  );
  return workflow;
}
initWorkflowVerifyLoginLink.intent = "latte.IntentVerifyLoginLink";

export async function initWorkflowMigrate(
  query: URLSearchParams
): Promise<WorkflowMigrate> {
  const workflowID = query.get("_w");
  const instanceID = query.get("_i");
  // (Version 1, 2)
  const uiState = parseUIStateFromSearchParams(query);
  let email = uiState.email ?? "";
  const token = uiState.token;
  // (Version 3)
  const xSecretsToken = uiState.x_secrets_token;

  if (!token) {
    throw new Error("Missing migration token");
  }

  if (xSecretsToken) {
    const xTokenObj = await decodeXSecretsToken(xSecretsToken);
    email = xTokenObj["email"];
  }

  const mapper = makeWorkflowMigrateMapper(email);

  if (workflowID != null && instanceID != null) {
    try {
      return await workflowGet(mapper, workflowID, instanceID);
    } catch (e: unknown) {
      if (AuthgearError.isWorkflowNotFound(e)) {
        // Failed to load existing interaction session;
        // fallthrough and start new interaction session.
      } else {
        throw e;
      }
    }
  }

  const workflow = await workflowNewWithBatchInputWithCommonSearchParams(
    query,
    mapper,
    {
      kind: "latte.IntentMigrate",
      data: {},
    },
    [
      {
        kind: "latte.InputTakeMigrationToken",
        data: {
          migration_token: token,
        },
      },
    ]
  );
  return workflow;
}
initWorkflowMigrate.intent = "latte.IntentMigrate";

export async function initWorkflowReauth(
  query: URLSearchParams
): Promise<WorkflowReauth> {
  const uiState = parseUIStateFromSearchParams(query);

  const xSecretsToken = uiState.x_secrets_token;

  if (!xSecretsToken) {
    throw new Error("Missing x_secrets_token");
  }

  const xTokenObj = await decodeXSecretsToken(xSecretsToken);
  const email = xTokenObj["email"];
  const phone = xTokenObj["phone"];

  const mapper = makeWorkflowReauthMapper({ email, phoneNumber: phone });

  const workflow = await workflowNewWithBatchInputWithCommonSearchParams(
    query,
    mapper,
    {
      kind: "latte.IntentReauthenticate",
      data: {},
    },
    []
  );
  return workflow;
}
initWorkflowReauth.intent = "latte.IntentReauthenticate";

export async function initWorkflowVerifyUser(
  query: URLSearchParams
): Promise<WorkflowVerifyUser> {
  // (Version 1)
  let email = query.get("email");
  // (Version 2)
  const uiState = parseUIStateFromSearchParams(query);
  const xSecretsToken = uiState.x_secrets_token;
  const finishURI = query.get(REDIRECT_URI);

  if (xSecretsToken) {
    const xTokenObj = await decodeXSecretsToken(xSecretsToken);
    email = xTokenObj["email"];
  }

  if (!email) {
    throw new Error("Missing email");
  }

  const mapper = makeWorkflowVerifyUserMapper({
    email,
    finishURI: finishURI ?? undefined,
  });

  return newWorkflowVerifyUser(query, mapper, email);
}
initWorkflowVerifyUser.intent = "latte.IntentVerifyUser";

export async function initWorkflowForgotPasswordV2(
  query: URLSearchParams,
  options: {
    channel: ForgotPasswordChannel;
    phoneNumber: string;
    maskedEmail?: string;
  }
): Promise<WorkflowForgotPasswordV2> {
  const finishURI = query.get(REDIRECT_URI);
  const mapper = makeWorkflowForgotPasswordV2Mapper({
    channel: options.channel,
    phoneNumber: options.phoneNumber,
    maskedEmail: options.maskedEmail,
    finishURI: finishURI ?? undefined,
  });

  const search = new URLSearchParams();
  const currentSearch = new URLSearchParams(location.search);
  const uiState = parseUIStateFromSearchParams(currentSearch);
  // Exclude any unnecessary params
  const newUIState: UIState = {
    platform: uiState.platform,
    bu: uiState.bu,
    // Keep these for tracking
    channel: uiState.channel,
    user_initiate: uiState.user_initiate,
    x_custom_attributes_b64url: uiState.x_custom_attributes_b64url,
  };
  search.set(X_STATE, stringifyUIState(newUIState));

  return newWorkflowForgotPasswordV2(query, mapper, search);
}
initWorkflowForgotPasswordV2.intent = "latte.IntentForgotPasswordV2";

async function initWorkflowReauthForgotPassword(
  query: URLSearchParams,
  state: ReauthForgotPasswordNavigationState,
  channel: ForgotPasswordChannel
): Promise<WorkflowReauthForgotPassword> {
  const finishURI = query.get(REDIRECT_URI);
  const mapper = makeWorkflowReauthForgotPasswordMapper({
    channel: channel,
    finishURI: finishURI ?? undefined,
    email: state.email,
    phoneNumber: state.phoneNumber,
    backWorkflow: state.backWorkflow,
  });

  const search = new URLSearchParams();
  const currentSearch = new URLSearchParams(location.search);
  const uiState = parseUIStateFromSearchParams(currentSearch);
  // Exclude any unnecessary params
  const newUIState: UIState = {
    platform: uiState.platform,
    bu: uiState.bu,
    // Keep these for tracking
    channel: uiState.channel,
    user_initiate: uiState.user_initiate,
    x_custom_attributes_b64url: uiState.x_custom_attributes_b64url,
  };
  search.set(X_STATE, stringifyUIState(newUIState));

  return newWorkflowReauthForgotPassword(query, mapper, search, channel);
}
initWorkflowReauthForgotPassword.intent = "latte.IntentReauthForgotPassword";

export async function initWorkflowReauthForgotPasswordByEmail(
  query: URLSearchParams,
  state: ReauthForgotPasswordNavigationState
): Promise<WorkflowReauthForgotPassword> {
  return initWorkflowReauthForgotPassword(query, state, "email");
}
initWorkflowReauthForgotPasswordByEmail.intent =
  initWorkflowReauthForgotPassword.intent;

export async function initWorkflowReauthForgotPasswordBySMS(
  query: URLSearchParams,
  state: ReauthForgotPasswordNavigationState
): Promise<WorkflowReauthForgotPassword> {
  return initWorkflowReauthForgotPassword(query, state, "sms");
}
initWorkflowReauthForgotPasswordBySMS.intent =
  initWorkflowReauthForgotPassword.intent;

async function doInitResetPasswordWorkflow(
  initiationIntent: WorkflowResetPassword["initiationIntent"],
  query: URLSearchParams
): Promise<WorkflowResetPassword> {
  const finishURI = query.get(REDIRECT_URI);
  const code = query.get("code");
  if (!code) {
    throw new Error("Missing code");
  }
  const mapper = makeWorkflowResetPasswordMapper(
    initiationIntent,
    finishURI ?? undefined
  );
  const workflow = await workflowNewWithBatchInputWithCommonSearchParams(
    query,
    mapper,
    {
      kind: "latte.IntentResetPassword",
      data: {},
    },
    [
      {
        kind: "latte.InputTakeCode",
        data: {
          code,
        },
      },
    ],
    {
      // This workflow cannot bind to user agent because
      // it might be called from middleware
      bindUserAgent: false,
    }
  );
  return workflow;
}

export async function initWorkflowResetPassword(
  query: URLSearchParams
): Promise<WorkflowResetPassword> {
  return doInitResetPasswordWorkflow("reset", query);
}
initWorkflowResetPassword.intent = "latte.IntentResetPassword";

export async function initWorkflowSetupPassword(
  query: URLSearchParams
): Promise<WorkflowResetPassword> {
  return doInitResetPasswordWorkflow("setup", query);
}
initWorkflowSetupPassword.intent = "latte.IntentResetPassword";

export async function initWorkflowChangePassword(
  query: URLSearchParams
): Promise<WorkflowChangePassword> {
  const finishURI = query.get(REDIRECT_URI);
  const mapper = makeWorkflowChangePasswordMapper(finishURI ?? undefined);

  return newWorkflowChangePassword(query, mapper);
}
initWorkflowChangePassword.intent = "latte.IntentChangePassword";

export async function initWorkflowChangeEmail(
  query: URLSearchParams
): Promise<WorkflowChangeEmail> {
  // (Version 1)
  let phoneNumber = query.get("phone") ?? "";
  let email = query.get("email");

  // (Version 2)
  const uiState = parseUIStateFromSearchParams(query);
  const xSecretsToken = uiState.x_secrets_token;

  if (xSecretsToken) {
    const xTokenObj = await decodeXSecretsToken(xSecretsToken);
    phoneNumber = xTokenObj["phone"];
    email = xTokenObj["email"];
  }

  const finishURI = query.get(REDIRECT_URI) ?? undefined;

  if (!email) {
    throw new Error("Missing email");
  }

  const mapper = makeWorkflowChangeEmailMapper(phoneNumber, finishURI);
  return newWorkflowChangeEmail(query, mapper, email);
}
initWorkflowChangeEmail.intent = "latte.IntentChangeEmail";

async function newWorkflowVerifyUser(
  locationQuery: URLSearchParams,
  mapper: (result: WorkflowResult) => WorkflowVerifyUser,
  email: string
): Promise<WorkflowVerifyUser> {
  const workflow = await workflowNewWithBatchInputWithCommonSearchParams(
    locationQuery,
    mapper,
    {
      kind: "latte.IntentVerifyUser",
      data: {},
    },
    [
      {
        kind: "latte.InputSelectClaim",
        data: {
          claim_name: "email",
          claim_value: email,
        },
      },
    ]
  );
  return workflow;
}

async function newWorkflowForgotPasswordV2(
  locationQuery: URLSearchParams,
  mapper: (result: WorkflowResult) => WorkflowForgotPasswordV2,
  search: URLSearchParams
): Promise<WorkflowForgotPasswordV2> {
  const workflow = await workflowNewWithBatchInputWithCommonSearchParams(
    locationQuery,
    mapper,
    {
      kind: "latte.IntentForgotPasswordV2",
      data: {},
    },
    [],
    { search }
  );
  return workflow;
}

async function newWorkflowReauthForgotPassword(
  locationQuery: URLSearchParams,
  mapper: (result: WorkflowResult) => WorkflowReauthForgotPassword,
  search: URLSearchParams,
  channel: ForgotPasswordChannel
): Promise<WorkflowReauthForgotPassword> {
  const workflow = await workflowNewWithBatchInputWithCommonSearchParams(
    locationQuery,
    mapper,
    {
      kind: "latte.IntentReauthForgotPassword",
      data: {},
    },
    [
      {
        kind: "latte.InputTakeForgotPasswordChannel",
        data: { channel },
      },
    ],
    { search }
  );
  return workflow;
}

async function newWorkflowChangePassword(
  locationQuery: URLSearchParams,
  mapper: (result: WorkflowResult) => WorkflowChangePassword
): Promise<WorkflowChangePassword> {
  const workflow = await workflowNewWithBatchInputWithCommonSearchParams(
    locationQuery,
    mapper,
    {
      kind: "latte.IntentChangePassword",
      data: {},
    },
    []
  );
  return workflow;
}

async function newWorkflowChangeEmail(
  locationQuery: URLSearchParams,
  mapper: (result: WorkflowResult) => WorkflowChangeEmail,
  email: string
): Promise<WorkflowChangeEmail> {
  const workflow = await workflowNewWithBatchInputWithCommonSearchParams(
    locationQuery,
    mapper,
    {
      kind: "latte.IntentChangeEmail",
      data: {},
    },
    [
      {
        kind: "latte.InputTakeCurrentLoginID",
        data: {
          login_id: email,
        },
      },
    ]
  );
  return workflow;
}

function propagateSearchParams(
  locationQuery: URLSearchParams,
  original: URLSearchParams,
  keys: string[]
): URLSearchParams {
  const search = new URLSearchParams(original);
  for (const key of keys) {
    const value = locationQuery.get(key);
    if (value) {
      search.set(key, value);
    }
  }
  return search;
}

async function workflowNewWithBatchInputWithCommonSearchParams<
  T extends Workflow
>(
  locationQuery: URLSearchParams,
  mapper: WorkflowMapper<T>,
  intent: {
    kind: string;
    data: unknown;
  },
  batchInput: {
    kind: string;
    data: unknown;
  }[],
  options: NewWorkflowOptions = {}
) {
  const search = propagateSearchParams(
    locationQuery,
    options.search ?? new URLSearchParams(),
    [CLIENT_ID, UI_LOCALES, X_REF]
  );
  return workflowNewWithBatchInput(mapper, intent, batchInput, {
    ...options,
    search,
  });
}
