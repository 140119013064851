import React, { useContext, useLayoutEffect, useMemo } from "react";
import { Brand } from "../shell/brand";
import { useLocaleBrand, useLocalePlatform } from "../intl/IntlProvider";
import { Platform } from "./platform";
import { useUIState, useUIStatePlatform } from "./uistate";
import { useLatteGTM } from "../tracking/hooks";
import { mapBrand } from "./brand";

function useBrand(platform: Platform): Brand | null {
  const uiState = useUIState();

  const brand = mapBrand(uiState.bu ?? null, platform);

  useLocaleBrand(brand);

  useLayoutEffect(() => {
    const element = document.documentElement;
    element.dataset.brand = brand;
  }, [brand]);

  return brand;
}

interface ShellContextValue {
  brand: Brand | null;
  platform: Platform;
}

const ShellContext = React.createContext<ShellContextValue | undefined>(
  undefined
);

interface ShellContextProviderProps extends React.PropsWithChildren {
  platform: Platform;
}

export const ShellContextProvider: React.FC<ShellContextProviderProps> = ({
  children,
  platform,
}) => {
  useLocalePlatform(platform);
  useUIStatePlatform(platform);
  const brand = useBrand(platform);
  const value = useMemo<ShellContextValue>(() => {
    return {
      brand,
      platform,
    };
  }, [brand, platform]);

  useLatteGTM(brand);

  return (
    <ShellContext.Provider value={value}>{children}</ShellContext.Provider>
  );
};

export function useShell(): ShellContextValue {
  const maybeCtx = useContext(ShellContext);
  if (maybeCtx === undefined) {
    throw new Error("No ShellContextProvider found");
  }
  return maybeCtx;
}
