const REGEXP = /^(\d+)(?:\.(\d+)(?:\.(\d+))?)?/;

export class SemVer {
  public major: number;
  public minor?: number;
  public patch?: number;

  constructor(major: number, minor?: number, patch?: number) {
    this.major = major;
    this.minor = minor;
    this.patch = patch;
  }

  static parse(raw: string): SemVer | null {
    const matches = REGEXP.exec(raw);
    if (matches == null) {
      return null;
    }
    const major = Number(matches[1]);
    let minor: number | undefined = Number(matches[2]);
    let patch: number | undefined = Number(matches[3]);
    if (Number.isNaN(major)) {
      return null;
    }
    if (Number.isNaN(minor)) {
      minor = undefined;
    }
    if (Number.isNaN(patch)) {
      patch = undefined;
    }
    return new SemVer(major, minor, patch);
  }

  // eslint-disable-next-line complexity
  compare(to: SemVer): number {
    if (this.major > to.major) {
      return 1;
    }
    if (this.major < to.major) {
      return -1;
    }
    if ((this.minor ?? 0) > (to.minor ?? 0)) {
      return 1;
    }
    if ((this.minor ?? 0) < (to.minor ?? 0)) {
      return -1;
    }
    if ((this.patch ?? 0) > (to.patch ?? 0)) {
      return 1;
    }
    if ((this.patch ?? 0) < (to.patch ?? 0)) {
      return -1;
    }
    return 0;
  }
}
