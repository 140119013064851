import React from "react";
import { ReactComponent as CheckCircle } from "../assets/check-circle.svg";
import { ReactComponent as KDPCheckCircle } from "../assets/kdp-check-circle.svg";
import { ReactComponent as KDPCheckCircleDisabled } from "../assets/kdp-check-circle-disabled.svg";
import { useShell } from "../shell/context";

interface PasswordRequirementIconProps {
  isPassed: boolean;
}

const PasswordRequirementIconBase: React.FC<PasswordRequirementIconProps> = (
  props
) => {
  return props.isPassed ? (
    <CheckCircle className="w-full h-full text-success" />
  ) : (
    <span className="m-px bg-black/10 rounded-full" />
  );
};
const PasswordRequirementIconKDP: React.FC<PasswordRequirementIconProps> = (
  props
) => {
  return props.isPassed ? (
    <KDPCheckCircle className="w-4 h-4" />
  ) : (
    <KDPCheckCircleDisabled className="w-4 h-4" />
  );
};

export const PasswordRequirementIcon: React.FC<PasswordRequirementIconProps> = (
  props
) => {
  switch (useShell().brand) {
    case "KDP":
      return <PasswordRequirementIconKDP {...props} />;
    default:
      return <PasswordRequirementIconBase {...props} />;
  }
};
