import {
  ReauthPasswordState,
  useReauthPassword,
} from "../../../hooks/reauth/useReauthPassword";
import { Brand } from "../../../shell/brand";
import { useWorkflow } from "../../../shell/workflow";
import { useInteractionIsLoading } from "../../../states/states";
import { WorkflowReauth } from "../../../states/workflows";
import { useDeclareScreenName } from "../../../tracking/hooks";
import { AppScreen, getScreenName } from "../../../tracking/screen";

export interface ReauthPasswordScreenProps extends ReauthPasswordState {}

export function useReauthPasswordScreen(
  bu: Brand | null
): ReauthPasswordScreenProps {
  useDeclareScreenName(getScreenName(bu, AppScreen.ReauthPassword));

  const workflow = useWorkflow<WorkflowReauth>(
    "latte.NodeAuthenticatePassword"
  );

  const state = useReauthPassword(workflow);
  const isInteractionLoading = useInteractionIsLoading();
  const isLoading = state.isLoading || isInteractionLoading;

  return {
    ...state,
    isLoading,
  };
}
