import React from "react";
import { useShell } from "../../../../shell/context";
import { useLoginVerifyMFAPasswordScreen } from "./useLoginVerifyMFAPasswordScreen";
import { LoginVerifyMFAPasswordScreen as Screen } from "./LoginVerifyMFAPasswordScreen";
import { LoginVerifyMFAPasswordScreen as KDPScreen } from "./LoginVerifyMFAPasswordScreen.kdp";

export function LoginVerifyMFAPasswordScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useLoginVerifyMFAPasswordScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPScreen {...props} />;
    default:
      return <Screen {...props} />;
  }
}
