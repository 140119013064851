import { useState, useCallback, useMemo } from "react";
import { useShell } from "../../shell/context";
import { useAppNavigate } from "../../shell/hooks";
import { routes } from "../../shell/routes";
import { forgotPasswordV2 } from "../../states/interaction/steps";
import { useErrorMessage } from "../errors";
import { useInteractionStep } from "../interaction";
import { useLocation } from "react-router-dom";
import {
  ForgotPasswordChannel,
  WorkflowForgotPasswordV2,
} from "../../states/workflows";
import { maskEmail } from "../../utils/email";
import { maskPhoneNumber } from "../../utils/phone";
import { Platform } from "../../shell/platform";

export interface ForgotPasswordNavigationState {
  maskedEmail: string;
  phoneNumber: string;
  backWorkflow: WorkflowForgotPasswordV2 | null;
}

export interface SendForgotPasswordCodeV2State {
  maskedEmail: string;
  maskedPhoneNumber: string;
  phoneNumber: string;
  errorMessage: string;
  handleSendForgotPasswordCode: () => void;
  handleBack?: () => void;
  isLoading: boolean;
}

function getProceedPathname(
  platform: Platform,
  channel: ForgotPasswordChannel
) {
  return platform === "mobile"
    ? channel === "email"
      ? routes.mobile.recovery.forgotPasswordProceed.email
      : routes.mobile.recovery.forgotPasswordProceed.sms
    : channel === "email"
    ? routes.web.recovery.forgotPasswordProceed.email
    : routes.web.recovery.forgotPasswordProceed.sms;
}

export const useSendEmailForgotPasswordCodeV2 = (
  channel: ForgotPasswordChannel
): SendForgotPasswordCodeV2State => {
  const location = useLocation();
  const navigate = useAppNavigate();
  const platform = useShell().platform;

  const locationState = location.state as
    | Partial<ForgotPasswordNavigationState>
    | undefined;

  const { maskedEmail = "", phoneNumber = "" } = locationState ?? {};

  const [error, setError] = useState<unknown>(null);
  const errorMessage = useErrorMessage(error);

  const { trigger, isLoading } = useInteractionStep(null, forgotPasswordV2);

  const handleSendForgotPasswordCode = useCallback(() => {
    setError(null);

    Promise.resolve()
      .then(async () => trigger(channel, phoneNumber, maskedEmail))
      .then(
        (workflow) => {
          const originalState = location.state ?? {};
          navigate(
            {
              pathname: getProceedPathname(platform, channel),
              search: window.location.search,
            },
            { replace: true, state: { ...originalState, workflow } }
          );
        },
        (err) => {
          setError(err);
        }
      );
  }, [
    trigger,
    phoneNumber,
    maskedEmail,
    channel,
    location.state,
    navigate,
    platform,
  ]);

  const handleBack = useMemo(() => {
    if (locationState?.backWorkflow == null) {
      return undefined;
    }
    const backWorkflow = locationState.backWorkflow;
    return () => {
      const originalState = location.state ?? {};
      const pathname = getProceedPathname(platform, backWorkflow.channel);
      navigate(
        {
          pathname,
          search: window.location.search,
        },
        { replace: true, state: { ...originalState, workflow: backWorkflow } }
      );
    };
  }, [locationState?.backWorkflow, location.state, platform, navigate]);

  const furtherMaskedEmail = useMemo(
    () => maskEmail(maskedEmail),
    [maskedEmail]
  );
  const maskedPhoneNumber = useMemo(
    () => maskPhoneNumber(phoneNumber),
    [phoneNumber]
  );

  return {
    maskedEmail: furtherMaskedEmail,
    maskedPhoneNumber: maskedPhoneNumber,
    phoneNumber,
    errorMessage,
    handleSendForgotPasswordCode,
    handleBack,
    isLoading,
  };
};
