import { Brand } from "../shell/brand";

export enum AppScreen {
  LoginVerifyPhoneOTP = "LoginVerifyPhoneOTP",
  LoginSelectMFAMethod = "LoginSelectMFAMethod",
  LoginVerifyMFAEmail = "LoginVerifyMFAEmail",
  LoginVerifyMFAPassword = "LoginVerifyMFAPassword",
  SignupVerifyPhoneOTP = "SignupVerifyPhoneOTP",
  SignupSetupMFA = "SignupSetupMFA",
  ChangeEmail = "ChangeEmail",
  ChangePassword = "ChangePassword",
  ChangePasswordSuccess = "ChangePasswordSuccess",
  ForgotPasswordByEmailProceed = "ForgotPasswordByEmailProceed",
  ForgotPasswordByEmail = "ForgotPasswordByEmail",
  ForgotPasswordBySMSProceed = "ForgotPasswordBySMSProceed",
  ForgotPasswordBySMS = "ForgotPasswordBySMS",
  ResetPassword = "ResetPassword",
  ResetPasswordSuccess = "ResetPasswordSuccess",
  VerifyEmailOTP = "VerifyEmailOTP",
  ReauthPassword = "ReauthPassword",
}

const defaultScreenNames: { [key in AppScreen]: string } = {
  [AppScreen.LoginVerifyPhoneOTP]: "One-Login-Phone-OTP",
  [AppScreen.LoginSelectMFAMethod]: "One-Login-Email-Password",
  [AppScreen.LoginVerifyMFAEmail]: "One-Login-Email",
  [AppScreen.LoginVerifyMFAPassword]: "One-Login-Password",
  [AppScreen.SignupVerifyPhoneOTP]: "One-Reg-Phone-OTP",
  [AppScreen.SignupSetupMFA]: "One-Reg-Email-Password",
  [AppScreen.ChangeEmail]: "One-Login-Profile-Edit-Email",
  [AppScreen.ChangePassword]: "Member-Profile-PW-Change-One-Login",
  [AppScreen.ChangePasswordSuccess]: "Member-Profile-PW-Changed-One-Login",
  [AppScreen.ForgotPasswordByEmailProceed]: "One-ID-Pw-Forgot-Email-Sent",
  [AppScreen.ForgotPasswordByEmail]: "One-ID-Pw-Forgot-Email",
  [AppScreen.ForgotPasswordBySMSProceed]: "One-ID-Pw-Forgot-SMS-Sent",
  [AppScreen.ForgotPasswordBySMS]: "One-ID-Pw-Forgot-SMS",
  [AppScreen.ResetPassword]: "One-Login-Pw-Reset",
  [AppScreen.ResetPasswordSuccess]: "One-Login-Pw-Reset-Success",
  [AppScreen.VerifyEmailOTP]: "One-Login-Profile-Verify-Email",
  // FIXME(tung): Use name provided by client
  [AppScreen.ReauthPassword]: "One-Login-Reauth",
};

const kdpScreenNames: { [key in AppScreen]: string } = {
  [AppScreen.LoginVerifyPhoneOTP]: "Login-1ID-Phone-OTP",
  [AppScreen.LoginSelectMFAMethod]: "Login-1ID-Email-Password",
  [AppScreen.LoginVerifyMFAEmail]: "Login-1ID-Email-Sent",
  [AppScreen.LoginVerifyMFAPassword]: "Login-1ID-Password-Input",
  [AppScreen.SignupVerifyPhoneOTP]: "Reg-1ID-Phone-OTP",
  [AppScreen.SignupSetupMFA]: "Reg-1ID-Email-Password",
  [AppScreen.ChangeEmail]: "My-Login-KDP-Update-Email",
  [AppScreen.ChangePassword]: "My-Login-1ID-Update-Pw",
  [AppScreen.ChangePasswordSuccess]: "My-Login-1ID-Update-Pw-Success",
  [AppScreen.ForgotPasswordByEmailProceed]: "Login-Forgot-Password-Email-Sent",
  [AppScreen.ForgotPasswordByEmail]: "Login-Forgot-Password",
  [AppScreen.ForgotPasswordBySMSProceed]: "Login-Forgot-Password-SMS-Sent",
  [AppScreen.ForgotPasswordBySMS]: "Login-Forgot-Password-SMS",
  [AppScreen.ResetPassword]: "Login-Forgot-Password-Reset",
  [AppScreen.ResetPasswordSuccess]: "Login-Forgot-Password-Reset-Success",
  [AppScreen.VerifyEmailOTP]: "My-Login-KDP-Verify-Email",
  // NOTE(tung): This screen doesn't exist in kdp, adding this line for consistency
  [AppScreen.ReauthPassword]: "My-Login-KDP-Reauth",
};

export function getScreenName(bu: Brand | null, screen: AppScreen): string {
  switch (bu) {
    case "KDP":
      return kdpScreenNames[screen];
    default:
      return `${bu ?? "K11"}-${defaultScreenNames[screen]}`;
  }
}
