import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { ReactComponent as IconEnvelope } from "../../../../assets/email.svg";
import { AppNavigate } from "../../../../components/AppNavigate";
import { Button } from "../../../../components/web/Button";
import { WebScreenLayout } from "../../../../components/WebScreenLayout";
import { routes } from "../../../../shell/routes";
import { ForgotPasswordByEmailProceedScreenProps } from "./useForgotPasswordByEmailProceedScreen";

export function WebForgotPasswordByEmailProceedScreen(
  props: ForgotPasswordByEmailProceedScreenProps
): React.ReactElement {
  const {
    currentWorkflow,
    maskedEmail,
    canBack,
    handleBackActionOnPress,
    handleResetBySMSOnPress,
  } = props;
  const { search } = useLocation();

  const descriptionValues = useMemo(
    () => ({
      email: (
        <>
          <wbr />
          <strong className="inline-block whitespace-normal">
            {maskedEmail}
          </strong>
          <wbr />
        </>
      ),
    }),
    [maskedEmail]
  );

  if (currentWorkflow !== "latte.NodeSendForgotPasswordCode" || !maskedEmail) {
    return (
      <AppNavigate
        to={{
          pathname: routes.web.auth.default,
          search: search,
        }}
        replace={true}
      />
    );
  }

  return (
    <WebScreenLayout>
      <WebScreenLayout.Body>
        <div className="flex flex-col justify-center h-full text-center">
          <IconEnvelope className="self-center text-c-button-primary w-25 h-25" />

          <h1 className="mt-3 font-heading text-heading-2">
            <FormattedMessage
              id="web.screens.forgotPasswordProceed.email.title"
              defaultMessage="We Just Sent You an Email"
            />
          </h1>

          <p className="mt-3 text-body-1">
            <FormattedMessage
              id="web.screens.forgotPasswordProceed.email.description"
              defaultMessage={
                "We just sent you a link at {email}. Please click the link from your email to reset your password."
              }
              values={descriptionValues}
            />
          </p>

          <Button
            className="mt-3"
            variant="link"
            onPress={handleResetBySMSOnPress}
          >
            <FormattedMessage
              id="web.screens.forgotPasswordProceed.email.resetBySMS"
              defaultMessage="Reset Password by SMS"
            />
          </Button>

          {canBack ? (
            <Button
              className="w-full mt-12 sm:mt-3"
              variant="secondary"
              onPress={handleBackActionOnPress}
            >
              <FormattedMessage
                id="web.screens.forgotPasswordProceed.email.action"
                defaultMessage="Back to Login"
              />
            </Button>
          ) : null}
        </div>
      </WebScreenLayout.Body>
    </WebScreenLayout>
  );
}
