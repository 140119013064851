const LATTE_SIGNAL = "latte:signal";

export enum LatteSignalType {
  ResetPasswordCompleted = "resetPasswordCompleted",
}

export interface LatteSignal extends CustomEvent {
  detail: { type: LatteSignalType };
}

export function listenToSignal(
  type: LatteSignalType,
  fn: (e: LatteSignal) => void
): () => void {
  const filter = (e: Event) => {
    const eventType = (e as CustomEvent).detail?.type;
    if (!eventType || eventType !== type) {
      return;
    }
    fn(e as LatteSignal);
  };

  document.addEventListener(LATTE_SIGNAL, filter);

  return () => {
    document.removeEventListener(LATTE_SIGNAL, filter);
  };
}
