import React from "react";
import { useShell } from "../../../shell/context";
import { useChangePasswordScreen } from "./useChangePasswordScreen";
import { ChangePasswordScreen as Screen } from "./ChangePasswordScreen";
import { ChangePasswordScreen as KDPScreen } from "./ChangePasswordScreen.kdp";

export function ChangePasswordScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useChangePasswordScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPScreen {...props} />;
    default:
      return <Screen {...props} />;
  }
}
