import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { ReactComponent as IconEnvelope } from "../../assets/email.svg";
import { AppNavigate } from "../../components/AppNavigate";
import { Button } from "../../components/Button";
import { MobileScreenLayout } from "../../components/MobileScreenLayout";
import { routes } from "../../shell/routes";
import { ForgotPasswordByEmailProceedViewProps } from "./viewmodel";

export function ForgotPasswordByEmailProceedView(
  props: ForgotPasswordByEmailProceedViewProps
): React.ReactElement {
  const {
    currentWorkflow,
    maskedEmail,
    canBack,
    handleActionOnPress,
    handleBackActionOnPress,
    handleResetBySMSOnPress,
  } = props;
  const { search } = useLocation();

  const descriptionValues = useMemo(
    () => ({
      email: (
        <>
          <wbr />
          <b className="inline-block whitespace-normal">{maskedEmail}</b>
          <wbr />
        </>
      ),
    }),
    [maskedEmail]
  );

  if (
    currentWorkflow !== "latte.NodeSendForgotPasswordCode" ||
    maskedEmail == null
  ) {
    return (
      <AppNavigate
        to={{
          pathname: routes.mobile.recovery.forgotPassword.email,
          search: search,
        }}
        replace={true}
      />
    );
  }

  return (
    <MobileScreenLayout>
      <MobileScreenLayout.Body>
        <div className="flex flex-col justify-center h-full text-center">
          <IconEnvelope className="self-center text-c-button-primary w-25 h-25" />

          <h1 className="my-2 font-heading text-heading-2">
            <FormattedMessage
              id="mobile.screens.forgotPasswordProceed.email.title"
              defaultMessage="We Just Sent You an Email"
            />
          </h1>

          <p className="text-body-1 text-c-text-disabled px-2 whitespace-pre-wrap">
            <FormattedMessage
              id="mobile.screens.forgotPasswordProceed.email.description"
              defaultMessage={
                "We just sent you a link at {email}.\nPlease click the link from your email to reset your password."
              }
              values={descriptionValues}
            />
          </p>

          <Button
            variant="link"
            className="mt-5"
            onPress={handleResetBySMSOnPress}
          >
            <FormattedMessage
              id="mobile.screens.forgotPasswordProceed.email.resetBySMS"
              defaultMessage="Reset password by SMS"
            />
          </Button>
        </div>
      </MobileScreenLayout.Body>
      <MobileScreenLayout.FixedFooter>
        <Button
          className="w-full"
          type="button"
          preventFocusOnPress={true}
          onPress={handleActionOnPress}
        >
          <FormattedMessage
            id="mobile.screens.forgotPasswordProceed.email.action"
            defaultMessage="Check Email"
          />
        </Button>
        {canBack ? (
          <Button
            className="w-full mt-2"
            variant="secondary"
            onPress={handleBackActionOnPress}
          >
            <FormattedMessage
              id="mobile.screens.forgotPasswordProceed.email.back.action"
              defaultMessage="Back"
            />
          </Button>
        ) : null}
      </MobileScreenLayout.FixedFooter>
    </MobileScreenLayout>
  );
}
