import { Transition } from "@headlessui/react";
import { isIOS, isAndroid } from "@react-aria/utils";
import cn from "classnames";
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useInert } from "../hooks/inert";

const useIOSScreenTransition = isIOS() || isAndroid();

export type ScreenType = "screen" | "overlay";
export type AnimationType = "push" | "pop";

export interface ScreenContextValue {
  type: ScreenType;
  isActive: boolean;
  appear: boolean;
  appearAnimation: AnimationType | null;
  disappearAnimation: AnimationType | null;
  isPop: boolean;
  willAppear: () => void;
  didDisappear: () => void;
}

const ScreenContext = React.createContext<ScreenContextValue>({
  type: "screen",
  isActive: true,
  appear: false,
  appearAnimation: null,
  disappearAnimation: null,
  isPop: false,
  willAppear: () => {},
  didDisappear: () => {},
});

export function useScreenIsActive(): boolean {
  return useContext(ScreenContext).isActive;
}

export const ScreenContainer: React.FC<
  React.PropsWithChildren<{
    type: ScreenType;
  }>
  // eslint-disable-next-line complexity
> = (props) => {
  const { type, children } = props;
  const {
    isActive,
    appear,
    appearAnimation,
    disappearAnimation,
    isPop,
    willAppear,
    didDisappear,
  } = useContext(ScreenContext);

  const isAppearAnimated = appearAnimation !== null;
  const isDisappearAnimated = disappearAnimation !== null;

  const isTop = isPop ? !isActive : isActive;

  const ref = useRef<HTMLDivElement>(null);
  useInert(ref, !isActive);

  useLayoutEffect(() => {
    willAppear();
  }, [willAppear]);

  const handleAfterLeave = useCallback(() => {
    didDisappear();
  }, [didDisappear]);

  useLayoutEffect(() => {
    // handleAfterLeave is triggered too late on iOS back gesture,
    // and caused flickering.
    // Manually trigger it early if no animation.
    if (!isActive && !isDisappearAnimated) {
      didDisappear();
    }
  }, [isActive, didDisappear, isDisappearAnimated]);

  useLayoutEffect(() => {
    if (!isActive) {
      // NOTE(tung): afterLeave sometimes is never called, causing bugs in the screen stack.
      // I've no idea on the actual cause, so just call didDisappear after 300ms (The longest animation) as a workaround.
      const timeout = window.setTimeout(() => {
        didDisappear();
      }, 300);
      return () => {
        window.clearTimeout(timeout);
      };
    }
    return () => {};
  }, [didDisappear, isActive]);

  const classes = useMemo(() => {
    const transition =
      type === "screen"
        ? useIOSScreenTransition
          ? "duration-300"
          : "duration-150"
        : "duration-300 ease-out";
    const transitionIn = useIOSScreenTransition
      ? "translate-x-full"
      : "translate-x-16 opacity-0";
    const transitionOut = useIOSScreenTransition
      ? "-translate-x-[30%]"
      : "-translate-x-16";

    return {
      transition: {
        screen: cn(
          "transition-[transform,opacity]",
          useIOSScreenTransition && "shadow",
          transition
        ),
        overlay: cn("transition-opacity", transition),
      },
      enterFrom: {
        screen: appearAnimation === "pop" ? transitionOut : transitionIn,
        overlay: "opacity-0",
      },
      enterTo: {
        screen: "translate-x-0 opacity-100",
        overlay: "opacity-100",
      },
      leaveTo: {
        screen: disappearAnimation === "pop" ? transitionIn : transitionOut,
        overlay: "opacity-0",
      },
    };
  }, [appearAnimation, disappearAnimation, type]);

  const [doAppear] = useState(appear);

  return (
    <Transition
      show={isActive}
      appear={doAppear}
      className={cn(
        "fixed inset-0 isolate",
        type === "screen" && "bg-white",
        type === "overlay" && "bg-black/50",
        isTop ? "z-10" : "z-0"
      )}
      enter={isAppearAnimated ? classes.transition[type] : ""}
      leave={isDisappearAnimated ? classes.transition[type] : ""}
      enterFrom={isAppearAnimated ? classes.enterFrom[type] : ""}
      enterTo={isAppearAnimated ? classes.enterTo[type] : ""}
      leaveFrom={isDisappearAnimated ? classes.enterTo[type] : ""}
      leaveTo={isDisappearAnimated ? classes.leaveTo[type] : ""}
      afterLeave={handleAfterLeave}
    >
      <div ref={ref} className="w-full h-full">
        {children}
      </div>
    </Transition>
  );
};

export const ScreenProvider: React.FC<
  React.PropsWithChildren<ScreenContextValue>
> = (props) => {
  const {
    type,
    isActive,
    appear,
    appearAnimation,
    disappearAnimation,
    isPop,
    willAppear,
    didDisappear,
    children,
  } = props;

  const isParentActive = useScreenIsActive();

  const contextValue = useMemo(
    () => ({
      type,
      isActive: isParentActive && isActive,
      appear,
      appearAnimation,
      disappearAnimation,
      isPop,
      willAppear,
      didDisappear,
      children,
    }),
    [
      type,
      isParentActive,
      isActive,
      appear,
      appearAnimation,
      disappearAnimation,
      isPop,
      willAppear,
      didDisappear,
      children,
    ]
  );

  return (
    <ScreenContext.Provider value={contextValue}>
      {type === "screen" ? (
        <ScreenContainer type={type}>{children}</ScreenContainer>
      ) : (
        children
      )}
    </ScreenContext.Provider>
  );
};
