import { Platform, isPlatform } from "./platform";

export enum Capability {
  Biometric = "biometric",
}

function isCapability(x: string): x is Capability {
  return Object.values<string>(Capability).includes(x);
}

export interface UIState {
  platform?: Platform;
  x_custom_attributes_b64url?: string;
  bu?: string;
  bu_email_check?: string[];
  email?: string;
  phone?: string;
  user_initiate?: string;
  token?: string;
  x_secrets_token?: string;
  channel?: string;
  capabilities?: Capability[];
}

// eslint-disable-next-line complexity
export function parseUIState(rawStr: string): UIState {
  const searchParams = new URLSearchParams(rawStr);
  const uiState: UIState = {};

  let kdp_found = false;
  for (const [key, value] of searchParams.entries()) {
    switch (key) {
      case "platform":
        if (isPlatform(value)) {
          uiState[key] = value;
        }
        break;
      case "kdp_found":
        if (value === "true") {
          kdp_found = true;
        }
        break;

      case "capabilities":
        uiState["capabilities"] = value.split(",").filter(isCapability);
        break;
      case "bu_email_check":
        uiState["bu_email_check"] = value
          .split(",")
          .filter((x) => x.length > 0);
        break;
      case "x_custom_attributes_b64url":
      // fallthrough
      case "bu":
      // fallthrough
      case "email":
      // fallthrough
      case "phone":
      // fallthrough
      case "token":
      // fallthrough
      case "x_secrets_token":
      // fallthrough
      case "user_initiate":
      // fallthrough
      case "channel":
        uiState[key] = value;
        break;
      default:
        break;
    }
  }

  // Convert user_initiate=login&kdp_found=true to user_initiate=kdp_binding
  if (uiState.user_initiate === "login" && kdp_found) {
    uiState.user_initiate = "kdp_binding";
  }
  return uiState;
}

export function stringifyUIState(uiState: UIState): string {
  const searchParams = new URLSearchParams();
  for (const key in uiState) {
    if (Object.prototype.hasOwnProperty.call(uiState, key)) {
      const value = uiState[key as keyof UIState];
      if (Array.isArray(value)) {
        searchParams.set(key, value.join(","));
      } else if (value) {
        searchParams.set(key, value);
      }
    }
  }
  return searchParams.toString();
}
