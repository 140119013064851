import React from "react";
import { useRouteError } from "react-router-dom";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { Dialog } from "../../components/web/Dialog";
import { DialogErrorContent } from "../../components/web/DialogErrorContent";
import {
  useErrorButtonText,
  useErrorMessage,
  useErrorTitle,
} from "../../hooks/errors";

export function WebErrorScreen(): React.ReactElement {
  const error = useRouteError();
  const title = useErrorTitle(error);
  const message = useErrorMessage(error, false);
  const buttonText = useErrorButtonText(error);

  const errorDialogState = useOverlayTriggerState({ isOpen: true });

  return (
    <Dialog state={errorDialogState} isDismissable={false}>
      <DialogErrorContent
        title={title}
        description={message}
        confirmButtonText={buttonText}
        error={error}
      />
    </Dialog>
  );
}
