import { useCallback, useEffect } from "react";
import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate, // eslint-disable-line no-restricted-imports
  useSearchParams,
} from "react-router-dom";
import { useRefValue } from "../hooks/ref-value";
import {
  checkRedirectURI,
  WorkflowEvent,
  workflowEvents,
} from "../states/interaction/api";
import { withLoading } from "../states/states";
import { Workflow } from "../states/workflows";
import { useShell } from "./context";
import { getWorkflowRoute, makeWorkflowURL } from "./routes";
import { REDIRECT_URI } from "../search";

export function useIsCancellable(): boolean {
  const [searchParams] = useSearchParams();
  return (searchParams.get(REDIRECT_URI) ?? "").length > 0;
}

export function useAppCanGoBack(): boolean {
  const location = useLocation();
  return Boolean(location.state?.canGoBack);
}

type NavigateFunction = (to: To, options?: NavigateOptions) => void;
export function useAppNavigate(): NavigateFunction {
  const navigate = useNavigate();
  const canGoBack = useAppCanGoBack();
  return useCallback(
    (to, options) =>
      navigate(to, {
        ...options,
        state: {
          ...options?.state,
          canGoBack: options?.replace ? canGoBack : true,
        },
      }),
    [navigate, canGoBack]
  );
}

export function useWorkflowNavigate(): (
  workflow: Workflow,
  options?: NavigateOptions
) => Promise<void> {
  const { platform } = useShell();
  const navigate = useAppNavigate();
  const location = useLocation();
  return useCallback(
    async (workflow, options) => {
      // Case 1: the workflow is finished.
      if (workflow.redirectURI != null) {
        const redirectURI = workflow.redirectURI;
        await withLoading(async (handle) => {
          await checkRedirectURI(redirectURI.toString());
          switch (platform) {
            case "mobile": {
              const newIframe = document.createElement("iframe");
              newIframe.setAttribute("src", redirectURI);
              // When allow-top-navigation is set, setting window.location.href will navigate top-level frame.
              newIframe.setAttribute(
                "sandbox",
                "allow-scripts allow-top-navigation"
              );
              newIframe.setAttribute("width", "0");
              newIframe.setAttribute("height", "0");
              newIframe.setAttribute("className", "invisible");
              document.body.appendChild(newIframe);
              handle.loadInfinitely();
              return;
            }
            case "web": {
              window.location.href = redirectURI;
            }
          }
        });
        // End of Case 1
        return;
      }

      const route = getWorkflowRoute(platform, workflow);

      navigate(
        makeWorkflowURL(
          platform,
          workflow,
          new URLSearchParams(location.search)
        ),
        {
          ...options,
          state: { ...options?.state, workflow },
          replace: options?.replace ?? location.pathname === route,
        }
      );
    },
    [navigate, platform, location]
  );
}

export function useWorkflowWebsocket(
  workflowID: string | undefined,
  eventCallback: (workflowEvent: WorkflowEvent) => void
): void {
  const eventCallbackRef = useRefValue((workflowEvent: WorkflowEvent) => {
    eventCallback(workflowEvent);
  });

  useEffect(() => {
    if (workflowID == null) {
      return () => {};
    }

    return workflowEvents(workflowID, (message) => {
      eventCallbackRef.current(message);
    });
  }, [workflowID, eventCallbackRef]);
}
