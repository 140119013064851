import "./index.css";
import "./polyfills";

import ReactDOM from "react-dom/client";
import { sentryInit } from "./sentry";
import { App } from "./App";
import { sentryDSN } from "./config";

sentryInit(sentryDSN);

// FIXME: https://github.com/adobe/react-spectrum/issues/779
ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
