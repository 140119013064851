import React from "react";
import { useShell } from "../../../../shell/context";
import { useSignupSetupMFAScreen } from "./useSignupSetupMFAScreen";
import { SignupSetupMFAScreen as Screen } from "./SignupSetupMFAScreen";
import { SignupSetupMFAScreen as KDPScreen } from "./SignupSetupMFAScreen.kdp";

export function SignupSetupMFAScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useSignupSetupMFAScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPScreen {...props} />;
    default:
      return <Screen {...props} />;
  }
}
