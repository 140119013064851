import React from "react";
import { useShell } from "../../../../shell/context";
import { useLoginVerifyMFAEmailScreen } from "./useLoginVerifyMFAEmailScreen";
import { LoginVerifyMFAEmailScreen as Screen } from "./LoginVerifyMFAEmailScreen";
import { LoginVerifyMFAEmailScreen as KDPScreen } from "./LoginVerifyMFAEmailScreen.kdp";

export function LoginVerifyMFAEmailScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useLoginVerifyMFAEmailScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPScreen {...props} />;
    default:
      return <Screen {...props} />;
  }
}
