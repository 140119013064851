import { useOverlayTriggerState } from "@react-stately/overlays";
import React from "react";
import { useRouteError } from "react-router-dom";
import { Dialog } from "../components/Dialog";
import { DialogErrorContent } from "../components/DialogErrorContent";
import {
  useErrorButtonText,
  useErrorMessage,
  useErrorTitle,
} from "../hooks/errors";
import { useReady } from "../hooks/ready";

export function ErrorScreen(): React.ReactElement {
  // When error screen was displayed, the app must be ready
  useReady();
  const error = useRouteError();
  const title = useErrorTitle(error);
  const message = useErrorMessage(error, false);
  const buttonText = useErrorButtonText(error);

  const errorDialogState = useOverlayTriggerState({ isOpen: true });

  return (
    <Dialog state={errorDialogState} isDismissable={false}>
      <DialogErrorContent
        title={title}
        description={message}
        confirmButtonText={buttonText}
        error={error}
      />
    </Dialog>
  );
}
