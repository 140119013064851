import React from "react";
import { Outlet } from "react-router-dom";
import { MobileWorkflowProvider } from "../../shell/MobileWorkflowProvider";
import { initWorkflowChangeEmail } from "../../states/interaction/intents";

export function ChangeEmailInitScreen(): React.ReactElement {
  return (
    <MobileWorkflowProvider initializer={initWorkflowChangeEmail}>
      <Outlet />
    </MobileWorkflowProvider>
  );
}
