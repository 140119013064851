import { match } from "@formatjs/intl-localematcher";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { IntlProvider as ReactIntlProvider, useIntl } from "react-intl";
import { intlGCSBucketName } from "../config";
import { AppError } from "../errors";
import { UI_LOCALES } from "../search";
import ALEnMessages from "./mobile/AL-en.json";
import ALZhHansCNMessages from "./mobile/AL-zh-Hans-CN.json";
import ALZhHantHKMessages from "./mobile/AL-zh-Hant-HK.json";
import DPEnMessages from "./mobile/DP-en.json";
import DPZhHansCNMessages from "./mobile/DP-zh-Hans-CN.json";
import DPZhHantHKMessages from "./mobile/DP-zh-Hant-HK.json";
import KDPEnMessages from "./mobile/KDP-en.json";
import KDPZhHansCNMessages from "./mobile/KDP-zh-Hans-CN.json";
import KDPZhHantHKMessages from "./mobile/KDP-zh-Hant-HK.json";
import enMessages from "./mobile/en.json";
import zhHansCNMessages from "./mobile/zh-Hans-CN.json";
import zhHantHKMessages from "./mobile/zh-Hant-HK.json";
import webALEnMessages from "./web/AL-en.json";
import webALZhHansCNMessages from "./web/AL-zh-Hans-CN.json";
import webALZhHantHKMessages from "./web/AL-zh-Hant-HK.json";
import webEnMessages from "./web/en.json";
import webZhHansCNMessages from "./web/zh-Hans-CN.json";
import webZhHantHKMessages from "./web/zh-Hant-HK.json";
import { Platform } from "../shell/platform";
import { Brand, mapBrand } from "../shell/brand";

export type Locale = "en" | "zh-Hant-HK" | "zh-Hans-CN";
const defaultLocale: Locale = "en";

const fallbackMessageData: Record<
  Brand,
  Record<Locale, Record<string, string>>
> = {
  K11: {
    en: { ...enMessages, ...webEnMessages },
    "zh-Hant-HK": { ...zhHantHKMessages, ...webZhHantHKMessages },
    "zh-Hans-CN": { ...zhHansCNMessages, ...webZhHansCNMessages },
  },
  AL: {
    en: { ...ALEnMessages, ...webALEnMessages },
    "zh-Hant-HK": { ...ALZhHantHKMessages, ...webALZhHantHKMessages },
    "zh-Hans-CN": { ...ALZhHansCNMessages, ...webALZhHansCNMessages },
  },
  DP: {
    en: DPEnMessages,
    "zh-Hant-HK": DPZhHantHKMessages,
    "zh-Hans-CN": DPZhHansCNMessages,
  },
  KDP: {
    en: KDPEnMessages,
    "zh-Hant-HK": KDPZhHantHKMessages,
    "zh-Hans-CN": KDPZhHansCNMessages,
  },
};

const filePrefix = {
  K11: "",
  DP: "DP-",
  AL: "AL-",
  KDP: "KDP-",
};

async function fetchMessage(platform: Platform, brand: Brand, locale: Locale) {
  let messages: Record<string, string> = {};

  let remoteMessages: Record<string, string> = {};
  const fileName = `intl/${platform}/${filePrefix[brand]}${locale}.json`;
  const res = await fetch(
    `https://storage.googleapis.com/${intlGCSBucketName}/${fileName}`
  );
  if (res.ok) {
    try {
      const platformMessages = await res.json();
      remoteMessages = platformMessages;
    } catch (e: unknown) {
      console.error("failed to parse locale message response", e);
    }
  } else {
    console.error("failed to load locale messages", res.status);
  }

  messages = { ...messages, ...remoteMessages };

  return messages;
}

const localeMap: Partial<Record<string, Locale>> = {
  en: "en",
  zh: "zh-Hant-HK",
  "zh-Hant": "zh-Hant-HK",
  "zh-Hant-HK": "zh-Hant-HK",
  "zh-Hans": "zh-Hans-CN",
  "zh-Hans-CN": "zh-Hans-CN",
};

function getInitialLocale(): Locale {
  const requestedLocales = navigator.languages.slice();

  const queryParams = new URLSearchParams(window.location.search);
  const queryLocales = queryParams
    .get(UI_LOCALES)
    ?.split(/ +/)
    .flatMap((rawLocale) => {
      try {
        return Intl.getCanonicalLocales([rawLocale]);
      } catch {
        // Ignore any invalid locales
      }
      return [];
    });
  if (queryLocales != null) {
    // Prioritize specified locale.
    requestedLocales.splice(0, 0, ...queryLocales);
  }

  const initialLocale =
    localeMap[match(requestedLocales, Object.keys(localeMap), defaultLocale)];

  return initialLocale != null ? initialLocale : defaultLocale;
}

const AppLocaleContext = React.createContext<{
  setBrand: (brand: Brand) => void;
  setPlatform: (platform: Platform) => void;
  isLocaleLoaded: boolean;
  localeLoadedError: AppError | null;
}>({
  setPlatform: () => {},
  setBrand: () => {},
  isLocaleLoaded: false,
  localeLoadedError: null,
});

export const IntlProvider: React.FC<React.PropsWithChildren> = (props) => {
  const initialLocale = useMemo(getInitialLocale, []);

  const [platform, setPlatform] = useState<Platform | undefined>(undefined);
  const [locale] = useState<Locale>(initialLocale);
  const [brand, setBrand] = useState<Brand | undefined>(undefined);
  const [isLocaleLoaded, setIsLocaleLoaded] = useState(false);
  const [localeLoadedError, setLocaleLoadedError] = useState<AppError | null>(
    null
  );

  const [messages, setMessages] = useState(fallbackMessageData.K11.en);

  useEffect(() => {
    if (platform === undefined || brand === undefined) {
      return () => {};
    }
    let isMounted = true;
    setIsLocaleLoaded(false);
    setLocaleLoadedError(null);
    const selectedBrand = mapBrand(brand, platform);
    const fallbackMessages = fallbackMessageData[selectedBrand][locale];
    fetchMessage(platform, selectedBrand, locale)
      .then((msg) => {
        if (!isMounted) {
          return;
        }
        setIsLocaleLoaded(true);
        setMessages({ ...fallbackMessages, ...msg });
      })
      .catch((err) => {
        if (!isMounted) {
          return;
        }
        console.error("Failed to fetch messages:", err);
        setMessages(fallbackMessages);
        setLocaleLoadedError(err);
      });
    return () => {
      isMounted = false;
    };
  }, [brand, locale, platform]);

  const localeContextValue = useMemo(
    () => ({ setPlatform, setBrand, isLocaleLoaded, localeLoadedError }),
    [isLocaleLoaded, localeLoadedError]
  );

  return (
    <AppLocaleContext.Provider value={localeContextValue}>
      <ReactIntlProvider
        defaultLocale={defaultLocale}
        locale={locale}
        messages={messages}
      >
        {props.children}
      </ReactIntlProvider>
    </AppLocaleContext.Provider>
  );
};

export function useLocaleBrand(brand: Brand): void {
  const { setBrand } = useContext(AppLocaleContext);
  useLayoutEffect(() => setBrand(brand), [setBrand, brand]);
}

export function useLocalePlatform(platform: Platform): void {
  const { setPlatform } = useContext(AppLocaleContext);
  useLayoutEffect(() => setPlatform(platform), [setPlatform, platform]);
}

export function useAppLocale(): Locale {
  const { locale } = useIntl();
  return locale as Locale;
}

export function useIsLocaleLoaded(): boolean {
  const { isLocaleLoaded, localeLoadedError } = useContext(AppLocaleContext);
  if (localeLoadedError != null) {
    throw localeLoadedError;
  }
  return isLocaleLoaded;
}
