import React from "react";
import { useShell } from "../../../shell/context";
import { useForgotPasswordBySMSProceedScreen } from "./useForgotPasswordBySMSProceedScreen";
import { KDPForgotPasswordBySMSProceedView } from "../../../components/forgot-password/ForgotPasswordBySMSProceedView.kdp";
import { ForgotPasswordBySMSProceedView } from "../../../components/forgot-password/ForgotPasswordBySMSProceedView";

export function ForgotPasswordBySMSProceedScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useForgotPasswordBySMSProceedScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPForgotPasswordBySMSProceedView {...props} />;
    default:
      return <ForgotPasswordBySMSProceedView {...props} />;
  }
}
