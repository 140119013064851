import { useCallback, useState } from "react";
import { useWorkflowNavigate } from "../../shell/hooks";
import {
  requestEmailOTP,
  requestPasswordMFA,
} from "../../states/interaction/steps";
import { WorkflowAuth } from "../../states/workflows";
import { useTrackSelectLoginMethodClick } from "../../tracking/hooks";
import { useErrorMessage } from "../errors";
import { useInteractionStep } from "../interaction";

export interface LoginSelectMFAMethodState {
  error: unknown;
  errorMessage: string;
  handleRequestEmailOTP: () => void;
  handleRequestPassword: () => void;
  isSendingOTP: boolean;
  isRequestingPassword: boolean;
}

export const useLoginSelectMFAMethod = (
  workflow: WorkflowAuth
): LoginSelectMFAMethodState => {
  const navigate = useWorkflowNavigate();

  const [error, setError] = useState<unknown>(null);
  const errorMessage = useErrorMessage(error);

  const { trigger: sendOTP, isLoading: isSendingOTP } = useInteractionStep(
    workflow,
    requestEmailOTP
  );
  const { trigger: requestPassword, isLoading: isRequestingPassword } =
    useInteractionStep(workflow, requestPasswordMFA);

  const trackSelectLoginMethod = useTrackSelectLoginMethodClick();

  const handleRequestEmailOTP = useCallback(() => {
    (async () => {
      setError(null);
      try {
        trackSelectLoginMethod("email");
        await navigate(await sendOTP(workflow));
      } catch (err: unknown) {
        setError(err);
      }
    })().catch(() => {});
  }, [trackSelectLoginMethod, navigate, sendOTP, workflow]);

  const handleRequestPassword = useCallback(() => {
    (async () => {
      setError(null);
      try {
        trackSelectLoginMethod("password");
        await navigate(await requestPassword(workflow));
      } catch (err: unknown) {
        setError(err);
      }
    })().catch(() => {});
  }, [trackSelectLoginMethod, navigate, requestPassword, workflow]);

  return {
    error,
    errorMessage,
    handleRequestEmailOTP,
    handleRequestPassword,
    isSendingOTP,
    isRequestingPassword,
  };
};
