import React from "react";
import { useShell } from "../../../shell/context";
import { useForgotPasswordBySMSScreen } from "./useForgotPasswordBySMSScreen";
import { ForgotPasswordBySMSView } from "../../../components/forgot-password/ForgotPasswordBySMSView";
import { KDPForgotPasswordBySMSView } from "../../../components/forgot-password/ForgotPasswordBySMSView.kdp";

export function ForgotPasswordBySMSScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useForgotPasswordBySMSScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPForgotPasswordBySMSView {...props} />;
    default:
      return <ForgotPasswordBySMSView {...props} />;
  }
}
