import React from "react";
import { useShell } from "../../../shell/context";
import { useForgotPasswordByEmailProceedScreen } from "./useForgotPasswordByEmailProceedScreen";
import { KDPForgotPasswordByEmailProceedView } from "../../../components/forgot-password/ForgotPasswordByEmailProceedView.kdp";
import { ForgotPasswordByEmailProceedView } from "../../../components/forgot-password/ForgotPasswordByEmailProceedView";

export function ForgotPasswordByEmailProceedScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useForgotPasswordByEmailProceedScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPForgotPasswordByEmailProceedView {...props} />;
    default:
      return <ForgotPasswordByEmailProceedView {...props} />;
  }
}
