import { useCallback, useMemo } from "react";
import { useWorkflowNavigate } from "../../../shell/hooks";
import { useWorkflow } from "../../../shell/workflow";
import { WorkflowChangePassword } from "../../../states/workflows";
import { useDeclareScreenName } from "../../../tracking/hooks";
import { Brand } from "../../../shell/brand";
import { AppScreen, getScreenName } from "../../../tracking/screen";

export interface ChangePasswordSuccessScreenProps {
  workflow: WorkflowChangePassword;
  handleActionOnPress: () => void;
}

export function useChangePasswordSuccessScreen(
  brand: Brand | null
): ChangePasswordSuccessScreenProps {
  useDeclareScreenName(getScreenName(brand, AppScreen.ChangePasswordSuccess));

  const workflow = useWorkflow<WorkflowChangePassword>(
    "latte.NodeDoUpdateAuthenticator",
    "latte.NodeChangePasswordEnd"
  );
  const navigateWorkflow = useWorkflowNavigate();

  const handleActionOnPress = useCallback(() => {
    if (!workflow.finishURI) {
      // Should not have button
      console.error("reset password success: No redirect uri available");
    } else {
      (async () => {
        await navigateWorkflow(workflow);
      })().catch((err) => console.error(err));
    }
  }, [navigateWorkflow, workflow]);

  return useMemo(
    (): ChangePasswordSuccessScreenProps => ({
      workflow,
      handleActionOnPress,
    }),
    [workflow, handleActionOnPress]
  );
}
