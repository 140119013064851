import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useScreenIsActive } from "../components/ScreenContainer";
import { MobileScreenLayout } from "../components/MobileScreenLayout";
import { WorkflowInitializer, WorkflowProvider } from "./workflow";
import { Workflow } from "../states/workflows";
import { useAppNavigate } from "./hooks";
import { makeWorkflowURL } from "./routes";
import { useSearchParams } from "react-router-dom";
import { ScreenLoadingIndicator } from "../components/ScreenLoadingIndicator";

interface MobileWorkflowProviderProps extends React.PropsWithChildren {
  initializer: WorkflowInitializer;
}

export const MobileWorkflowProvider: React.FC<MobileWorkflowProviderProps> = ({
  children,
  initializer,
}) => {
  const navigate = useAppNavigate();
  const [searchParams] = useSearchParams();
  const isMounted = useRef(false);
  const isActive = useScreenIsActive();
  useEffect(() => {
    isMounted.current = isActive;
    return () => {
      isMounted.current = false;
    };
  }, [isActive]);

  const isActiveGetter = useCallback(() => {
    return isMounted.current;
  }, []);

  const loadingComponent = useMemo(
    () => (
      <MobileScreenLayout>
        <ScreenLoadingIndicator />
      </MobileScreenLayout>
    ),
    []
  );

  const onInitialized = useCallback(
    (workflow: Workflow) => {
      navigate(makeWorkflowURL("mobile", workflow, searchParams), {
        replace: true,
        state: { workflow },
      });
    },
    [navigate, searchParams]
  );

  return (
    <WorkflowProvider
      isActive={isActiveGetter}
      initializer={initializer}
      LoadingComponent={loadingComponent}
      onInitialized={onInitialized}
    >
      {children}
    </WorkflowProvider>
  );
};
