import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../../components/Button";
import { MobileScreenLayout } from "../../../../components/MobileScreenLayout";
import { ReactComponent as ChevronRight } from "../../../../assets/chevron-right.svg";
import { ReactComponent as IconEnvelope } from "../../../../assets/envelope.svg";
import { ReactComponent as IconLock } from "../../../../assets/lock.svg";
import { LoginSelectMFAMethodScreenProps } from "./useLoginSelectMFAMethodScreen";

export function LoginSelectMFAMethodScreen(
  props: LoginSelectMFAMethodScreenProps
): React.ReactElement {
  const {
    errorMessage,
    handleRequestEmailOTP,
    handleRequestPassword,
    isSendingOTP,
    isRequestingPassword,
    isLoading,
    initiationIntent,
  } = props;

  const isKDPFound = initiationIntent === "kdp_binding";

  return (
    <>
      <MobileScreenLayout>
        <MobileScreenLayout.Body>
          <h1 className="mb-1 font-heading text-heading-2">
            {isKDPFound ? (
              <FormattedMessage
                id="mobile.screens.login.selectMFAMethod.loginWithKDPFound.title"
                defaultMessage="A K Dollar account is found in OneLogin"
              />
            ) : initiationIntent === "signup" ? (
              <FormattedMessage
                id="mobile.screens.login.selectMFAMethod.signup.title"
                defaultMessage="An existing OneLogin account is found"
              />
            ) : (
              <FormattedMessage
                id="mobile.screens.login.selectMFAMethod.login.title"
                defaultMessage="Select a login method"
              />
            )}
          </h1>
          <p className="mb-4 text-body-1">
            {isKDPFound ? (
              <FormattedMessage
                id="mobile.screens.login.selectMFAMethod.loginWithKDPFound.description"
                defaultMessage="To use this K Dollar account, please login with either OneLogin email or password"
              />
            ) : (
              <FormattedMessage
                id="mobile.screens.login.selectMFAMethod.description"
                defaultMessage="Please login to your account by password or email verification"
              />
            )}
          </p>

          <div className="space-y-4">
            <Button
              variant="card"
              className="w-full"
              onPress={handleRequestEmailOTP}
              disabled={isLoading}
              loading={isSendingOTP}
            >
              <span className="flex items-center w-full space-x-3">
                <IconEnvelope className="w-8 h-8 text-c-button-card-icon" />
                <span className="flex-1 text-start">
                  <FormattedMessage
                    id="mobile.screens.login.selectMFAMethod.emailLogin"
                    defaultMessage="Email Login"
                  />
                </span>
                <ChevronRight className="w-4 h-4 text-black/30" />
              </span>
            </Button>

            <Button
              variant="card"
              className="w-full"
              onPress={handleRequestPassword}
              disabled={isLoading}
              loading={isRequestingPassword}
            >
              <span className="flex items-center w-full space-x-3">
                <IconLock className="w-8 h-8 text-c-button-card-icon" />
                <span className="flex-1 text-start">
                  <FormattedMessage
                    id="mobile.screens.login.selectMFAMethod.passwordLogin"
                    defaultMessage="Password Login"
                  />
                </span>
                <ChevronRight className="w-4 h-4 text-black/30" />
              </span>
            </Button>
          </div>

          {errorMessage ? (
            <p className="mt-4 text-body-2 text-error">{errorMessage}</p>
          ) : null}
        </MobileScreenLayout.Body>
      </MobileScreenLayout>
    </>
  );
}
