import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../components/Button";
import { MobileScreenLayout } from "../../components/MobileScreenLayout";
import { ForgotPasswordByEmailViewProps } from "./viewmodel";

export function ForgotPasswordByEmailView(
  props: ForgotPasswordByEmailViewProps
): React.ReactElement {
  const { maskedEmail, errorMessage, handleSendForgotPasswordCode, isLoading } =
    props;

  return (
    <MobileScreenLayout>
      <MobileScreenLayout.Body>
        <h1 className="mb-1 font-heading text-heading-2">
          <FormattedMessage
            id="mobile.screens.forgotPassword.email.title"
            defaultMessage="Forgot your password?"
          />
        </h1>
        <p className="mb-6 text-body-1">
          <FormattedMessage
            id="mobile.screens.forgotPassword.email.description"
            defaultMessage="Press Reset Password by Email and we will send you an email at {maskedEmail} to reset the password"
            values={{
              maskedEmail: (
                <>
                  <wbr />
                  <b className="inline-block whitespace-normal">
                    {maskedEmail}
                  </b>
                  <wbr />
                </>
              ),
            }}
          />
        </p>
        {errorMessage ? (
          <p className="mt-4 text-body-2 text-error">{errorMessage}</p>
        ) : null}
      </MobileScreenLayout.Body>

      <MobileScreenLayout.FixedFooter>
        <Button
          className="w-full"
          type="button"
          preventFocusOnPress={true}
          loading={isLoading}
          onPress={handleSendForgotPasswordCode}
        >
          <FormattedMessage
            id="mobile.screens.forgotPassword.email.action"
            defaultMessage="Reset Password by Email"
          />
        </Button>
      </MobileScreenLayout.FixedFooter>
    </MobileScreenLayout>
  );
}
