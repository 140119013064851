import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";

export interface TrackingContextObject {
  pushScreenName: (reactID: string, screenName: string) => void;
  popScreenName: (reactID: string) => void;
  topScreenName: string | null;
}

const ZERO = {
  pushScreenName: (_reactID: string, _screenName: string) => {},
  popScreenName: (_reactID: string) => {},
  topScreenName: null,
};

export const TrackingContext = createContext<TrackingContextObject>(ZERO);

export function TrackingContextProvider(
  props: PropsWithChildren
): ReactElement {
  const ref = useRef(new Map<string, string>());
  const [count, setCount] = useState(0);
  const { children } = props;

  // We want to keep pushScreenName stable so that calling it does not result in
  // infinite recursion.
  const pushScreenName = useCallback((reactID: string, screenName: string) => {
    const value = ref.current.get(reactID);
    if (value !== screenName) {
      ref.current.set(reactID, screenName);
      setCount((prev) => prev + 1);
    }
  }, []);

  // We want to keep popScreenName stable so that calling it does not result in
  // infinite recursion.
  const popScreenName = useCallback((reactID: string) => {
    const has = ref.current.has(reactID);
    if (has) {
      ref.current.delete(reactID);
      setCount((prev) => prev + 1);
    }
  }, []);

  // Make topScreenName changes whenever count is updated.
  const topScreenName = useMemo(() => {
    const entries = Array.from(ref.current.entries());
    if (entries.length <= 0) {
      return null;
    }
    return entries[entries.length - 1][1];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const value = useMemo(() => {
    return {
      pushScreenName,
      popScreenName,
      topScreenName,
    };
  }, [pushScreenName, popScreenName, topScreenName]);

  return (
    <TrackingContext.Provider value={value}>
      {children}
    </TrackingContext.Provider>
  );
}
