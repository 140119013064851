import React, { useRef } from "react";
import cn from "classnames";
import { useButton } from "@react-aria/button";
import { ReactComponent as Loader } from "../../assets/loader.svg";
import css from "./Button.module.css";

const variants = {
  primary: css["button--primary"],
  secondary: css["button--secondary"],
  text: css["button--text"],
  link: css["button--link"],
};

interface ButtonProps extends React.PropsWithChildren {
  className?: string;
  variant?: keyof typeof variants;
  type?: "button" | "submit";
  disabled?: boolean;
  loading?: boolean;
  preventFocusOnPress?: boolean;
  onPress?: () => void;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    className,
    variant = "primary",
    type,
    disabled = false,
    loading = false,
    preventFocusOnPress = false,
    onPress,
    children,
  } = props;

  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps, isPressed } = useButton(
    {
      onPress,
      type,
      isDisabled: disabled || loading,
      // @ts-expect-error
      preventFocusOnPress,
    },
    ref
  );

  const displayLoadingSpinner = loading && !disabled;

  return (
    <button
      type="button"
      className={cn(
        className,
        css["button"],
        variants[variant],
        isPressed && css["button--pressed"],
        displayLoadingSpinner && css["button--loading"]
      )}
      {...buttonProps}
      ref={ref}
    >
      {displayLoadingSpinner ? (
        <Loader
          className={cn(
            css["loader"],
            "w-5 h-5 animate-spin absolute inset-0 m-auto z-10"
          )}
        />
      ) : null}
      <span className={cn("w-full", displayLoadingSpinner && "opacity-0")}>
        {children}
      </span>
    </button>
  );
};
