import {
  OverlayTriggerState,
  useOverlayTriggerState,
} from "@react-stately/overlays";
import React, { useState, useRef, useCallback } from "react";
import { MaximumAttemptExceededError } from "../../components/MaximumAttemptExceededError";
import { useWorkflowNavigate } from "../../shell/hooks";
import { WorkflowError, AuthgearError } from "../../states/interaction/errors";
import { resendPhoneOTP, verifyPhoneOTP } from "../../states/interaction/steps";
import { WorkflowAuth } from "../../states/workflows";
import { useErrorMessage } from "../errors";
import { useInteractionStep } from "../interaction";
import { useRefValue } from "../ref-value";

const otpLength = 6;

export interface VerifyPhoneOTPState {
  otp: string;
  otpInputRef: React.RefObject<HTMLInputElement>;
  otpLength: number;
  error: unknown;
  errorDialogContent: React.ReactNode;
  errorDialogState: OverlayTriggerState;
  errorMessage: string;
  rateLimitState: "ok" | "resend" | "blocked";
  isResending: boolean;
  isVerifying: boolean;
  handleOTPOnChange: (otp: string) => void;
  handleResend: () => void;
  handleVerify: () => void;
}

export const useVerifyPhoneOTP = (
  workflow: WorkflowAuth
): VerifyPhoneOTPState => {
  const navigateWorkflow = useWorkflowNavigate();

  const { trigger: resendOTP, isLoading: isResending } = useInteractionStep(
    workflow,
    resendPhoneOTP
  );
  const { trigger: verifyOTP, isLoading: isVerifying } = useInteractionStep(
    workflow,
    verifyPhoneOTP
  );

  const [error, setError] = useState<unknown>(() => {
    return workflow.failedAttemptRateLimitExceeded
      ? new WorkflowError(
          workflow,
          new AuthgearError(
            "", // name
            "RateLimited", // reason
            "", // message
            {
              bucket_name: "TrackFailedOTPAttemptBucket",
            }
          )
        )
      : null;
  });
  const errorMessage = useErrorMessage(error);

  const errorDialogState = useOverlayTriggerState({
    defaultOpen: false,
  });
  const [errorDialogContent, setErrorDialogContent] =
    useState<React.ReactNode>(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const [otp, setOTP] = useState("");
  const [rateLimitState, setRateLimitState] = useState<
    "ok" | "resend" | "blocked"
  >(workflow.failedAttemptRateLimitExceeded ? "resend" : "ok");

  const handleResend = useCallback(() => {
    (async () => {
      setError(null);
      try {
        const newWorkflow = await resendOTP(workflow);
        setRateLimitState("ok");
        setOTP("");
        inputRef.current?.focus();
        await navigateWorkflow(newWorkflow);
      } catch (err: unknown) {
        if (WorkflowError.is(err, "RateLimited")) {
          setRateLimitState("blocked");
          errorDialogState.open();
          setErrorDialogContent(<MaximumAttemptExceededError />);
          return;
        }

        setError(err);
      }
    })().catch(() => {});
  }, [resendOTP, navigateWorkflow, workflow, errorDialogState]);

  const doVerifyOTP = useRefValue(async (otp: string) => {
    if (otp.length < otpLength) {
      return;
    }

    setError(null);

    try {
      await navigateWorkflow(await verifyOTP(workflow, otp), { replace: true });
    } catch (err: unknown) {
      setError(err);
      setOTP("");

      if (WorkflowError.is(err, "RateLimited")) {
        setRateLimitState("resend");
      } else {
        inputRef.current?.focus();
      }
    }
  });

  const handleVerify = useCallback(() => {
    (async () => {
      await doVerifyOTP.current(otp);
    })().catch(() => {});
  }, [doVerifyOTP, otp]);

  const handleOTPOnChange = useCallback(
    (otp: string) => {
      setError(null);
      setOTP(otp);

      if (otp.length === otpLength) {
        (async () => {
          await doVerifyOTP.current(otp);
        })().catch(() => {});
      }
    },
    [doVerifyOTP]
  );

  return {
    otpLength,
    otp,
    error,
    errorMessage,
    errorDialogState,
    errorDialogContent,
    rateLimitState,
    handleResend,
    isResending,
    handleVerify,
    isVerifying,
    handleOTPOnChange,
    otpInputRef: inputRef,
  };
};
