import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../components/Button";
import { Dialog } from "../../../components/Dialog";
import { FormField } from "../../../components/FormField";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import { MobileScreenLayout } from "../../../components/MobileScreenLayout";
import { OTPInput } from "../../../components/OTPInput";
import { VerifyEmailOTPScreenProps } from "./useVerifyEmailOTPScreen";

const otpLength = 6;

export function VerifyEmailOTPScreen(
  props: VerifyEmailOTPScreenProps
): React.ReactElement {
  const {
    maskedEmail,
    remainingSeconds,
    otp,
    errorMessage,
    isLoading,
    isResending,
    rateLimitState,
    handleOTPOnChange,
    handleResendOnPress,
    handleFormSubmit,
    inputRef,
    errorDialogState,
    errorDialogContent,
  } = props;

  const descriptionValues = useMemo(
    () => ({
      email: <span className="text-body-1 font-semibold">{maskedEmail}</span>,
    }),
    [maskedEmail]
  );

  const resendValues = useMemo(
    () => ({ remainingSeconds }),
    [remainingSeconds]
  );

  return (
    <form className="h-full" noValidate={true} onSubmit={handleFormSubmit}>
      <MobileScreenLayout>
        <MobileScreenLayout.Body>
          <h1 className="font-heading text-heading-2 mb-1">
            <FormattedMessage
              id="mobile.screens.verify.verifyEmailOTP.title"
              defaultMessage="Verify your OneLogin Email"
            />
          </h1>
          <p className="text-body-1 mb-6">
            <FormattedMessage
              id="mobile.screens.verify.verifyEmailOTP.description"
              defaultMessage="We sent a verification code to your email address {email}, please fill below to continue your registration."
              values={descriptionValues}
            />
          </p>

          <FormField>
            <OTPInput
              className="w-full h-11"
              value={otp}
              onChange={handleOTPOnChange}
              maxLength={otpLength}
              required={true}
              autoFocus={true}
              disabled={rateLimitState !== "ok"}
              readOnly={isLoading}
              inputRef={inputRef}
            />
            <div className="h-14">
              {errorMessage ? (
                <FormField.ErrorMessage>{errorMessage}</FormField.ErrorMessage>
              ) : null}
            </div>
          </FormField>

          <Button
            variant="text"
            disabled={
              remainingSeconds > 0 || isLoading || rateLimitState === "blocked"
            }
            loading={isResending}
            onPress={handleResendOnPress}
          >
            {remainingSeconds === 0 ? (
              <FormattedMessage
                id="mobile.screens.verify.verifyEmailOTP.resend.action"
                defaultMessage="Resend"
              />
            ) : (
              <FormattedMessage
                id="mobile.screens.verify.verifyEmailOTP.resend.cooldown"
                defaultMessage="Resend in {remainingSeconds}s"
                values={resendValues}
              />
            )}
          </Button>
        </MobileScreenLayout.Body>
      </MobileScreenLayout>

      {isResending ? <LoadingOverlay /> : null}

      <Dialog state={errorDialogState} isDismissable={false}>
        {errorDialogContent}
      </Dialog>
    </form>
  );
}
