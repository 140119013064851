import { useObjectRef, useResizeObserver } from "@react-aria/utils";
import cn from "classnames";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAutoFocus } from "../hooks/auto-focus";
import css from "./OTPInput.module.css";

interface OTPInputProps {
  className?: string;
  value: string;
  onChange: (value: string) => void;

  maxLength: number;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;

  inputRef?: React.Ref<HTMLInputElement>;
}

function useOTPInputSelection(
  inputRef: React.RefObject<HTMLInputElement>,
  maxLength: number
) {
  const [selection, setSelection] = useState<[number, number]>([0, 0]);
  const [needUpdateSelection, setNeedUpdateSelection] = useState({});
  useLayoutEffect(() => {
    const input = inputRef.current;
    if (input == null) {
      return;
    }

    const [oldStart, oldEnd] = selection;
    let start = input.selectionStart ?? 0;
    let end = input.selectionEnd ?? 0;
    if (start === oldStart && end === oldEnd) {
      return;
    }

    if (start === end && end !== maxLength) {
      const bias = end === oldStart ? -1 : 0;

      // Ensure minimum one selected char when caret not at the end.
      requestAnimationFrame(() => {
        start = Math.max(0, Math.min(maxLength - 1, start + bias));
        end = start + 1;
        input.setSelectionRange(start, end);
      });
    }

    setSelection([start, end]);
  }, [inputRef, needUpdateSelection, maxLength, selection]);

  const selectionStart = Math.min(selection[0], maxLength - 1);
  let selectionEnd = Math.min(selection[1], maxLength);
  if (selectionEnd === selectionStart) {
    // Make closed range into half-open rrange
    selectionEnd++;
  }

  return {
    updateSelection: useCallback(() => setNeedUpdateSelection({}), []),
    selectionStart,
    selectionEnd,
  };
}

export const OTPInput: React.FC<OTPInputProps> = (props) => {
  const {
    className,
    value,
    onChange,
    maxLength,
    required = false,
    disabled = false,
    readOnly = false,
    autoFocus = false,
    inputRef: propsInputRef,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef: React.RefObject<HTMLInputElement> =
    useObjectRef(propsInputRef);

  const [size, setSize] = useState<[number, number]>([0, 0]);
  useResizeObserver({
    ref: containerRef,
    onResize: useCallback(() => {
      const width = containerRef.current?.offsetWidth ?? 0;
      const height = containerRef.current?.offsetHeight ?? 0;
      setSize((sz) => {
        if (sz[0] === width && sz[1] === height) {
          return sz;
        }
        return [width, height];
      });
    }, []),
  });

  const inputStyle = useMemo<CSSProperties>(
    () => ({
      letterSpacing: `calc(${size[0]}px / ${maxLength})`,
      fontSize: "1px",
    }),
    [size, maxLength]
  );

  const { selectionStart, selectionEnd, updateSelection } =
    useOTPInputSelection(inputRef, maxLength);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value.replace(/[^0-9]/g, ""));
      updateSelection();
    },
    [onChange, updateSelection]
  );

  useEffect(() => {
    const handler = () => updateSelection();
    document.addEventListener("selectionchange", handler);
    return () => document.removeEventListener("selectionchange", handler);
  }, [updateSelection]);

  const [isFocused, setIsFocused] = useState(false);
  const handleOnFocus = useCallback(() => setIsFocused(true), []);
  const handleOnBlur = useCallback(() => setIsFocused(false), []);

  useEffect(() => {
    updateSelection();
  }, [isFocused, updateSelection]);

  const handleOnPaste = useCallback(
    (e: React.ClipboardEvent) => {
      e.preventDefault();
      const text = e.clipboardData.getData("text/plain");
      if (text) {
        onChange(text.replace(/[^0-9]/g, ""));
      }
    },
    [onChange]
  );

  useAutoFocus(inputRef, autoFocus);

  return (
    <div className={className}>
      <div
        ref={containerRef}
        className={cn(css["container"], disabled && css["container--disabled"])}
      >
        <input
          ref={inputRef}
          type="text"
          className={css["input"]}
          style={inputStyle}
          value={value}
          onChange={handleOnChange}
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern={`\\d{${maxLength}}`}
          maxLength={maxLength}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          onPaste={handleOnPaste}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />

        <div className={css["text-container"]}>
          {new Array(maxLength).fill(0).map((_, i) => {
            const isSelected =
              isFocused && i >= selectionStart && i < selectionEnd;
            return (
              <div
                key={i}
                className={cn(
                  css["text-digit"],
                  isSelected && !disabled && css["text-digit--active"]
                )}
              >
                <span>{value.slice(i, i + 1)}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
