import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useWorkflowNavigate } from "../shell/hooks";
import { completeInteraction } from "../states/interaction/complete";
import { WorkflowError, AuthgearError } from "../states/interaction/errors";
import { useInteractionIsLoading } from "../states/states";
import { Workflow } from "../states/workflows";
import { AppCancelError } from "../errors";

type AnyFunction = (...args: any[]) => any;

interface UseInteractionStepResult<F extends AnyFunction> {
  isLoading: boolean;
  trigger: (...args: Parameters<F>) => Promise<Awaited<ReturnType<F>>>;
}

export function useInteractionStep<F extends AnyFunction>(
  workflow: Workflow | null,
  stepFn: F
): UseInteractionStepResult<F> {
  const isInteractionLoading = useInteractionIsLoading();
  const navigate = useWorkflowNavigate();
  const instanceID = workflow?.instanceID;

  const isMounted = useRef(false);
  useLayoutEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const trigger = useCallback(
    async (...args: Parameters<F>) => {
      const activeElement = document.activeElement;
      if (activeElement instanceof HTMLElement) {
        activeElement.blur();
      }

      if (isInteractionLoading) {
        // Halt execution if loading.
        await new Promise(() => {});
      }

      setIsLoading(true);
      try {
        const result = await stepFn(...args);
        if (!isMounted.current) {
          // Prevent callbacks from executing if unmounted.
          await new Promise(() => {});
        }
        return result;
      } catch (err: unknown) {
        if (
          err instanceof WorkflowError &&
          err.workflow.instanceID === instanceID
        ) {
          // Update workflow state of curernt route
          await navigate(err.workflow);
        }
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [isInteractionLoading, stepFn, navigate, instanceID]
  );

  return { trigger, isLoading };
}

export const useAbortInteraction = (error: unknown): (() => void) => {
  return useCallback(() => {
    (async () => {
      if (error != null && !(error instanceof AppCancelError)) {
        if (
          error instanceof WorkflowError ||
          AuthgearError.isWorkflowNotFound(error)
        ) {
          await completeInteraction({
            error: "unexpected_error",
            workflowError: error,
          });
        } else {
          await completeInteraction({
            error: "unexpected_error",
          });
        }
      } else {
        await completeInteraction({ error: "cancel" });
      }
    })().catch((err) => console.error(err));
  }, [error]);
};
