import createStore from "zustand";

export interface InteractionState {
  clientID: string;
  loadingCount: number;
  isReady: boolean;
}

export const useInteraction = createStore<InteractionState>(() => ({
  clientID: "",
  loadingCount: 0,
  isReady: false,
}));

export function useInteractionIsLoading(): boolean {
  return useInteraction((s) => s.loadingCount > 0);
}

interface LoadingHandle {
  loadInfinitely: () => void;
}

export async function withLoading<T>(
  fn: (handle: LoadingHandle) => Promise<T>
): Promise<T> {
  useInteraction.setState((s) => ({ loadingCount: s.loadingCount + 1 }));
  let shouldEnd: boolean = true as boolean;
  const handle: LoadingHandle = {
    loadInfinitely: () => {
      shouldEnd = false;
    },
  };
  try {
    return await fn(handle);
  } finally {
    if (shouldEnd) {
      useInteraction.setState((s) => ({ loadingCount: s.loadingCount - 1 }));
    }
  }
}

export function setIsReady(isReady: boolean): void {
  useInteraction.setState(() => ({ isReady }));
}

export function useIsReady(): boolean {
  return useInteraction((s) => s.isReady);
}
