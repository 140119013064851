import { Overlay, useModalOverlay } from "@react-aria/overlays";
import {
  OverlayTriggerState,
  useOverlayTriggerState,
} from "@react-stately/overlays";
import React, { useCallback } from "react";
import { ScreenContainer } from "../ScreenContainer";

interface DialogLayoutBodyProps extends React.PropsWithChildren {
  state: OverlayTriggerState;
  isDismissable?: boolean;
}

const DialogLayoutBody = (props: DialogLayoutBodyProps) => {
  const { state, isDismissable = true, children } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  const { modalProps, underlayProps } = useModalOverlay(
    {
      isDismissable,
      isKeyboardDismissDisabled: false,
    },
    state,
    ref
  );

  // ref ScreenStack overlay
  return (
    <div
      className="flex items-center justify-center w-full h-full px-6"
      {...underlayProps}
    >
      <div
        ref={ref}
        className="bg-white rounded-lg px-8 py-10 w-full sm:w-[518px]"
        {...modalProps}
      >
        {children}
      </div>
    </div>
  );
};

interface DialogLayoutProps extends React.PropsWithChildren {
  isDismissable?: boolean;
  onDismiss?: () => void;
}

export function DialogLayout(props: DialogLayoutProps): React.ReactElement {
  const { onDismiss, isDismissable, children } = props;
  const state = useOverlayTriggerState({
    isOpen: true,
    onOpenChange: useCallback(
      (isOpen: boolean) => {
        if (!isOpen) {
          onDismiss?.();
        }
      },
      [onDismiss]
    ),
  });

  return (
    <Overlay>
      <ScreenContainer type="overlay">
        <DialogLayoutBody state={state} isDismissable={isDismissable}>
          {children}
        </DialogLayoutBody>
      </ScreenContainer>
    </Overlay>
  );
}

DialogLayout.Body = DialogLayoutBody;
