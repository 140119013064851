import { useMemo } from "react";
import { validateEmail } from "../utils/email";
import { useDebounce } from "./debounce";

export function useValidateEmail(
  email: string,
  debounceMS: number = 1000,
  isFocused?: boolean
): { error: unknown; deboundedError: unknown } {
  const error = useMemo(() => {
    try {
      if (email === "") {
        return null;
      }
      validateEmail(email);
      return null;
    } catch (e: unknown) {
      return e;
    }
  }, [email]);

  const [deboundedError, isDebounced] = useDebounce(
    error,
    isFocused ? debounceMS : 0
  );

  return {
    error,
    deboundedError: isDebounced ? null : deboundedError,
  };
}
