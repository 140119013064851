import React, { useState, useCallback, useMemo } from "react";
import { useWorkflowNavigate } from "../../shell/hooks";
import { setupMFA } from "../../states/interaction/steps";
import { WorkflowAuth } from "../../states/workflows";
import { useTrackRegistrationSuccess } from "../../tracking/hooks";
import { validateEmail } from "../../utils/email";
import { useErrorMessage } from "../errors";
import { useInteractionStep } from "../interaction";
import { useValidateEmail } from "../validate-email";
import { useAppLocale } from "../../intl/IntlProvider";
import { useUIState } from "../../shell/uistate";
import { Brand, tryMapBrand } from "../../shell/brand";
import { useShell } from "../../shell/context";
import { getEmailCheckBrands } from "../../states/nwd";

export interface SignupSetupMFAState {
  email: string;
  onChangeEmail: (email: string) => void;
  handleSetupMFA: (e: React.FormEvent) => void;
  isLoading: boolean;
  error: unknown;
  errorMessage: string;
  isEmailInvalid: boolean;
}

export const useSignupSetupMFA = (
  workflow: WorkflowAuth,
  password: string,
  isFocused: boolean
): SignupSetupMFAState => {
  const locale = useAppLocale();
  const navigateWorkflow = useWorkflowNavigate();

  const [email, setEmail] = useState("");

  const { deboundedError: emailError, error: immediateEmailError } =
    useValidateEmail(email, 1000, isFocused);
  const [error, setError] = useState<unknown>(null);
  const errorMessage = useErrorMessage(emailError ?? error);

  const onChangeEmail = useCallback((email: string) => {
    setEmail(email);
    setError(null);
  }, []);

  const { trigger, isLoading } = useInteractionStep(workflow, setupMFA);

  const { bu_email_check } = useUIState();
  const { brand } = useShell();
  const emailCheckBrands = useMemo(
    () =>
      getEmailCheckBrands(
        brand,
        bu_email_check
          ?.map((x) => tryMapBrand(x))
          .filter((x): x is Brand => x != null)
      ),
    [bu_email_check, brand]
  );

  const trackRegistrationSuccess = useTrackRegistrationSuccess();

  const handleSetupMFA = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      (async () => {
        setError(null);
        try {
          validateEmail(email);
          const newWorkflow = await trigger(
            workflow,
            locale,
            email,
            password,
            emailCheckBrands
          );
          trackRegistrationSuccess(workflow.initiationIntent);
          await navigateWorkflow(newWorkflow);
        } catch (err: unknown) {
          setError(err);
        }
      })().catch(() => {});
    },
    [
      locale,
      emailCheckBrands,
      email,
      navigateWorkflow,
      password,
      trackRegistrationSuccess,
      trigger,
      workflow,
    ]
  );

  return {
    email,
    onChangeEmail,
    handleSetupMFA,
    isLoading,
    error,
    errorMessage,
    isEmailInvalid: immediateEmailError != null,
  };
};
