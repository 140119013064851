import React from "react";
import { useShell } from "../../../shell/context";
import { useChangeEmailScreen } from "./useChangeEmailScreen";
import { ChangeEmailScreen as Screen } from "./ChangeEmailScreen";
import { ChangeEmailScreen as KDPScreen } from "./ChangeEmailScreen.kdp";

export function ChangeEmailScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useChangeEmailScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPScreen {...props} />;
    default:
      return <Screen {...props} />;
  }
}
