import { useState, useCallback, useMemo } from "react";
import { useDeclareScreenName } from "../../../../tracking/hooks";
import { useLocation } from "react-router-dom";
import { useAppCanGoBack } from "../../../../shell/hooks";
import { WorkflowForgotPasswordV2 } from "../../../../states/workflows";
import { maskPhoneNumber } from "../../../../utils/phone";

export interface ForgotPasswordBySMSProceedScreenProps {
  currentWorkflow: string | undefined;
  maskedPhoneNumber: string | undefined;
  canBack: boolean;
  handleBackActionOnPress: () => void;
}

export function useForgotPasswordBySMSProceedScreen(): ForgotPasswordBySMSProceedScreenProps {
  useDeclareScreenName("Forgot-Password-SMS-Sent");

  const { state } = useLocation();
  const canBack = useAppCanGoBack();

  const [workflow] = useState<WorkflowForgotPasswordV2 | undefined>(
    state?.workflow
  );

  const handleBackActionOnPress = useCallback(() => {
    history.back();
  }, []);

  const maskedPhoneNumber = useMemo(
    () => maskPhoneNumber(workflow?.phoneNumber ?? ""),
    [workflow]
  );

  return {
    currentWorkflow: workflow?.current,
    maskedPhoneNumber: maskedPhoneNumber,
    canBack,
    handleBackActionOnPress,
  };
}
