import { useRef, useEffect } from "react";
import { useRefValue } from "./ref-value";

async function sleep(ms: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms);
  });
}

export function usePolling(rawFn: () => Promise<void>, delay: number): void {
  const fn = useRefValue(rawFn);
  const currentPoll = useRef<Promise<void> | null>(null);

  useEffect(() => {
    let stop = false;

    const previousPoll = currentPoll.current;
    currentPoll.current = new Promise<void>((resolve) => {
      async function poll() {
        await previousPoll;
        // eslint-disable-next-line no-unmodified-loop-condition
        while (!stop) {
          try {
            await fn.current();
          } catch (err: unknown) {
            console.error(err);
          }
          await sleep(delay);
        }
        resolve();
      }

      setTimeout(() => {
        poll().catch(console.error);
      }, 0);
    });
    return () => {
      stop = true;
    };
  }, [delay, fn]);
}
