import React from "react";
import { IntlProvider } from "./intl/IntlProvider";
import { Router } from "./shell/Router";

export const App: React.FC = () => {
  return (
    <IntlProvider>
      <Router />
    </IntlProvider>
  );
};
