import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FormField } from "../../../../components/FormField";
import { PasswordInput } from "../../../../components/PasswordInput";
import { Button } from "../../../../components/web/Button";
import { PasswordRequirement } from "../../../../components/web/PasswordRequirement";
import { PasswordStrength } from "../../../../components/web/PasswordStrength";
import { WebScreenLayout } from "../../../../components/WebScreenLayout";
import { useSignupSetupMFA } from "../../../../hooks/auth/useSignupSetupMFA";
import { useWorkflow } from "../../../../shell/workflow";
import { checkPassword } from "../../../../states/interaction/password";
import { WorkflowAuth } from "../../../../states/workflows";
import { useDeclareScreenName } from "../../../../tracking/hooks";
import { useInteractionIsLoading } from "../../../../states/states";

export function WebSignupSetupMFAScreen(): React.ReactElement {
  useDeclareScreenName("Auth-Setup");

  const workflow = useWorkflow<WorkflowAuth>("latte.IntentCreateLoginID");
  const intl = useIntl();

  const [password, setPassword] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const {
    email,
    onChangeEmail,
    handleSetupMFA,
    error,
    errorMessage,
    isEmailInvalid,
  } = useSignupSetupMFA(workflow, password, isFocused);
  const isLoading = useInteractionIsLoading();

  const handleEmailOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeEmail(e.target.value);
    },
    [onChangeEmail]
  );
  const handlePasswordOnChange = useCallback((value: string) => {
    setPassword(value);
  }, []);
  const handleOnFocus = useCallback(() => setIsFocused(true), []);
  const handleOnBlur = useCallback(() => setIsFocused(false), []);

  const passwordChecks = useMemo(() => checkPassword(password), [password]);

  return (
    <form className="h-full" noValidate={true} onSubmit={handleSetupMFA}>
      <WebScreenLayout>
        <WebScreenLayout.Body>
          <h1 className="mb-3 text-center font-heading text-heading-2">
            <FormattedMessage
              id="web.screens.signup.setupMFA.title"
              defaultMessage="Complete Your Login Details"
            />
          </h1>

          <div className="mb-12">
            <p className="text-center text-body-1">
              <FormattedMessage
                id="web.screens.signup.setupMFA.description"
                defaultMessage="Please enter your email and set a password for the login."
              />
            </p>
          </div>

          <FormField className="self-stretch mb-6">
            <FormField.InputContainer>
              <FormField.TextInput
                value={email}
                type="email"
                onChange={handleEmailOnChange}
                required={true}
                readOnly={isLoading}
                autoFocus={true}
                autoComplete="email"
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                placeholder={intl.formatMessage({
                  id: "web.screens.signup.setupMFA.email.placeholder",
                  defaultMessage: "Email Address",
                })}
              />
            </FormField.InputContainer>
            {errorMessage ? (
              <FormField.ErrorMessage>{errorMessage}</FormField.ErrorMessage>
            ) : null}
          </FormField>

          <div className="self-stretch mb-8">
            <FormField className="self-stretch">
              <FormField.InputContainer>
                <PasswordInput
                  value={password}
                  onChange={handlePasswordOnChange}
                  required={true}
                  readOnly={isLoading}
                  autoComplete="new-password"
                  placeholder={intl.formatMessage({
                    id: "web.screens.signup.setupMFA.password.placeholder",
                    defaultMessage: "Password",
                  })}
                />
              </FormField.InputContainer>
            </FormField>
            {password.length > 0 ? (
              <PasswordStrength
                className="self-stretch mt-3 sm:mt-4"
                password={password}
              />
            ) : null}
          </div>

          <PasswordRequirement
            className="self-stretch"
            checks={passwordChecks}
            isFilled={password.length > 0}
          />

          <Button
            className="self-stretch mt-12"
            type="submit"
            preventFocusOnPress={true}
            disabled={
              email.length === 0 ||
              !passwordChecks.ok ||
              error != null ||
              isEmailInvalid
            }
            loading={isLoading}
          >
            <FormattedMessage
              id="web.screens.signup.setupMFA.action"
              defaultMessage="Continue"
            />
          </Button>
        </WebScreenLayout.Body>
      </WebScreenLayout>
    </form>
  );
}
