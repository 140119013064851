import React, { useCallback, useState, useMemo } from "react";
import { useErrorMessage } from "../../../hooks/errors";
import { useInteractionStep } from "../../../hooks/interaction";
import { useAppNavigate } from "../../../shell/hooks";
import { routes } from "../../../shell/routes";
import { useWorkflow } from "../../../shell/workflow";
import { WorkflowError } from "../../../states/interaction/errors";
import {
  checkPassword,
  PasswordChecks,
} from "../../../states/interaction/password";
import { changePassword } from "../../../states/interaction/steps";
import { WorkflowChangePassword } from "../../../states/workflows";
import { useDeclareScreenName } from "../../../tracking/hooks";
import { useInteractionIsLoading } from "../../../states/states";
import { Brand } from "../../../shell/brand";
import { AppScreen, getScreenName } from "../../../tracking/screen";

export interface ChangePasswordScreenProps {
  oldPassword: string;
  newPassword: string;
  passwordChecks: PasswordChecks;

  isLoading: boolean;

  errorMessage: string;
  showErrorAtOldPasswordField: boolean;

  handleOldPasswordOnChange: (s: string) => void;
  handleNewPasswordOnChange: (s: string) => void;
  handleOnSubmit: (e: React.FormEvent) => void;
}

export function useChangePasswordScreen(
  brand: Brand | null
): ChangePasswordScreenProps {
  useDeclareScreenName(getScreenName(brand, AppScreen.ChangePassword));

  const workflow = useWorkflow<WorkflowChangePassword>(
    "latte.NodeChangePassword"
  );
  const navigate = useAppNavigate();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const passwordChecks = useMemo(
    () => checkPassword(newPassword),
    [newPassword]
  );

  const { trigger } = useInteractionStep(workflow, changePassword);
  const isLoading = useInteractionIsLoading();

  const [error, setError] = useState<unknown>(null);
  const errorMessage = useErrorMessage(error);
  const showErrorAtOldPasswordField = useMemo(() => {
    return WorkflowError.is(error, "InvalidCredentials");
  }, [error]);

  const handleOldPasswordOnChange = useCallback((value: string) => {
    setOldPassword(value);
  }, []);

  const handleNewPasswordOnChange = useCallback((value: string) => {
    setNewPassword(value);
    setError(null);
  }, []);

  const handleOnSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      setError(null);

      trigger(workflow, oldPassword, newPassword).then(
        (workflow) =>
          navigate(
            {
              pathname: routes.mobile.settings.changePasswordSuccess,
              search: window.location.search,
            },
            { replace: true, state: { workflow } }
          ),
        (err) => setError(err)
      );
    },
    [trigger, workflow, oldPassword, newPassword, navigate]
  );

  return {
    oldPassword,
    newPassword,
    passwordChecks,
    isLoading,
    errorMessage,
    showErrorAtOldPasswordField,
    handleOldPasswordOnChange,
    handleNewPasswordOnChange,
    handleOnSubmit,
  };
}
