import { mobileRoutePrefix, webRoutePrefix } from "../config";
import {
  Workflow,
  WorkflowForgotPasswordV2,
  WorkflowReauthForgotPassword,
  WorkflowResetPassword,
} from "../states/workflows";
import { Platform } from "./platform";

export const routes = {
  web: {
    prefix: webRoutePrefix,
    preload: webRoutePrefix + "/preload",
    recovery: {
      setupPassword: webRoutePrefix + "/setup-password",
      setupPasswordSuccess: webRoutePrefix + "/setup-password/success",
      forgotPassword: {
        email: webRoutePrefix + "/recovery/email",
        sms: webRoutePrefix + "/recovery/sms",
      },
      forgotPasswordProceed: {
        email: webRoutePrefix + "/recovery/email/proceed",
        sms: webRoutePrefix + "/recovery/sms/proceed",
      },
      resetPassword: webRoutePrefix + "/recovery/reset",
      resetPasswordSuccess: webRoutePrefix + "/recovery/success",
    },
    auth: {
      prefix: webRoutePrefix + "/auth",
      default: webRoutePrefix + "/auth",
      verifyPhoneOTP: webRoutePrefix + "/auth",
      signup: {
        default: webRoutePrefix + "/auth/setup",
        setupMFA: webRoutePrefix + "/auth/setup",
      },
      login: {
        default: webRoutePrefix + "/auth/mfa",
        selectMFA: webRoutePrefix + "/auth/mfa",
        verifyMFAEmail: webRoutePrefix + "/auth/mfa/email",
        verifyMFAPassword: webRoutePrefix + "/auth/mfa/password",
      },
      loginLink: webRoutePrefix + "/auth/login_link",
      loginLinkSuccess: webRoutePrefix + "/auth/login_link/success",
    },
  },
  mobile: {
    prefix: mobileRoutePrefix,
    preload: mobileRoutePrefix + "/preload",
    auth: {
      prefix: mobileRoutePrefix + "/auth",
      default: mobileRoutePrefix + "/auth",
      verifyPhoneOTP: mobileRoutePrefix + "/auth",
      login: {
        default: mobileRoutePrefix + "/auth/mfa",
        selectMFA: mobileRoutePrefix + "/auth/mfa",
        verifyMFAEmail: mobileRoutePrefix + "/auth/mfa/email",
        verifyMFAPassword: mobileRoutePrefix + "/auth/mfa/password",
      },
      signup: {
        default: mobileRoutePrefix + "/auth/setup",
        setupMFA: mobileRoutePrefix + "/auth/setup",
      },
    },
    reauth: {
      default: mobileRoutePrefix + "/reauth",
      forgotPassword: {
        email: mobileRoutePrefix + "/reauth/recovery/email",
        sms: mobileRoutePrefix + "/reauth/recovery/sms",
      },
      forgotPasswordProceed: {
        email: mobileRoutePrefix + "/reauth/recovery/email/proceed",
        sms: mobileRoutePrefix + "/reauth/recovery/sms/proceed",
      },
    },
    recovery: {
      forgotPassword: {
        email: mobileRoutePrefix + "/recovery/email",
        sms: mobileRoutePrefix + "/recovery/sms",
      },
      forgotPasswordProceed: {
        email: mobileRoutePrefix + "/recovery/email/proceed",
        sms: mobileRoutePrefix + "/recovery/sms/proceed",
      },

      resetPassword: mobileRoutePrefix + "/recovery/reset",
      resetPasswordSuccess: mobileRoutePrefix + "/recovery/success",
    },
    verify: {
      email: mobileRoutePrefix + "/verify/email",
    },
    migrate: {
      setupUser: mobileRoutePrefix + "/migrate/setup",
    },
    settings: {
      changePassword: mobileRoutePrefix + "/settings/change_password",
      changePasswordSuccess:
        mobileRoutePrefix + "/settings/change_password/success",
      changeEmail: mobileRoutePrefix + "/settings/change_email",
      changeEmailVerify: mobileRoutePrefix + "/settings/change_email/verify",
    },
  },
} as const;

// eslint-disable-next-line complexity
export function getMobileWorkflowRoute(workflow: Workflow): string {
  switch (workflow.current) {
    case "latte.NodeVerifyPhoneSMS":
      return routes.mobile.auth.verifyPhoneOTP;
    case "latte.IntentCreateLoginID":
      switch (workflow.root) {
        case "latte.IntentAuthenticate":
          return routes.mobile.auth.signup.default;
        case "latte.IntentMigrate":
          return routes.mobile.migrate.setupUser;
        default:
          return routes.mobile.auth.default;
      }
    case "latte.NodeAuthenticateOOBOTPPhoneEnd":
      return routes.mobile.auth.login.selectMFA;
    case "latte.NodeAuthenticatePassword":
      switch (workflow.root) {
        case "latte.IntentReauthenticate":
          return routes.mobile.reauth.default;
        case "latte.IntentAuthenticate":
        default:
          return routes.mobile.auth.login.verifyMFAPassword;
      }
    case "latte.NodeAuthenticateEmailLoginLink":
      return routes.mobile.auth.login.verifyMFAEmail;
    case "latte.NodeChangeEmail":
      return routes.mobile.settings.changeEmail;
    case "latte.NodeVerifyEmail":
      switch (workflow.root) {
        case "latte.IntentChangeEmail":
          return routes.mobile.settings.changeEmailVerify;
        case "latte.IntentVerifyUser":
        default:
          return routes.mobile.verify.email;
      }
    case "latte.IntentReauthForgotPassword":
    case "latte.NodeForgotPasswordForUser":
    case "latte.NodeForgotPasswordWithLoginID":
      switch ((workflow as WorkflowReauthForgotPassword).channel) {
        case "email":
          return routes.mobile.reauth.forgotPassword.email;
        case "sms":
          return routes.mobile.reauth.forgotPassword.sms;
        default:
          throw new Error(
            `unexpected forgot password channel: ${
              (workflow as WorkflowReauthForgotPassword).channel
            }`
          );
      }
    case "latte.IntentForgotPasswordV2":
      switch ((workflow as WorkflowForgotPasswordV2).channel) {
        case "email":
          return routes.mobile.recovery.forgotPassword.email;
        case "sms":
          return routes.mobile.recovery.forgotPassword.sms;
        default:
          throw new Error(
            `unexpected forgot password channel: ${
              (workflow as WorkflowForgotPasswordV2).channel
            }`
          );
      }
    case "latte.NodeValidatedResetPasswordCode":
      return routes.mobile.recovery.resetPassword;
    case "latte.NodeChangePassword":
      return routes.mobile.settings.changePassword;
    case "latte.NodeDoUpdateAuthenticator":
    case "latte.NodeChangePasswordEnd":
      return routes.mobile.settings.changePasswordSuccess;
    default:
      return routes.mobile.auth.default;
  }
}

// eslint-disable-next-line complexity
export function getWebWorkflowRoute(workflow: Workflow): string {
  switch (workflow.current) {
    case "latte.NodeVerifyPhoneSMS":
    case "latte.IntentVerifyCaptcha":
      return routes.web.auth.verifyPhoneOTP;
    case "latte.IntentCreateLoginID":
      switch (workflow.root) {
        case "latte.IntentAuthenticate":
        case "latte.IntentProtectedAuthenticate":
          return routes.web.auth.signup.default;
        default:
          return routes.web.auth.default;
      }
    case "latte.NodeAuthenticateOOBOTPPhoneEnd":
      return routes.web.auth.login.selectMFA;
    case "latte.NodeAuthenticatePassword":
      return routes.web.auth.login.verifyMFAPassword;
    case "latte.NodeAuthenticateEmailLoginLink":
      return routes.web.auth.login.verifyMFAEmail;
    case "latte.IntentVerifyLoginLink":
      return routes.web.auth.loginLink;
    case "latte.NodeVerifiedLoginLink":
      return routes.web.auth.loginLinkSuccess;
    case "latte.NodeValidatedResetPasswordCode":
      switch ((workflow as WorkflowResetPassword).initiationIntent) {
        case "setup":
          return routes.web.recovery.setupPassword;
        case "reset":
          return routes.web.recovery.resetPassword;
        default:
          return routes.web.recovery.resetPassword;
      }
    case "latte.IntentForgotPassword":
      switch ((workflow as WorkflowForgotPasswordV2).channel) {
        case "email":
          return routes.web.recovery.forgotPassword.email;
        case "sms":
          return routes.web.recovery.forgotPassword.sms;
        default:
          throw new Error(
            `unexpected forgot password channel: ${
              (workflow as WorkflowForgotPasswordV2).channel
            }`
          );
      }
    default:
      return routes.web.auth.default;
  }
}

export function getWorkflowRoute(
  platform: Platform,
  workflow: Workflow
): string {
  switch (platform) {
    case "web":
      return getWebWorkflowRoute(workflow);
    case "mobile":
      return getMobileWorkflowRoute(workflow);
  }
  throw new Error("getWorkflowRoute: Unknown platform");
}

export function makeWorkflowURL(
  platform: Platform,
  workflow: Workflow,
  searchParams: URLSearchParams
): string {
  const route = getWorkflowRoute(platform, workflow);
  const query = new URLSearchParams(searchParams);
  query.set("_w", workflow.workflowID);
  query.set("_i", workflow.instanceID);
  return [route, query].join("?");
}

export function excludeWorkflowParams(
  searchParams: URLSearchParams
): URLSearchParams {
  const query = new URLSearchParams(searchParams);
  query.delete("_w");
  query.delete("_i");
  return query;
}

export function isAuthenticationFlow(
  platform: Platform,
  path: string
): boolean {
  let prefix = "";
  switch (platform) {
    case "web":
      prefix = routes.web.auth.prefix;
      break;
    case "mobile":
      prefix = routes.mobile.auth.prefix;
      break;
  }
  if (path.startsWith(prefix)) {
    return true;
  }
  return false;
}
