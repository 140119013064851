import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../../components/Button";
import { MobileScreenLayout } from "../../../../components/MobileScreenLayout";
import { ReactComponent as ChevronRight } from "../../../../assets/chevron-right.svg";
import { ReactComponent as IconLoginEmail } from "../../../../assets/kdp-login-email.svg";
import { ReactComponent as IconLoginPassword } from "../../../../assets/kdp-login-password.svg";
import { LoginSelectMFAMethodScreenProps } from "./useLoginSelectMFAMethodScreen";
import { LoadingOverlay } from "../../../../components/LoadingOverlay";

export function LoginSelectMFAMethodScreen(
  props: LoginSelectMFAMethodScreenProps
): React.ReactElement {
  const {
    errorMessage,
    handleRequestEmailOTP,
    handleRequestPassword,
    isSendingOTP,
    isRequestingPassword,
    isLoading,
    initiationIntent,
  } = props;

  return (
    <>
      <MobileScreenLayout>
        <MobileScreenLayout.Body>
          <h1 className="mb-6 font-heading text-heading-2">
            {initiationIntent === "signup" ? (
              <FormattedMessage
                id="mobile.screens.login.selectMFAMethod.signup.title"
                defaultMessage="An existing OneLogin account is found"
              />
            ) : (
              <FormattedMessage
                id="mobile.screens.login.selectMFAMethod.login.title"
                defaultMessage="Select a login method"
              />
            )}
          </h1>

          <div className="space-y-4">
            <Button
              variant="card"
              className="w-full"
              onPress={handleRequestEmailOTP}
              disabled={isLoading}
              loading={isSendingOTP}
            >
              <span className="flex items-center w-full space-x-3">
                <span className="w-12 h-12 rounded-full bg-bu-kdp-light grid place-content-center">
                  <IconLoginEmail className="w-6 h-6 text-c-button-card-icon" />
                </span>
                <span className="flex-1 text-start font-bold">
                  <FormattedMessage
                    id="mobile.screens.login.selectMFAMethod.emailLogin"
                    defaultMessage="Email Login"
                  />
                </span>
                <ChevronRight className="w-6 h-6 text-c-button-primary" />
              </span>
            </Button>

            <Button
              variant="card"
              className="w-full"
              onPress={handleRequestPassword}
              disabled={isLoading}
              loading={isRequestingPassword}
            >
              <span className="flex items-center w-full space-x-3">
                <span className="w-12 h-12 rounded-full bg-bu-kdp-light grid place-content-center">
                  <IconLoginPassword className="w-6 h-6 text-c-button-card-icon" />
                </span>
                <span className="flex-1 text-start font-bold">
                  <FormattedMessage
                    id="mobile.screens.login.selectMFAMethod.passwordLogin"
                    defaultMessage="Password Login"
                  />
                </span>
                <ChevronRight className="w-6 h-6 text-c-button-primary" />
              </span>
            </Button>
          </div>

          {errorMessage ? (
            <p className="mt-4 text-body-2 text-error">{errorMessage}</p>
          ) : null}
        </MobileScreenLayout.Body>
      </MobileScreenLayout>

      {isLoading ? <LoadingOverlay /> : null}
    </>
  );
}
