import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FormField } from "../../../../components/FormField";
import { PasswordInput } from "../../../../components/PasswordInput";
import { Button } from "../../../../components/web/Button";
import { WebScreenLayout } from "../../../../components/WebScreenLayout";
import { useLoginVerifyMFAPassword } from "../../../../hooks/auth/useLoginVerifyMFAPassword";
import { useAppCanGoBack } from "../../../../shell/hooks";
import { useWorkflow } from "../../../../shell/workflow";
import { WorkflowAuth } from "../../../../states/workflows";
import { useDeclareScreenName } from "../../../../tracking/hooks";
import { useInteractionIsLoading } from "../../../../states/states";

export function WebLoginVerifyMFAPasswordScreen(): React.ReactElement {
  useDeclareScreenName("Auth-Mfa-Password");

  const workflow = useWorkflow<WorkflowAuth>("latte.NodeAuthenticatePassword");
  const intl = useIntl();

  const canGoBack = useAppCanGoBack();
  const handleChooseOtherMethodOnPress = useCallback(() => {
    if (canGoBack) {
      history.back();
    } else {
      // Should never happen
      console.error(
        "login verify MFA password: Cannot return to previous page"
      );
    }
  }, [canGoBack]);

  const {
    password,
    onChangePassword,
    isMaximumAttemptExceeded,
    error,
    errorMessage,
    handleVerifyMFAPassword,
    isLoading: isVerifying,
    handleForgotPasswordRedirect,
  } = useLoginVerifyMFAPassword(workflow);
  const isInteractionLoading = useInteractionIsLoading();
  const isLoading = isVerifying || isInteractionLoading;

  return (
    <form
      className="h-full"
      noValidate={true}
      onSubmit={handleVerifyMFAPassword}
    >
      <WebScreenLayout>
        <WebScreenLayout.Body>
          <h1 className="mb-12 font-heading text-heading-2">
            <FormattedMessage
              id="web.screens.login.verifyMFAPassword.title"
              defaultMessage="Password Log In"
            />
          </h1>

          <input
            className="hidden"
            aria-hidden="true"
            readOnly={true}
            type="text"
            autoComplete="username"
            name=""
            value={workflow.phoneNumber}
          />

          <FormField className="w-full mb-6">
            <FormField.InputContainer>
              <PasswordInput
                value={password}
                onChange={onChangePassword}
                required={true}
                readOnly={isLoading}
                autoFocus={true}
                autoComplete="current-password"
                placeholder={intl.formatMessage({
                  id: "web.screens.login.verifyMFAPassword.password.placeholder",
                  defaultMessage: "Password",
                })}
              />
            </FormField.InputContainer>

            {errorMessage ? (
              <FormField.ErrorMessage>{errorMessage}</FormField.ErrorMessage>
            ) : null}
          </FormField>

          <Button
            variant="text"
            className="self-start underline"
            onPress={handleForgotPasswordRedirect}
          >
            <FormattedMessage
              id="web.screens.login.verifyMFAPassword.forgotPassword"
              defaultMessage="Forgot Password?"
            />
          </Button>

          <Button
            className="w-full my-12"
            type="submit"
            preventFocusOnPress={true}
            disabled={
              password.length === 0 || error != null || isMaximumAttemptExceeded
            }
            loading={isLoading}
          >
            <FormattedMessage
              id="web.screens.login.verifyMFAPassword.action"
              defaultMessage="Continue"
            />
          </Button>

          <Button
            variant="text"
            className="underline"
            onPress={handleChooseOtherMethodOnPress}
          >
            <FormattedMessage
              id="web.screens.login.verifyMFAPassword.chooseOtherMethod"
              defaultMessage="Choose other log in methods"
            />
          </Button>
        </WebScreenLayout.Body>
      </WebScreenLayout>
    </form>
  );
}
