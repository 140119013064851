import React, { useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useAppNavigate } from "../../shell/hooks";
import { resetPassword } from "../../states/interaction/steps";
import { WorkflowResetPassword } from "../../states/workflows";
import { useErrorMessage } from "../errors";
import { useInteractionStep } from "../interaction";

export interface ResetPasswordState {
  password: string;
  onChangePassword: (password: string) => void;
  errorMessage: string;
  handleResetPassword: (e: React.FormEvent) => void;
}

export const useResetPassword = (
  workflow: WorkflowResetPassword,
  successPath: string
): ResetPasswordState => {
  const navigate = useAppNavigate();
  const location = useLocation();

  const [error, setError] = useState<unknown>(null);
  const errorMessage = useErrorMessage(error);

  const [password, setPassword] = useState("");
  const onChangePassword = useCallback((password: string) => {
    setPassword(password);
    setError(null);
  }, []);

  const { trigger: doResetPassword } = useInteractionStep(
    workflow,
    resetPassword
  );

  const handleResetPassword = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      setError(null);

      doResetPassword(workflow, password).then(
        (workflow) => {
          navigate(
            {
              pathname: successPath,
              search: location.search,
            },
            { replace: true, state: { workflow } }
          );
        },
        (err) => {
          console.error("reset password:", err);
          setError(err);
        }
      );
    },
    [
      doResetPassword,
      workflow,
      password,
      navigate,
      successPath,
      location.search,
    ]
  );

  return {
    password,
    onChangePassword,
    errorMessage,
    handleResetPassword,
  };
};
