import React, { useMemo } from "react";
import cn from "classnames";
import parsePhoneNumber from "libphonenumber-js/mobile";
import { FormattedMessage } from "react-intl";

export interface SetupPasswordUserInfo {
  phone?: string;
  email?: string;
}

function Row({
  className,
  label,
  value,
}: {
  className?: string;
  label: React.ReactNode;
  value: React.ReactNode;
}) {
  return (
    <div className={className}>
      <h3 className="text-subheading uppercase text-c-form-label ">{label}</h3>
      <p className="text-body-1 font-semibold text-c-text">{value}</p>
    </div>
  );
}

export function SetupPasswordUserInfoView({
  userInfo,
  className,
}: {
  userInfo: SetupPasswordUserInfo;
  className?: string;
}): React.ReactElement {
  const formattedPhone = useMemo<string | undefined>(() => {
    if (!userInfo.phone) {
      return undefined;
    }
    const parsed = parsePhoneNumber(userInfo.phone);
    if (!parsed) {
      return undefined;
    }
    return parsed.formatInternational();
  }, [userInfo.phone]);

  return (
    <section
      className={cn(
        "bg-c-setup-password-userinfo p-4 space-y-4 text-left",
        className
      )}
    >
      {formattedPhone ? (
        <Row
          label={
            <FormattedMessage
              id="web.components.setupPasswordUserInfo.mobile"
              defaultMessage="Mobile"
            />
          }
          value={formattedPhone}
        />
      ) : null}
      {userInfo.email ? (
        <Row
          label={
            <FormattedMessage
              id="web.components.setupPasswordUserInfo.email"
              defaultMessage="Email"
            />
          }
          value={userInfo.email}
        />
      ) : null}
      <Row
        label={
          <FormattedMessage
            id="web.components.setupPasswordUserInfo.password"
            defaultMessage="Password"
          />
        }
        value={
          <FormattedMessage
            id="web.components.setupPasswordUserInfo.password.value"
            defaultMessage="8 or more alphanumeric characters you entered just now"
          />
        }
      />
    </section>
  );
}
