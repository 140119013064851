import { useState, useCallback, useMemo } from "react";
import { useDeclareScreenName } from "../../../../tracking/hooks";
import { useLocation } from "react-router-dom";
import { useAppNavigate, useAppCanGoBack } from "../../../../shell/hooks";
import { routes } from "../../../../shell/routes";
import { WorkflowForgotPasswordV2 } from "../../../../states/workflows";
import { ForgotPasswordNavigationState } from "../../../../hooks/forgot-password/useSendForgotPasswordCodeV2";
import { maskEmail } from "../../../../utils/email";

export interface ForgotPasswordByEmailProceedScreenProps {
  currentWorkflow: string | undefined;
  maskedEmail: string | undefined;
  canBack: boolean;
  handleBackActionOnPress: () => void;
  handleResetBySMSOnPress: () => void;
}

export function useForgotPasswordByEmailProceedScreen(): ForgotPasswordByEmailProceedScreenProps {
  useDeclareScreenName("Forgot-Password-Email-Sent");

  const navigate = useAppNavigate();
  const { state } = useLocation();
  const canBack = useAppCanGoBack();

  const [workflow] = useState<WorkflowForgotPasswordV2 | undefined>(
    state?.workflow
  );

  const handleBackActionOnPress = useCallback(() => {
    history.back();
  }, []);

  const handleResetBySMSOnPress = useCallback(() => {
    if (!workflow) {
      console.error("cannot reset by sms because previous workflow is missing");
      return;
    }
    const newState: ForgotPasswordNavigationState = {
      phoneNumber: workflow.phoneNumber!,
      maskedEmail: workflow.maskedEmail ?? "",
      backWorkflow: workflow,
    };
    navigate(
      {
        pathname: routes.web.recovery.forgotPassword.sms,
        search: location.search,
      },
      { state: newState, replace: true }
    );
  }, [navigate, workflow]);

  const furtherMaskedEmail = useMemo(
    () => maskEmail(workflow?.maskedEmail ?? ""),
    [workflow]
  );

  return {
    currentWorkflow: workflow?.current,
    maskedEmail: furtherMaskedEmail,
    canBack,
    handleBackActionOnPress,
    handleResetBySMSOnPress,
  };
}
