import { useMemo } from "react";
import { useScreenIsActive } from "../../../../components/ScreenContainer";
import {
  LoginVerifyMFAEmailState,
  useLoginVerifyMFAEmail,
} from "../../../../hooks/auth/useLoginVerifyMFAEmail";
import { useRemainingSeconds } from "../../../../hooks/timer";
import { Brand } from "../../../../shell/brand";
import { useWorkflow } from "../../../../shell/workflow";
import { useInteractionIsLoading } from "../../../../states/states";
import { WorkflowAuth } from "../../../../states/workflows";
import { useDeclareScreenName } from "../../../../tracking/hooks";
import { AppScreen, getScreenName } from "../../../../tracking/screen";
import { maskEmail } from "../../../../utils/email";

export interface LoginVerifyMFAEmailScreenProps
  extends LoginVerifyMFAEmailState {
  isLoading: boolean;
  remainingSeconds: number;
  maskedEmail?: string;
}

export function useLoginVerifyMFAEmailScreen(
  brand: Brand | null
): LoginVerifyMFAEmailScreenProps {
  useDeclareScreenName(getScreenName(brand, AppScreen.LoginVerifyMFAEmail));

  const screenIsActive = useScreenIsActive();

  const workflow = useWorkflow<WorkflowAuth>(
    "latte.NodeAuthenticateEmailLoginLink"
  );

  const state = useLoginVerifyMFAEmail(
    "LoginVerifyMFAEmailScreen",
    workflow,
    screenIsActive
  );

  const isInteractionLoading = useInteractionIsLoading();
  const { isProceeding, isResending } = state;
  const isLoading = isProceeding || isResending || isInteractionLoading;

  const remainingSeconds = useRemainingSeconds(workflow.resendResetTimestamp!);

  const furtherMaskedEmail = useMemo(
    () => maskEmail(workflow.maskedEmail ?? ""),
    [workflow.maskedEmail]
  );

  return {
    ...state,
    isLoading,
    remainingSeconds,
    maskedEmail: furtherMaskedEmail,
  };
}
