import React, { useEffect, useMemo, useRef, useState } from "react";
import { type TurnstileRenderOptions } from "../@types/turnstile";
import { turnstileSiteKey } from "../config";
import { useAppLocale } from "../intl/IntlProvider";
import { useTurnstile } from "../hooks/turnstile";

interface CaptchaFieldProps {
  onSuccess: (token: string) => void;
  onFailure: (err: unknown) => void;
}

export function CaptchaField({
  onSuccess,
  onFailure,
}: CaptchaFieldProps): React.ReactElement {
  const turnstile = useTurnstile();
  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null);

  const locale = useAppLocale();
  const widgetLocale = useMemo<TurnstileRenderOptions["language"]>(() => {
    switch (locale) {
      case "en":
        return "en";
      case "zh-Hans-CN":
        return "zh-CN";
      case "zh-Hant-HK":
        return "zh-TW";
    }
    return "en";
  }, [locale]);

  const currentOnSuccess = useRef(onSuccess);
  currentOnSuccess.current = onSuccess;

  const currentOnFailure = useRef(onFailure);
  currentOnFailure.current = onFailure;

  useEffect(() => {
    if (!containerEl || turnstile == null) {
      return;
    }

    turnstile.render(containerEl, {
      sitekey: turnstileSiteKey,
      callback: (token: string) => {
        currentOnSuccess.current(token);
      },
      "error-callback": (err: unknown) => {
        console.warn("turnstile render error:", err);
        currentOnFailure.current(err);
      },
      language: widgetLocale,
    });
  }, [containerEl, widgetLocale, turnstile]);

  return <div ref={setContainerEl}></div>;
}
