import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../../components/Button";
import { Dialog } from "../../../components/Dialog";
import { FormField } from "../../../components/FormField";
import { MobileScreenLayout } from "../../../components/MobileScreenLayout";
import { PasswordInput } from "../../../components/PasswordInput";
import { ReauthPasswordScreenProps } from "./useReauthPasswordScreen";
import { ResetPasswordButton } from "../../../components/ResetPasswordButton";

export function ReauthPasswordScreen(
  props: ReauthPasswordScreenProps
): React.ReactElement {
  const intl = useIntl();

  const {
    password,
    onChangePassword,
    errorMessage,
    errorDialogState,
    errorDialogContent,
    handleReauthByPassword,
    handleReauthByBiometric,
    handleForgotPassword,
    isLoading,
    canReauthByBiometric,
    failedAttempts,
    warningThreshold,
  } = props;

  return (
    <form
      className="h-full"
      noValidate={true}
      onSubmit={handleReauthByPassword}
    >
      <MobileScreenLayout>
        <MobileScreenLayout.Body>
          <h1 className="mb-1 font-heading text-heading-2">
            <FormattedMessage
              id="mobile.screens.reauth.title"
              defaultMessage="Login with One ID"
            />
          </h1>
          <p className="mb-10 text-body-1">
            <FormattedMessage
              id="mobile.screens.reauth.description"
              defaultMessage="Please enter your password to continue"
            />
          </p>

          <FormField className="pb-1">
            <FormField.InputContainer>
              <PasswordInput
                value={password}
                onChange={onChangePassword}
                required={true}
                readOnly={isLoading}
                autoFocus={true}
                autoComplete="current-password"
                placeholder={intl.formatMessage({
                  id: "mobile.screens.reauth.password.placeholder",
                  defaultMessage: "Password",
                })}
              />
            </FormField.InputContainer>

            <div className="h-8 mb-4">
              {errorMessage ? (
                <FormField.ErrorMessage>{errorMessage}</FormField.ErrorMessage>
              ) : null}
            </div>
          </FormField>

          {failedAttempts < warningThreshold ? (
            <Button
              variant="text"
              className="underline"
              onPress={handleForgotPassword}
            >
              <FormattedMessage
                id="mobile.screens.reauth.forgotPassword"
                defaultMessage="Forgot Password?"
              />
            </Button>
          ) : (
            <ResetPasswordButton
              onPress={handleForgotPassword}
              disabled={isLoading}
            >
              <FormattedMessage
                id="mobile.screens.reauth.resetPasswordNow"
                defaultMessage="Reset your password now"
              />
            </ResetPasswordButton>
          )}
          <div className="mt-[30px]">
            {canReauthByBiometric ? (
              <Button
                className="w-full mb-2"
                variant="secondary"
                onPress={handleReauthByBiometric}
              >
                <span className="font-semibold">
                  <FormattedMessage
                    id="mobile.screens.reauth.biometric"
                    defaultMessage="Login With Biometric"
                  />
                </span>
              </Button>
            ) : null}
            <Button
              className="w-full"
              type="submit"
              preventFocusOnPress={true}
              disabled={password.length === 0}
              loading={isLoading}
            >
              <FormattedMessage
                id="mobile.screens.reauth.action"
                defaultMessage="Login"
              />
            </Button>
          </div>
        </MobileScreenLayout.Body>
      </MobileScreenLayout>
      <Dialog state={errorDialogState} isDismissable={false}>
        {errorDialogContent}
      </Dialog>
    </form>
  );
}
