import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FormField } from "../../../components/FormField";
import { PasswordInput } from "../../../components/PasswordInput";
import { Button } from "../../../components/web/Button";
import { PasswordRequirement } from "../../../components/web/PasswordRequirement";
import { WebScreenLayout } from "../../../components/WebScreenLayout";
import { useResetPassword } from "../../../hooks/reset-password/useResetPassword";
import { routes } from "../../../shell/routes";
import { useWorkflow } from "../../../shell/workflow";
import { checkPassword } from "../../../states/interaction/password";
import { useInteractionIsLoading } from "../../../states/states";
import { WorkflowResetPassword } from "../../../states/workflows";
import { useDeclareScreenName } from "../../../tracking/hooks";

export function WebResetPasswordScreen(): React.ReactElement {
  useDeclareScreenName("Forgot-Password-Reset");

  const workflow = useWorkflow<WorkflowResetPassword>(
    "latte.IntentResetPassword",
    "latte.NodeValidatedResetPasswordCode"
  );
  const intl = useIntl();

  const { password, onChangePassword, errorMessage, handleResetPassword } =
    useResetPassword(workflow, routes.web.recovery.resetPasswordSuccess);
  const passwordChecks = useMemo(() => checkPassword(password), [password]);

  const isLoading = useInteractionIsLoading();

  return (
    <form className="h-full" noValidate={true} onSubmit={handleResetPassword}>
      <WebScreenLayout>
        <WebScreenLayout.Body>
          <h1 className="font-heading text-heading-2 mb-3">
            <FormattedMessage
              id="web.screens.resetPassword.title"
              defaultMessage="Reset Your Password"
            />
          </h1>
          <p className="text-body-1 mb-12 font-sans text-center">
            <FormattedMessage
              id="web.screens.resetPassword.description.setup"
              defaultMessage="Please enter a new password with below requirements."
            />
          </p>
          <FormField className="mb-8 self-stretch">
            <FormField.InputContainer>
              <PasswordInput
                value={password}
                onChange={onChangePassword}
                required={true}
                readOnly={isLoading}
                autoComplete="new-password"
                placeholder={intl.formatMessage({
                  id: "web.screens.resetPassword.password.placeholder",
                  defaultMessage: "Password",
                })}
              />
            </FormField.InputContainer>
            {errorMessage ? (
              <FormField.ErrorMessage>{errorMessage}</FormField.ErrorMessage>
            ) : null}
          </FormField>

          <PasswordRequirement
            className="self-stretch"
            checks={passwordChecks}
            isFilled={password.length > 0}
          />

          <Button
            className="w-full mt-12"
            type="submit"
            preventFocusOnPress={true}
            disabled={!passwordChecks.ok}
            loading={isLoading}
          >
            <FormattedMessage
              id="web.screens.resetPassword.action"
              defaultMessage="Reset"
            />
          </Button>
        </WebScreenLayout.Body>
      </WebScreenLayout>
    </form>
  );
}
