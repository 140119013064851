import { useMemo, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { STATE, X_STATE } from "../search";
import { useAppNavigate } from "./hooks";
import { Platform } from "./platform";
import { UIState, parseUIState, stringifyUIState } from "./uistate_parse";

export function parseUIStateFromSearchParams(params: URLSearchParams): UIState {
  const state = params.get(STATE);
  const xState = params.get(X_STATE);
  if (xState) {
    return parseUIState(xState);
  }
  if (state) {
    return parseUIState(state);
  }
  return {};
}

export function useUIState(): UIState {
  const [params] = useSearchParams();
  return useMemo(() => {
    return parseUIStateFromSearchParams(params);
  }, [params]);
}

export function useUIStatePlatform(platform: Platform): void {
  const location = useLocation();
  const navigate = useAppNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const uiState = parseUIStateFromSearchParams(searchParams);
    if (uiState.platform === platform) {
      return;
    }
    uiState.platform = platform;
    searchParams.set(X_STATE, stringifyUIState(uiState));
    navigate(
      { pathname: location.pathname, search: searchParams.toString() },
      { replace: true, state: location.state }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
}
