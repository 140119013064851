import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../../components/Button";
import { FormField } from "../../../components/FormField";
import { MobileScreenLayout } from "../../../components/MobileScreenLayout";
import { PasswordInput } from "../../../components/PasswordInput";
import { PasswordRequirement } from "../../../components/PasswordRequirement";
import { ResetPasswordScreenProps } from "./useResetPasswordScreen";

export function ResetPasswordScreen(
  props: ResetPasswordScreenProps
): React.ReactElement {
  const intl = useIntl();

  const {
    password,
    onChangePassword,
    errorMessage,
    handleResetPassword,
    isLoading,
    passwordChecks,
  } = props;

  return (
    <form className="h-full" noValidate={true} onSubmit={handleResetPassword}>
      <MobileScreenLayout>
        <MobileScreenLayout.Body>
          <h1 className="font-heading text-heading-2 mb-1">
            <FormattedMessage
              id="mobile.screens.resetPassword.title"
              defaultMessage="Setup Your Password"
            />
          </h1>
          <p className="text-body-1 mb-6">
            <FormattedMessage
              id="mobile.screens.resetPassword.description.reset"
              defaultMessage="Your new password should contain at least 8 characters including both number(s) and letter(s)."
            />
          </p>
          <FormField className="mb-6">
            <FormField.Label>
              <FormattedMessage
                id="mobile.screens.resetPassword.password.label"
                defaultMessage="New Password"
              />
            </FormField.Label>
            <FormField.InputContainer>
              <PasswordInput
                value={password}
                onChange={onChangePassword}
                required={true}
                readOnly={isLoading}
                autoComplete="new-password"
                placeholder={intl.formatMessage({
                  id: "mobile.screens.resetPassword.password.placeholder",
                  defaultMessage: "Enter New Password",
                })}
              />
            </FormField.InputContainer>
            {errorMessage ? (
              <FormField.ErrorMessage>{errorMessage}</FormField.ErrorMessage>
            ) : null}
          </FormField>

          <PasswordRequirement checks={passwordChecks} />

          <Button
            className="w-full mt-6"
            type="submit"
            preventFocusOnPress={true}
            disabled={!passwordChecks.ok}
            loading={isLoading}
          >
            <FormattedMessage
              id="mobile.screens.resetPassword.action"
              defaultMessage="Continue"
            />
          </Button>
        </MobileScreenLayout.Body>
      </MobileScreenLayout>
    </form>
  );
}
