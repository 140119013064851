import React, { useMemo } from "react";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
import { PasswordChecks } from "../states/interaction/password";
import css from "./PasswordRequirement.module.css";
import { PasswordRequirementIcon } from "./PasswordRequirementIcon";

interface RequirementEntryProps extends React.PropsWithChildren {
  isPassed: boolean;
}

const RequirementEntry: React.FC<RequirementEntryProps> = (props) => {
  const { isPassed, children } = props;

  return (
    <p className={css["entry"]}>
      <span className={css["icon"]}>
        <PasswordRequirementIcon isPassed={isPassed} />
      </span>
      {children}
    </p>
  );
};

interface PasswordRequirementProps {
  className?: string;
  checks: PasswordChecks;
}

export const PasswordRequirement: React.FC<PasswordRequirementProps> = (
  props
) => {
  const { className, checks } = props;

  const minLengthValues = useMemo(
    () => ({ minLength: checks.minLength[1] }),
    [checks]
  );

  return (
    <section className={cn(css["container"], className)}>
      <h3 className={css["title"]}>
        <FormattedMessage
          id="mobile.components.passwordRequirement.title"
          defaultMessage="Requirement"
        />
      </h3>

      <RequirementEntry isPassed={checks.minLength[0]}>
        <FormattedMessage
          id="mobile.components.passwordRequirement.minLength"
          defaultMessage="minimum {minLength} characters"
          values={minLengthValues}
        />
      </RequirementEntry>

      <RequirementEntry isPassed={checks.alphabetic}>
        <FormattedMessage
          id="mobile.components.passwordRequirement.alphabetic"
          defaultMessage="include english letter"
        />
      </RequirementEntry>

      <RequirementEntry isPassed={checks.numeric}>
        <FormattedMessage
          id="mobile.components.passwordRequirement.numeric"
          defaultMessage="include number"
        />
      </RequirementEntry>
    </section>
  );
};
