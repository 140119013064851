import React, { useCallback, useMemo, useState } from "react";
import { useWorkflow } from "../../../../shell/workflow";
import {
  PasswordChecks,
  checkPassword,
} from "../../../../states/interaction/password";
import { WorkflowAuth } from "../../../../states/workflows";
import {
  SignupSetupMFAState,
  useSignupSetupMFA,
} from "../../../../hooks/auth/useSignupSetupMFA";
import { useDeclareScreenName } from "../../../../tracking/hooks";
import { useInteractionIsLoading } from "../../../../states/states";
import { AuthUserInitiate } from "../../../../states/interaction/intents";
import { Brand } from "../../../../shell/brand";
import { AppScreen, getScreenName } from "../../../../tracking/screen";

export interface SignupSetupMFAScreenProps extends SignupSetupMFAState {
  initiationIntent: AuthUserInitiate;
  maskedPhoneNumber: string;
  password: string;
  handleEmailOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePasswordOnChange: (value: string) => void;
  handleOnFocus: () => void;
  handleOnBlur: () => void;
  isLoading: boolean;
  passwordChecks: PasswordChecks;
}

export function useSignupSetupMFAScreen(
  brand: Brand | null
): SignupSetupMFAScreenProps {
  useDeclareScreenName(getScreenName(brand, AppScreen.SignupSetupMFA));

  const workflow = useWorkflow<WorkflowAuth>("latte.IntentCreateLoginID");

  const [password, setPassword] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const state = useSignupSetupMFA(workflow, password, isFocused);
  const isLoading = useInteractionIsLoading();

  const { onChangeEmail } = state;
  const handleEmailOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeEmail(e.target.value);
    },
    [onChangeEmail]
  );
  const handlePasswordOnChange = useCallback((value: string) => {
    setPassword(value);
  }, []);
  const handleOnFocus = useCallback(() => setIsFocused(true), []);
  const handleOnBlur = useCallback(() => setIsFocused(false), []);

  const passwordChecks = useMemo(() => checkPassword(password), [password]);

  return {
    ...state,
    initiationIntent: workflow.initiationIntent,
    maskedPhoneNumber: workflow.maskedPhoneNumber,
    password,
    handleEmailOnChange,
    handlePasswordOnChange,
    handleOnFocus,
    handleOnBlur,
    isLoading,
    passwordChecks,
  };
}
