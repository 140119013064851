import { useState, useMemo } from "react";
import { useDeclareScreenName } from "../../../tracking/hooks";
import { useLocation } from "react-router-dom";
import { WorkflowForgotPasswordV2 } from "../../../states/workflows";
import { maskPhoneNumber } from "../../../utils/phone";
import { useForgotPasswordProceed } from "../../../hooks/forgot-password/useForgotPasswordProceed";
import { Brand } from "../../../shell/brand";
import { AppScreen, getScreenName } from "../../../tracking/screen";

export interface ForgotPasswordBySMSProceedScreenProps {
  currentWorkflow: string | undefined;
  maskedPhoneNumber: string | undefined;
  canBack: boolean;
  handleActionOnPress: () => void;
  handleBackActionOnPress: () => void;
}

export function useForgotPasswordBySMSProceedScreen(
  bu: Brand | null
): ForgotPasswordBySMSProceedScreenProps {
  useDeclareScreenName(
    getScreenName(bu, AppScreen.ForgotPasswordByEmailProceed)
  );

  const { state } = useLocation();

  const [workflow] = useState<WorkflowForgotPasswordV2 | undefined>(
    state?.workflow
  );

  const { canBack, handleActionOnPress, handleBackActionOnPress } =
    useForgotPasswordProceed({
      finishURI: workflow?.finishURI,
      channel: "sms",
    });

  const maskedPhoneNumber = useMemo(
    () => maskPhoneNumber(workflow?.phoneNumber ?? ""),
    [workflow]
  );

  return {
    currentWorkflow: workflow?.current,
    maskedPhoneNumber: maskedPhoneNumber,
    canBack,
    handleActionOnPress,
    handleBackActionOnPress,
  };
}
