import {
  OverlayTriggerState,
  useOverlayTriggerState,
} from "@react-stately/overlays";
import React, { useState, useRef, useCallback, useMemo } from "react";
import { useErrorMessage } from "../../../hooks/errors";
import { useInteractionStep } from "../../../hooks/interaction";
import { useRefValue } from "../../../hooks/ref-value";
import { useRemainingSeconds } from "../../../hooks/timer";
import { useWorkflowNavigate } from "../../../shell/hooks";
import { useWorkflow } from "../../../shell/workflow";
import {
  WorkflowError,
  AuthgearError,
} from "../../../states/interaction/errors";
import {
  resendVerifyEmailOTP,
  verifyEmailOTP,
} from "../../../states/interaction/steps";
import { useInteractionIsLoading } from "../../../states/states";
import { WorkflowVerifyUser } from "../../../states/workflows";
import { useDeclareScreenName } from "../../../tracking/hooks";
import { MaximumAttemptExceededError } from "../../../components/MaximumAttemptExceededError";
import { Brand } from "../../../shell/brand";
import { AppScreen, getScreenName } from "../../../tracking/screen";
import { maskEmail } from "../../../utils/email";

const otpLength = 6;

export interface VerifyEmailOTPScreenProps {
  maskedEmail: string | undefined;
  remainingSeconds: number;
  otp: string;
  errorMessage: string;
  isLoading: boolean;
  isResending: boolean;
  rateLimitState: "ok" | "resend" | "blocked";
  handleOTPOnChange: (otp: string) => void;
  handleResendOnPress: () => void;
  handleFormSubmit: (e: React.FormEvent) => void;
  inputRef: React.Ref<HTMLInputElement>;
  errorDialogState: OverlayTriggerState;
  errorDialogContent: React.ReactNode;
}

export function useVerifyEmailOTPScreen(
  brand: Brand | null
): VerifyEmailOTPScreenProps {
  useDeclareScreenName(getScreenName(brand, AppScreen.VerifyEmailOTP));

  const workflow = useWorkflow<WorkflowVerifyUser>("latte.NodeVerifyEmail");
  const navigateWorkflow = useWorkflowNavigate();

  const interactionIsLoading = useInteractionIsLoading();
  const { trigger: resendOTP, isLoading: isResending } = useInteractionStep(
    workflow,
    resendVerifyEmailOTP
  );
  const isLoading = interactionIsLoading || isResending;
  const { trigger: verifyOTP } = useInteractionStep(workflow, verifyEmailOTP);
  const [error, setError] = useState<unknown>(() => {
    return workflow.failedAttemptRateLimitExceeded
      ? new WorkflowError(
          workflow,
          new AuthgearError(
            "", // name,
            "RateLimited", // reason
            "", // message,
            {
              bucket_name: "TrackFailedOTPAttemptBucket",
            }
          )
        )
      : null;
  });
  const errorMessage = useErrorMessage(error);

  const remainingSeconds = useRemainingSeconds(workflow.resendResetTimestamp!);

  const errorDialogState = useOverlayTriggerState({
    defaultOpen: false,
  });
  const [errorDialogContent, setErrorDialogContent] =
    useState<React.ReactNode>(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const [otp, setOTP] = useState("");
  const [rateLimitState, setRateLimitState] = useState<
    "ok" | "resend" | "blocked"
  >("ok");

  const handleResendOnPress = useCallback(() => {
    (async () => {
      try {
        const newWorkflow = await resendOTP(workflow);
        setRateLimitState("ok");
        setOTP("");
        inputRef.current?.focus();
        await navigateWorkflow(newWorkflow);
      } catch (err: unknown) {
        if (WorkflowError.is(err, "RateLimited")) {
          setRateLimitState("blocked");
          errorDialogState.open();
          setErrorDialogContent(<MaximumAttemptExceededError />);
          return;
        }

        setError(err);
      }
    })().catch(() => {});
  }, [errorDialogState, navigateWorkflow, resendOTP, workflow]);

  const doVerifyOTP = useRefValue((otp: string) => {
    if (otp.length < otpLength) {
      return;
    }

    verifyOTP(workflow, otp).then(
      async (workflow) => navigateWorkflow(workflow),
      (err) => {
        setError(err);
        setOTP("");

        if (WorkflowError.is(err, "RateLimited")) {
          setRateLimitState("resend");
        } else {
          inputRef.current?.focus();
        }
      }
    );
  });

  const handleOTPOnChange = useCallback(
    (otp: string) => {
      setError(null);
      setOTP(otp);

      if (otp.length === otpLength) {
        doVerifyOTP.current(otp);
      }
    },
    [doVerifyOTP]
  );

  const handleFormSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      doVerifyOTP.current(otp);
    },
    [doVerifyOTP, otp]
  );

  const furtherMaskedEmail = useMemo(
    () => maskEmail(workflow.maskedEmail ?? ""),
    [workflow.maskedEmail]
  );

  return {
    maskedEmail: furtherMaskedEmail,
    remainingSeconds,
    otp,
    errorMessage,
    isLoading,
    isResending,
    rateLimitState,
    handleOTPOnChange,
    handleResendOnPress,
    handleFormSubmit,
    inputRef,
    errorDialogState,
    errorDialogContent,
  };
}
