import { defineMessage } from "react-intl";
import { AppError } from "../errors";
import { parseOneAddress } from "email-addresses";

const EMAIL_REGEX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/;

export function isEmailValid(email: string): boolean {
  // We have two steps.
  // The first is to parse the email to see if it conforms to RFC AND does not contain name.
  // The second is to validate against a proprietary regex.
  const address = parseOneAddress(email);
  if (address == null) {
    return false;
  }
  if (address.type !== "mailbox") {
    return false;
  }
  if (address.name != null) {
    return false;
  }

  const passed = EMAIL_REGEX.test(email);
  if (!passed) {
    return false;
  }

  return true;
}

export function validateEmail(email: string): void {
  if (!isEmailValid(email)) {
    throw new AppError(
      {
        message: defineMessage({
          id: "mobile.errors.app.invalidEmail",
          defaultMessage: "The entered email is in an invalid format",
        }),
      },
      {
        message: defineMessage({
          id: "web.errors.app.invalidEmail",
          defaultMessage: "The entered email is in an invalid format",
        }),
      }
    );
  }
}

const MASK = "***";

function maskLocalPart(localPart: string): string {
  if (localPart === "") {
    return MASK;
  }

  let unmaskedLength = localPart.length - MASK.length;
  if (unmaskedLength <= 0) {
    unmaskedLength = 1;
  } else if (unmaskedLength > MASK.length) {
    unmaskedLength = MASK.length;
  }

  return `${localPart.slice(0, unmaskedLength)}${MASK}`;
}

// maskDomain masks the first label.
// If the domain has only one label, the label is NOT masked.
function maskDomain(domain: string): string {
  const labels = domain.split(".");
  if (labels.length <= 1) {
    return domain;
  }

  const maskedLabels = [MASK, ...labels.slice(1)];
  return maskedLabels.join(".");
}

export function maskEmail(email: string): string {
  const parts = email.split("@");
  if (parts.length !== 2) {
    return maskLocalPart(email);
  }

  const localPart = maskLocalPart(parts[0]);
  const domain = maskDomain(parts[1]);

  return `${localPart}@${domain}`;
}
