import { Overlay, useModalOverlay } from "@react-aria/overlays";
import {
  OverlayTriggerState,
  useOverlayTriggerState,
} from "@react-stately/overlays";
import React, { useCallback } from "react";
import { ScreenContainer } from "./ScreenContainer";
import css from "./DialogLayout.module.css";

interface DialogLayoutBodyProps extends React.PropsWithChildren {
  state: OverlayTriggerState;
  isDismissable?: boolean;
}

const DialogLayoutBody = (props: DialogLayoutBodyProps) => {
  const { state, isDismissable = true, children } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  const { modalProps, underlayProps } = useModalOverlay(
    {
      isDismissable,
      isKeyboardDismissDisabled: false,
    },
    state,
    ref
  );

  // ref ScreenStack overlay
  return (
    <div className={css["root"]} {...underlayProps}>
      <div ref={ref} className={css["container"]} {...modalProps}>
        {children}
      </div>
    </div>
  );
};

interface DialogLayoutProps extends React.PropsWithChildren {
  isDismissable?: boolean;
  onDismiss?: () => void;
}

export function DialogLayout(props: DialogLayoutProps): React.ReactElement {
  const { onDismiss, isDismissable, children } = props;
  const state = useOverlayTriggerState({
    isOpen: true,
    onOpenChange: useCallback(
      (isOpen: boolean) => {
        if (!isOpen) {
          onDismiss?.();
        }
      },
      [onDismiss]
    ),
  });

  return (
    <Overlay>
      <ScreenContainer type="overlay">
        <DialogLayoutBody state={state} isDismissable={isDismissable}>
          {children}
        </DialogLayoutBody>
      </ScreenContainer>
    </Overlay>
  );
}

DialogLayout.Body = DialogLayoutBody;
