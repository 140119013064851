import { nwdCheckEmailEndpoint } from "../config";
import { Brand } from "../shell/brand";
import { Locale } from "../intl/IntlProvider";
import { localeToLang, nwdBUs } from "./nwd";

export class NWDError extends Error {}

export async function checkNWDEmail({
  brands,
  email,
  locale,
}: {
  brands: Brand[];
  email: string;
  locale: Locale;
}): Promise<void> {
  const bu = brands.map((brand) => nwdBUs[brand]).filter((bu) => bu !== "");
  if (bu.length === 0) {
    return;
  }

  const lang = localeToLang(locale);
  const httpResp = await fetch(nwdCheckEmailEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, bu: bu.join(","), lang }),
  });

  interface CheckResponse {
    data: { isExist: boolean };
    meta?: {
      message: string;
    };
  }
  const resp: CheckResponse = await httpResp.json();
  if (!resp.data.isExist) {
    return;
  }

  const message = resp.meta?.message;
  if (typeof message === "string") {
    throw new NWDError(message);
  }
  throw new Error(JSON.stringify(resp));
}
