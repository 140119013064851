import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { MobileWorkflowProvider } from "../../shell/MobileWorkflowProvider";
import { routes } from "../../shell/routes";
import { useUIState } from "../../shell/uistate";
import {
  UserInitiate,
  initWorkflowAuth,
  isUserInitiate,
} from "../../states/interaction/intents";

function getRedirectRoute(userInitiate: UserInitiate): string | null {
  switch (userInitiate) {
    case "migrate":
      return routes.mobile.migrate.setupUser;
    case "reauth":
      return routes.mobile.reauth.default;
    default:
      break;
  }
  return null;
}

export function AuthenticateScreen(): React.ReactElement | null {
  const uiState = useUIState();
  const { user_initiate } = uiState;

  const redirectingRoute =
    user_initiate != null &&
    isUserInitiate(user_initiate) &&
    getRedirectRoute(user_initiate);

  useEffect(() => {
    if (redirectingRoute) {
      // Refresh the page to navigate to migration flow (instead of navigate through router)
      // to prevent screen push/pop transition animation.
      const url = new URL(window.location.href);
      url.pathname = redirectingRoute;
      window.location.replace(url);
    }
  }, [redirectingRoute]);

  if (redirectingRoute) {
    return null;
  }

  return (
    <MobileWorkflowProvider initializer={initWorkflowAuth}>
      <Outlet />
    </MobileWorkflowProvider>
  );
}
