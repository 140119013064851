import React from "react";
import { useShell } from "../../../shell/context";
import { useMigrateSetupUserScreen } from "./useMigrateSetupUserScreen";
import { MigrateSetupUserScreen as Screen } from "./MigrateSetupUserScreen";
import { MigrateSetupUserScreen as KDPScreen } from "./MigrateSetupUserScreen.kdp";

export function MigrateSetupUserScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useMigrateSetupUserScreen();

  switch (brand) {
    case "KDP":
      return <KDPScreen {...props} />;
    default:
      return <Screen {...props} />;
  }
}
