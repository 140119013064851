import React, { useLayoutEffect } from "react";

const inertClasses = [
  "pointer-events-none",
  "cursor-default",
  "[&_*]:select-none",
];

export function useInert(
  ref: React.RefObject<Element>,
  isInert: boolean
): void {
  useLayoutEffect(() => {
    const element = ref.current;
    if (isInert && element != null) {
      element.setAttribute("inert", "");
      element.classList.add(...inertClasses);

      return () => {
        element.removeAttribute("inert");
        element.classList.remove(...inertClasses);
      };
    }

    return () => {};
  }, [ref, isInert]);
}
