import { useLayoutEffect, useState } from "react";

export function useTimer(intervalMS: number, deps: unknown): Date {
  const [date, setDate] = useState(new Date());
  useLayoutEffect(() => {
    const updateDate = () => {
      setDate(new Date());
    };
    // Update it once immediately
    updateDate();
    // Update it periodically with interval
    const handle = setInterval(updateDate, intervalMS);

    return () => clearInterval(handle);
  }, [intervalMS, deps]);

  return date;
}

export function useRemainingSeconds(timestamp: number): number {
  const now = useTimer(1000, timestamp);
  return Math.ceil(Math.max(0, timestamp - now.getTime()) / 1000);
}
