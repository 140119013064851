import React from "react";
import {
  Outlet,
  redirect,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { AuthenticateScreen } from "../screens/auth/AutheticateScreen";
import { LoginSelectMFAMethodScreen } from "../screens/auth/login/LoginSelectMFAMethodScreen";
import { LoginSelectMFAMethodShell } from "../screens/auth/login/LoginSelectMFAMethodShell";
import { LoginVerifyMFAEmailScreen } from "../screens/auth/login/LoginVerifyMFAEmailScreen";
import { LoginVerifyMFAPasswordScreen } from "../screens/auth/login/LoginVerifyMFAPasswordScreen";
import { SignupSetupMFAScreen } from "../screens/auth/signup/SignupSetupMFAScreen";
import { VerifyPhoneOTPScreen } from "../screens/auth/VerifyPhoneOTPScreen";
import { ChangeEmailInitScreen } from "../screens/change-email/ChangeEmailInitScreen";
import { ChangeEmailScreen } from "../screens/change-email/ChangeEmailScreen";
import { ChangePasswordInitScreen } from "../screens/change-password/ChangePasswordInitScreen";
import { ChangePasswordScreen } from "../screens/change-password/ChangePasswordScreen";
import { ChangePasswordSuccessScreen } from "../screens/change-password/ChangePasswordSuccessScreen";
import { ErrorScreen } from "../screens/ErrorScreen";
import { ForgotPasswordBySMSProceedScreen } from "../screens/forgot-password/ForgotPasswordBySMSProceedScreen";
import { ForgotPasswordByEmailProceedScreen } from "../screens/forgot-password/ForgotPasswordByEmailProceedScreen";
import { ForgotPasswordBySMSScreen } from "../screens/forgot-password/ForgotPasswordBySMSScreen";
import { ForgotPasswordByEmailScreen } from "../screens/forgot-password/ForgotPasswordByEmailScreen";
import { ResetPasswordInitScreen } from "../screens/forgot-password/ResetPasswordInitScreen";
import { ResetPasswordScreen } from "../screens/forgot-password/ResetPasswordScreen";
import { ResetPasswordSuccessScreen } from "../screens/forgot-password/ResetPasswordSuccessScreen";
import { MigrateScreen } from "../screens/migrate/MigrateScreen";
import { MigrateSetupUserScreen } from "../screens/migrate/MigrateSetupUserScreen";
import { PreloadScreen } from "../screens/PreloadScreen";
import { VerifyEmailInitScreen } from "../screens/verify/VerifyEmailInitScreen";
import { VerifyEmailOTPScreen } from "../screens/verify/VerifyEmailOTPScreen";
import { WebAuthenticateScreen } from "../screens/web/auth/AutheticateScreen";
import { WebLoginLinkInitScreen } from "../screens/web/auth/login-link/LoginLinkInitScreen";
import { WebLoginLinkScreen } from "../screens/web/auth/login-link/LoginLinkScreen";
import { WebLoginLinkSuccessScreen } from "../screens/web/auth/login-link/LoginLinkSuccessScreen";
import { WebLoginSelectMFAMethodScreen } from "../screens/web/auth/login/LoginSelectMFAMethodScreen";
import { WebLoginVerifyMFAEmailScreen } from "../screens/web/auth/login/LoginVerifyMFAEmailScreen";
import { WebLoginVerifyMFAPasswordScreen } from "../screens/web/auth/login/LoginVerifyMFAPasswordScreen";
import { WebSignupSetupMFAScreen } from "../screens/web/auth/signup/SignupSetupMFAScreen";
import { WebVerifyPhoneOTPScreen } from "../screens/web/auth/VerifyPhoneOTPScreen";
import { WebErrorScreen } from "../screens/web/ErrorScreen";
import { WebForgotPasswordByEmailProceedScreen } from "../screens/web/forgot-password/ForgotPasswordByEmailProceedScreen";
import { WebResetPasswordInitScreen } from "../screens/web/forgot-password/ResetPasswordInitScreen";
import { WebResetPasswordScreen } from "../screens/web/forgot-password/ResetPasswordScreen";
import { WebResetPasswordSuccessScreen } from "../screens/web/forgot-password/ResetPasswordSuccessScreen";
import { SetupPasswordInitScreen } from "../screens/web/setup-password/SetupPasswordInitScreen";
import { SetupPasswordScreen } from "../screens/web/setup-password/SetupPasswordScreen";
import { SetupPasswordSuccessScreen } from "../screens/web/setup-password/SetupPasswordSuccessScreen";
import { sentryCreateBrowserRouter } from "../sentry";
import { TrackingContextProvider } from "../tracking/context";
import { ShellContextProvider } from "./context";
import { MobileShell } from "./MobileShell";
import { routes } from "./routes";
import { WebShell } from "./WebShell";
import { WebForgotPasswordByEmailScreen } from "../screens/web/forgot-password/ForgotPasswordByEmailScreen";
import { WebForgotPasswordBySMSScreen } from "../screens/web/forgot-password/ForgotPasswordBySMSScreen";
import { WebForgotPasswordBySMSProceedScreen } from "../screens/web/forgot-password/ForgotPasswordBySMSProceedScreen";
import { ReauthScreen } from "../screens/reauth/ReauthScreen";
import { ReauthPasswordScreen } from "../screens/reauth/ReauthPasswordScreen";
import { ReauthForgotPasswordByEmailScreen } from "../screens/reauth/ReauthForgotPasswordByEmailScreen";
import { ReauthForgotPasswordBySMSScreen } from "../screens/reauth/ReauthForgotPasswordBySMSScreen";
import { ReauthForgotPasswordByEmailProceedScreen } from "../screens/reauth/ReauthForgotPasswordByEmailProceedScreen";
import { ReauthForgotPasswordBySMSProceedScreen } from "../screens/reauth/ReauthForgotPasswordBySMSProceedScreen";

const mobileRoute: RouteObject = {
  element: <MobileShell />,
  errorElement: (
    <ShellContextProvider platform="mobile">
      <ErrorScreen />
    </ShellContextProvider>
  ),
  children: [
    {
      element: <AuthenticateScreen />,
      children: [
        {
          path: routes.mobile.auth.verifyPhoneOTP,
          element: <VerifyPhoneOTPScreen />,
        },
        {
          path: routes.mobile.auth.signup.setupMFA,
          element: <SignupSetupMFAScreen />,
        },
        {
          element: <LoginSelectMFAMethodShell />,
          children: [
            {
              path: routes.mobile.auth.login.selectMFA,
              element: <LoginSelectMFAMethodScreen />,
            },
            {
              path: routes.mobile.auth.login.verifyMFAEmail,
              element: <LoginVerifyMFAEmailScreen />,
            },
          ],
        },
        {
          path: routes.mobile.auth.login.verifyMFAPassword,
          element: <LoginVerifyMFAPasswordScreen />,
        },
      ],
    },
    {
      element: <VerifyEmailInitScreen />,
      children: [
        {
          path: routes.mobile.verify.email,
          element: <VerifyEmailOTPScreen />,
        },
      ],
    },
    {
      element: <MigrateScreen />,
      children: [
        {
          path: routes.mobile.migrate.setupUser,
          element: <MigrateSetupUserScreen />,
        },
      ],
    },
    {
      element: <Outlet />,
      children: [
        {
          path: routes.mobile.reauth.forgotPasswordProceed.email,
          element: <ReauthForgotPasswordByEmailProceedScreen />,
          handle: { hidesBackButtonWhen: "always" },
        },
        {
          path: routes.mobile.reauth.forgotPasswordProceed.sms,
          element: <ReauthForgotPasswordBySMSProceedScreen />,
          handle: { hidesBackButtonWhen: "always" },
        },
        {
          path: routes.mobile.reauth.forgotPassword.email,
          element: <ReauthForgotPasswordByEmailScreen />,
        },
        {
          path: routes.mobile.reauth.forgotPassword.sms,
          element: <ReauthForgotPasswordBySMSScreen />,
        },
        {
          path: routes.mobile.reauth.default,
          element: <ReauthScreen />,
          children: [{ index: true, element: <ReauthPasswordScreen /> }],
        },
      ],
    },
    // ---
    {
      element: <Outlet />,
      children: [
        {
          path: routes.mobile.recovery.forgotPassword.email,
          element: <ForgotPasswordByEmailScreen />,
        },
        {
          path: routes.mobile.recovery.forgotPasswordProceed.email,
          element: <ForgotPasswordByEmailProceedScreen />,
          handle: { hidesBackButtonWhen: "always" },
        },
        {
          path: routes.mobile.recovery.forgotPassword.sms,
          element: <ForgotPasswordBySMSScreen />,
        },
        {
          path: routes.mobile.recovery.forgotPasswordProceed.sms,
          element: <ForgotPasswordBySMSProceedScreen />,
          handle: { hidesBackButtonWhen: "always" },
        },
      ],
    },
    {
      element: <ResetPasswordInitScreen />,
      children: [
        {
          path: routes.mobile.recovery.resetPassword,
          element: <ResetPasswordScreen />,
          handle: { hidesBackButtonWhen: "non-cancellable" },
        },
        {
          path: routes.mobile.recovery.resetPasswordSuccess,
          element: <ResetPasswordSuccessScreen />,
          handle: { hidesBackButtonWhen: "non-cancellable" },
        },
      ],
    },
    // ---
    {
      element: <ChangePasswordInitScreen />,
      children: [
        {
          path: routes.mobile.settings.changePassword,
          element: <ChangePasswordScreen />,
        },
        {
          path: routes.mobile.settings.changePasswordSuccess,
          element: <ChangePasswordSuccessScreen />,
          handle: { hidesBackButtonWhen: "always" },
        },
      ],
    },
    // ---
    {
      element: <ChangeEmailInitScreen />,
      children: [
        {
          path: routes.mobile.settings.changeEmail,
          element: <ChangeEmailScreen />,
        },
        {
          path: routes.mobile.settings.changeEmailVerify,
          element: <VerifyEmailOTPScreen />,
        },
      ],
    },
    {
      element: <Outlet />,
      children: [
        {
          path: routes.mobile.preload,
          element: <PreloadScreen />,
        },
      ],
    },
    // ---
    {
      path: "*",
      loader: (args) => {
        const url = new URL(args.request.url);
        return redirect(routes.mobile.auth.default + url.search);
      },
      element: <MobileShell />,
    },
  ],
};

const webRoute: RouteObject = {
  path: routes.web.prefix,
  element: <WebShell />,
  errorElement: (
    <ShellContextProvider platform="web">
      <WebErrorScreen />
    </ShellContextProvider>
  ),
  children: [
    {
      element: <SetupPasswordInitScreen />,
      children: [
        {
          path: routes.web.recovery.setupPassword,
          element: <SetupPasswordScreen />,
        },
        {
          path: routes.web.recovery.setupPasswordSuccess,
          element: <SetupPasswordSuccessScreen />,
        },
      ],
    },
    {
      element: <WebAuthenticateScreen />,
      children: [
        {
          path: routes.web.auth.verifyPhoneOTP,
          element: <WebVerifyPhoneOTPScreen />,
        },
        {
          path: routes.web.auth.signup.setupMFA,
          element: <WebSignupSetupMFAScreen />,
        },
        {
          path: routes.web.auth.login.selectMFA,
          element: <WebLoginSelectMFAMethodScreen />,
        },
        {
          path: routes.web.auth.login.verifyMFAPassword,
          element: <WebLoginVerifyMFAPasswordScreen />,
        },
        {
          path: routes.web.auth.login.verifyMFAEmail,
          element: <WebLoginVerifyMFAEmailScreen />,
        },
      ],
    },
    {
      element: <WebLoginLinkInitScreen />,
      children: [
        {
          path: routes.web.auth.loginLink,
          element: <WebLoginLinkScreen />,
        },
        {
          path: routes.web.auth.loginLinkSuccess,
          element: <WebLoginLinkSuccessScreen />,
        },
      ],
    },
    {
      element: <Outlet />,
      children: [
        {
          path: routes.web.recovery.forgotPassword.email,
          element: <WebForgotPasswordByEmailScreen />,
        },
        {
          path: routes.web.recovery.forgotPasswordProceed.email,
          element: <WebForgotPasswordByEmailProceedScreen />,
        },
        {
          path: routes.web.recovery.forgotPassword.sms,
          element: <WebForgotPasswordBySMSScreen />,
        },
        {
          path: routes.web.recovery.forgotPasswordProceed.sms,
          element: <WebForgotPasswordBySMSProceedScreen />,
        },
      ],
    },
    {
      element: <WebResetPasswordInitScreen />,
      children: [
        {
          path: routes.web.recovery.resetPassword,
          element: <WebResetPasswordScreen />,
        },
        {
          path: routes.web.recovery.resetPasswordSuccess,
          element: <WebResetPasswordSuccessScreen />,
        },
      ],
    },
    {
      element: <Outlet />,
      children: [
        {
          path: routes.web.preload,
          element: <PreloadScreen />,
        },
      ],
    },
  ],
};

const router = sentryCreateBrowserRouter([mobileRoute, webRoute]);

export const Router: React.FC = () => {
  return (
    <TrackingContextProvider>
      <RouterProvider router={router} />
    </TrackingContextProvider>
  );
};
