import { useEffect, useState } from "react";

export function useDebounce<T>(
  value: T,
  periodMS: number
): [debounced: T, isDebounced: boolean] {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isDebounced, setIsDebounced] = useState(false);

  useEffect(() => {
    if (debouncedValue === value) {
      return () => {};
    }

    setIsDebounced(true);
    const handle = setTimeout(() => {
      setDebouncedValue(value);
      setIsDebounced(false);
    }, periodMS);
    return () => clearTimeout(handle);
  }, [debouncedValue, value, periodMS]);

  return [debouncedValue, isDebounced];
}
