import React from "react";
import { ScreenStack } from "../../../components/ScreenStack";
import { useShell } from "../../../shell/context";

export function LoginSelectMFAMethodShell(): React.ReactElement {
  const { brand } = useShell();
  return (
    <ScreenStack
      className="h-full"
      type={brand === "KDP" ? "screen" : "overlay"}
    />
  );
}
