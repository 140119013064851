import { isAppleDevice, isWebKit } from "@react-aria/utils";
import cn from "classnames";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  NavigationType,
  UNSAFE_RouteContext,
  useMatches,
  useNavigationType,
} from "react-router-dom";
import { ScreenProvider, ScreenType } from "./ScreenContainer";
import css from "./ScreenStack.module.css";

interface StackState {
  entries: Map<string, React.ReactNode>;
  appearScreenKeys: Set<string>;
}

interface ScreenStackProps {
  className?: string;
  type: ScreenType;
  skip?: number; // Handle nested screen transition at depth.
}

interface DisappearRequest {
  isPop: boolean;
}

// For WebKit on Apple devices (i.e. Safari), let gesture animation perform properly.
const shouldAnimatedPop = !(isWebKit() && isAppleDevice());

const ScreenStackEntry: React.FC<
  React.PropsWithChildren<{
    type: ScreenType;
    routeKey: string;
    activeRouteKey: string | null;
    navigationType: NavigationType;
    appearScreenKeys: Set<string>;
    willAppear: (key: string) => void;
    didDisappear: (key: string) => void;
  }>
> = (props) => {
  const {
    type,
    routeKey,
    activeRouteKey,
    navigationType,
    appearScreenKeys,
    willAppear,
    didDisappear,
    children,
  } = props;

  const isActive = routeKey === activeRouteKey;

  const [isPopOnMount] = useState(navigationType === NavigationType.Pop);
  const [disappearRequest, setDisappearRequest] = useState<
    DisappearRequest | undefined
  >(undefined);

  const isPop =
    navigationType === NavigationType.Replace
      ? isPopOnMount
      : navigationType === NavigationType.Pop;

  useLayoutEffect(() => {
    if (isActive === false) {
      setDisappearRequest({ isPop: navigationType === NavigationType.Pop });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const handleWillAppear = useCallback(() => {
    willAppear(routeKey);
  }, [willAppear, routeKey]);

  const handleDidDisappear = useCallback(() => {
    didDisappear(routeKey);
  }, [didDisappear, routeKey]);

  const isAnimated = isPop ? shouldAnimatedPop : true;
  const appear =
    isAnimated &&
    (type === "screen"
      ? Array.from(appearScreenKeys).filter((k) => k !== routeKey).length > 0
      : true);
  const popAnimation = shouldAnimatedPop ? "pop" : null;

  return (
    <ScreenProvider
      type={type}
      isActive={disappearRequest === undefined}
      appear={appear}
      appearAnimation={isPopOnMount ? popAnimation : "push"}
      disappearAnimation={disappearRequest?.isPop ? popAnimation : "push"}
      isPop={isPop}
      willAppear={handleWillAppear}
      didDisappear={handleDidDisappear}
    >
      {children}
    </ScreenProvider>
  );
};

export const ScreenStack: React.FC<ScreenStackProps> = (props) => {
  const { className, type, skip = 0 } = props;

  // useOutlet is unstable.
  const { outlet, matches } = useContext(UNSAFE_RouteContext);
  const [stack, setStack] = useState<StackState>(() => ({
    entries: new Map(),
    appearScreenKeys: new Set(),
  }));

  const isPop = useNavigationType() === "POP";
  const isPopRef = useRef(isPop);
  useLayoutEffect(() => {
    isPopRef.current = isPop;
  });

  const allMatches = useMatches();
  const routeID = matches[matches.length - 1].route.id;
  const matchIndex = allMatches.findIndex((s) => s.id === routeID);
  const childMatches = allMatches.slice(matchIndex + 1 + skip);

  const navigationType = useNavigationType();

  const activeRouteKey = childMatches.length === 0 ? null : childMatches[0].id;
  useEffect(() => {
    if (activeRouteKey != null) {
      const key = activeRouteKey;
      setStack((s) => ({ ...s, entries: new Map(s.entries).set(key, outlet) }));
    }
  }, [activeRouteKey, outlet]);

  const handleWillAppear = useCallback((key: string) => {
    setStack((s) => ({
      ...s,
      appearScreenKeys: new Set(s.appearScreenKeys).add(key),
    }));
  }, []);

  const handleDidDisappear = useCallback((key: string) => {
    setStack((s) => {
      const entries = new Map(s.entries);
      const appearScreenKeys = new Set(s.appearScreenKeys);
      entries.delete(key);
      appearScreenKeys.delete(key);
      return { entries, appearScreenKeys };
    });
  }, []);

  return (
    <div className={cn(css["stack"], className)}>
      {Array.from(stack.entries.entries()).map(([key, children]) => (
        <ScreenStackEntry
          key={key}
          type={type}
          routeKey={key}
          activeRouteKey={activeRouteKey}
          navigationType={navigationType}
          appearScreenKeys={stack.appearScreenKeys}
          willAppear={handleWillAppear}
          didDisappear={handleDidDisappear}
        >
          {children}
        </ScreenStackEntry>
      ))}
    </div>
  );
};
