import { useCallback, useContext, useEffect, useId } from "react";
import { useSearchParams } from "react-router-dom";
import { LatteGTMDataVariable } from "../@types/gtm";
import { useShell } from "../shell/context";
import { useUIState } from "../shell/uistate";
import { UserInitiate } from "../states/interaction/intents";
import { TrackingContext } from "./context";
import { base64URLDecode } from "../utils/base64";
import { Platform } from "../shell/platform";
import { CLIENT_ID } from "../search";
import { Brand } from "../shell/brand";
import { Workflow, WorkflowResetPassword } from "../states/workflows";

export function useLoadGTM(): void {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.googletagmanager.com/gtm.js?id=" + window.gtmContainerID;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
}

export function useLatteGTM(brand: Brand | null): void {
  const [searchParams] = useSearchParams();
  const uiState = useUIState();

  const one_id_client_id = searchParams.get("client_id") ?? "";
  const target_bu = brand ?? "K11";
  const channel = uiState.channel ?? "";
  const user_initiate = uiState.user_initiate;

  useEffect(() => {
    window.latteGTM = {
      one_id_client_id,
      target_bu,
      channel,
      user_initiate,
    };
  }, [channel, one_id_client_id, target_bu, user_initiate]);
}

function isWorkflowResetPassword(w?: Workflow): w is WorkflowResetPassword {
  if (w != null && Object.prototype.hasOwnProperty.call(w, "userID")) {
    return true;
  }
  return false;
}

export function useOneIDMemberID(
  workflows: Partial<Record<string, Workflow>>
): void {
  let userID: string | undefined;

  const resetPasswordWorkflow = Object.values(workflows).find(
    isWorkflowResetPassword
  );
  if (resetPasswordWorkflow != null) {
    userID = resetPasswordWorkflow.userID;
  }

  useEffect(() => {
    if (userID != null) {
      window.latteGTM.oneid_member_id = userID;
    }
    return () => {
      window.latteGTM.oneid_member_id = undefined;
    };
  }, [userID]);
}

function dispatchMobileTrackingEvent(
  eventName: string,
  params: Record<string, unknown>
) {
  document.dispatchEvent(
    new CustomEvent("latte:event", {
      detail: {
        type: "tracking",
        event_name: eventName,
        params,
      },
    })
  );
}

function dispatchWebTrackingEvent(
  eventName: string,
  params?: LatteGTMDataVariable
) {
  window.dataLayer.push({
    event: eventName,
    ...params,
  });
}

// useDeclareScreenName assumes the following
// 1. Screens are mounted according to their appearing order.
// 2. Map iterates its elements by insertion order.
// 3. Updating an element of a Map does not change the insertion order.
// 4. Deleting an element of a Map does not change the order of other elements.
//
// Since useLocation().key and listening on useLocation() involves too much
// coordination on timing.
// The approach here relies on the mounting order of the screens.
// When a screen is mounted, its screen name is pushed to the Map.
// The Map functions as a first-in last-out stack.
// When a screen is unmounted, its screen name is poped from the Map.
export function useDeclareScreenName(screenName: string): void {
  const reactID = useId();
  const { pushScreenName, popScreenName } = useContext(TrackingContext);
  useEffect(() => {
    pushScreenName(reactID, screenName);
    return () => {
      popScreenName(reactID);
    };
  }, [pushScreenName, popScreenName, reactID, screenName]);
}

export function useTrackScreenView(): void {
  const { brand, platform } = useShell();
  const uiState = useUIState();
  const { topScreenName } = useContext(TrackingContext);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (brand != null && topScreenName != null) {
      const target_bu = brand;
      const user_initiate = uiState.user_initiate;
      const client_id = searchParams.get(CLIENT_ID);
      switch (platform) {
        case "mobile":
          dispatchMobileTrackingEvent("screen_view", {
            screen_name: topScreenName,
            target_bu,
            user_initiate,
            client_id,
          });
          break;
        case "web":
          dispatchWebTrackingEvent("one_id_page_view", {
            screen_name: topScreenName,
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topScreenName]);
}

export function useTrackSelectLoginMethodClick(): (
  loginMethod: "email" | "password"
) => void {
  const { brand, platform } = useShell();
  const trigger = useCallback(
    (loginMethod: "email" | "password") => {
      if (brand != null) {
        switch (platform) {
          case "mobile":
            dispatchMobileTrackingEvent("select_login_method_click", {
              target_bu: brand,
              one_id_login_method: loginMethod,
            });
            break;
          case "web":
            dispatchWebTrackingEvent("select_login_method_click", {
              one_id_login_method: loginMethod,
            });
        }
      }
    },
    [brand, platform]
  );
  return trigger;
}

function decodeCustomAttributes(
  x_custom_attributes_b64url: string | undefined
): Record<string, unknown> | undefined {
  if (x_custom_attributes_b64url == null) {
    return undefined;
  }
  const bytes = base64URLDecode(x_custom_attributes_b64url);
  const str = new TextDecoder().decode(bytes);
  return JSON.parse(str);
}

export function useTrackRegistrationSuccess(): (
  initiationIntent: UserInitiate
) => void {
  const { brand, platform } = useShell();
  const x_custom_attributes_b64url = useUIState().x_custom_attributes_b64url;
  const customAttributes = decodeCustomAttributes(x_custom_attributes_b64url);
  const trigger = useCallback(
    (initiationIntent: UserInitiate) => {
      if (brand != null) {
        let params: Record<string, unknown> = {
          target_bu: brand,
          user_initiate: initiationIntent,
        };
        if (customAttributes != null) {
          params = {
            ...customAttributes,
            // Make sure target_bu and user_initiate have higher precedence.
            ...params,
          };
        }
        switch (platform) {
          case "mobile":
            dispatchMobileTrackingEvent("one_id_registration_success", params);
            break;
          case "web":
            dispatchWebTrackingEvent("one_id_registration_success");
        }
      }
    },
    [brand, customAttributes, platform]
  );
  return trigger;
}

export function useTrackError(
  platform: Platform,
  errorMessage: string,
  error: unknown
): void {
  const uiState = useUIState();
  useEffect(() => {
    const userInitiate = uiState.user_initiate;
    if (errorMessage) {
      switch (platform) {
        case "mobile":
          dispatchMobileTrackingEvent("one_id_reg_error_view", {
            errorMessage: errorMessage,
            user_initiate: userInitiate,
          });
          break;
        case "web":
          dispatchWebTrackingEvent("one_id_reg_error_view", {
            errorMessage: errorMessage,
          });
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, error]);
}
