import type { Brand } from "../shell/brand";
import type { Locale } from "../intl/IntlProvider";

export const nwdBUs: Record<Brand, string> = {
  K11: "k11",
  DP: "dp",
  AL: "al",
  KDP: "", // NOTE: unused, KDP does not call check email API.
};

export type Lang = "en" | "zh-hk" | "zh-cn";

export function localeToLang(locale: Locale): Lang {
  switch (locale) {
    case "en":
      return "en";
    case "zh-Hant-HK":
      return "zh-hk";
    case "zh-Hans-CN":
      return "zh-cn";
    default:
      return "en";
  }
}

export function getEmailCheckBrands(
  brand: Brand | null,
  emailCheckBrands: Brand[] | undefined
): Brand[] {
  // If not specified, use the brand as the email check brand
  if (emailCheckBrands == null) {
    // Skip email check by default for specific BU (KDP)
    const defaultDoEmailCheck = brand != null && nwdBUs[brand] !== "";
    emailCheckBrands = defaultDoEmailCheck ? [brand] : [];
  }
  return emailCheckBrands;
}
