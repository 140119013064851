import React from "react";
import { FormattedMessage } from "react-intl";
import { ScreenStack } from "../../../../components/ScreenStack";
import { Button } from "../../../../components/web/Button";
import { WebScreenLayout } from "../../../../components/WebScreenLayout";
import { useLoginSelectMFAMethod } from "../../../../hooks/auth/useLoginSelectMFAMethod";
import { useWorkflow } from "../../../../shell/workflow";
import { useInteractionIsLoading } from "../../../../states/states";
import { WorkflowAuth } from "../../../../states/workflows";
import { useDeclareScreenName } from "../../../../tracking/hooks";

export function WebLoginSelectMFAMethodScreen(): React.ReactElement {
  useDeclareScreenName("Auth-Mfa");

  const workflow = useWorkflow<WorkflowAuth>(
    "latte.NodeAuthenticateOOBOTPPhoneEnd",
    "latte.NodeAuthenticateEmailLoginLink"
  );

  const {
    errorMessage,
    handleRequestEmailOTP,
    handleRequestPassword,
    isSendingOTP,
    isRequestingPassword,
  } = useLoginSelectMFAMethod(workflow);

  const isLoading = useInteractionIsLoading();

  return (
    <>
      <WebScreenLayout>
        <WebScreenLayout.Body>
          <h1 className="mb-3 font-heading text-heading-2">
            <FormattedMessage
              id="web.screens.login.selectMFAMethod.title"
              defaultMessage="Log In Method"
            />
          </h1>
          <p className="mb-12 text-body-1">
            <FormattedMessage
              id="web.screens.login.selectMFAMethod.description"
              defaultMessage="Please select your log in method"
            />
          </p>

          <div className="w-full space-y-6">
            <Button
              variant="primary"
              className="w-full"
              onPress={handleRequestEmailOTP}
              disabled={isLoading}
              loading={isSendingOTP}
            >
              <FormattedMessage
                id="web.screens.login.selectMFAMethod.emailLogin"
                defaultMessage="By Email Magic Link (EML)"
              />
            </Button>

            <Button
              variant="primary"
              className="w-full"
              onPress={handleRequestPassword}
              disabled={isLoading}
              loading={isRequestingPassword}
            >
              <FormattedMessage
                id="web.screens.login.selectMFAMethod.passwordLogin"
                defaultMessage="By Password"
              />
            </Button>
          </div>

          {errorMessage ? (
            <p className="mt-4 text-body-2 text-error">{errorMessage}</p>
          ) : null}
        </WebScreenLayout.Body>
      </WebScreenLayout>

      <ScreenStack type="overlay" />
    </>
  );
}
