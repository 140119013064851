import {
  SendForgotPasswordCodeV2State,
  useSendEmailForgotPasswordCodeV2,
} from "../../../hooks/forgot-password/useSendForgotPasswordCodeV2";
import { useBackCallback } from "../../../shell/MobileShell";
import { Brand } from "../../../shell/brand";
import { useInteractionIsLoading } from "../../../states/states";
import { useDeclareScreenName } from "../../../tracking/hooks";
import { AppScreen, getScreenName } from "../../../tracking/screen";

export interface ForgotPasswordBySMSProps
  extends SendForgotPasswordCodeV2State {
  isLoading: boolean;
}

export function useForgotPasswordBySMSScreen(
  bu: Brand | null
): ForgotPasswordBySMSProps {
  useDeclareScreenName(getScreenName(bu, AppScreen.ForgotPasswordBySMS));

  const state = useSendEmailForgotPasswordCodeV2("sms");
  const isLoading = useInteractionIsLoading();
  useBackCallback(state.handleBack);

  return {
    ...state,
    isLoading,
  };
}
