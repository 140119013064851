import React, { useCallback, useMemo, useState } from "react";
import { useErrorMessage } from "../../../hooks/errors";
import { useInteractionStep } from "../../../hooks/interaction";
import { useValidateEmail } from "../../../hooks/validate-email";
import { useWorkflowNavigate } from "../../../shell/hooks";
import { useWorkflow } from "../../../shell/workflow";
import {
  PasswordChecks,
  checkPassword,
} from "../../../states/interaction/password";
import { migrateSetupMFA } from "../../../states/interaction/steps";
import { useInteractionIsLoading } from "../../../states/states";
import { WorkflowMigrate } from "../../../states/workflows";
import {
  useDeclareScreenName,
  useTrackRegistrationSuccess,
} from "../../../tracking/hooks";
import { validateEmail } from "../../../utils/email";

export interface MigrateSetupUserScreenProps {
  maskedPhoneNumber: string;
  email: string;
  password: string;
  handleEmailOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePasswordOnChange: (value: string) => void;
  isLoading: boolean;
  passwordChecks: PasswordChecks;
  errorMessage: string;
  immediateEmailError: unknown;
  handleOnFocus: () => void;
  handleOnBlur: () => void;
  handleOnSubmit: (e: React.FormEvent) => void;
}

export function useMigrateSetupUserScreen(): MigrateSetupUserScreenProps {
  useDeclareScreenName("One-Migration-Email-Password");

  const workflow = useWorkflow<WorkflowMigrate>("latte.IntentCreateLoginID");
  const navigateWorkflow = useWorkflowNavigate();

  const maskedPhoneNumber = workflow.maskedPhoneNumber ?? "";
  const defaultEmail = workflow.email ?? "";

  const [email, setEmail] = useState(defaultEmail);
  const [password, setPassword] = useState("");

  const passwordChecks = useMemo(() => checkPassword(password), [password]);

  const [isFocused, setIsFocused] = useState(false);
  const handleOnFocus = useCallback(() => setIsFocused(true), []);
  const handleOnBlur = useCallback(() => setIsFocused(false), []);
  const { deboundedError: emailError, error: immediateEmailError } =
    useValidateEmail(email, 1000, isFocused);

  const [error, setError] = useState<unknown>(null);
  const errorMessage = useErrorMessage(emailError ?? error);

  const handleEmailOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
      setError(null);
    },
    []
  );

  const handlePasswordOnChange = useCallback((value: string) => {
    setPassword(value);
  }, []);

  const { trigger } = useInteractionStep(workflow, migrateSetupMFA);
  const isLoading = useInteractionIsLoading();

  const trackRegistrationSuccess = useTrackRegistrationSuccess();

  const handleOnSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      setError(null);
      Promise.resolve()
        .then(() => validateEmail(email))
        .then(async () => trigger(workflow, email, password))
        .then(
          async (workflow) => {
            trackRegistrationSuccess("migrate");
            await navigateWorkflow(workflow);
          },
          (err) => setError(err)
        );
    },
    [
      email,
      navigateWorkflow,
      password,
      trigger,
      workflow,
      trackRegistrationSuccess,
    ]
  );

  return {
    maskedPhoneNumber,
    email,
    password,
    handleEmailOnChange,
    handlePasswordOnChange,
    isLoading,
    passwordChecks,
    errorMessage,
    immediateEmailError,
    handleOnFocus,
    handleOnBlur,
    handleOnSubmit,
  };
}
