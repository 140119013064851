import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../../components/Button";
import { LoadingOverlay } from "../../../../components/LoadingOverlay";
import { MobileScreenLayout } from "../../../../components/MobileScreenLayout";
import { LoginVerifyMFAEmailScreenProps } from "./useLoginVerifyMFAEmailScreen";

export function LoginVerifyMFAEmailScreen(
  props: LoginVerifyMFAEmailScreenProps
): React.ReactElement {
  const {
    errorMessage,
    handleCheckEmail,
    handleResend,
    remainingSeconds,
    maskedEmail,
    isLoading,
  } = props;

  const resendValues = useMemo(
    () => ({ remainingSeconds }),
    [remainingSeconds]
  );

  return (
    <>
      <MobileScreenLayout>
        <MobileScreenLayout.Body>
          <h1 className="mb-2 font-heading text-heading-2">
            <FormattedMessage
              id="mobile.screens.login.verifyMFAEmail.title"
              defaultMessage="Login by Email Verfication"
            />
          </h1>
          <p className="mb-8 text-body-1">
            <FormattedMessage
              id="mobile.screens.login.verifyMFAEmail.description"
              defaultMessage="Verification link has been sent to below email address:"
            />
          </p>
          <p className="mb-12 font-light text-center text-heading-2">
            {maskedEmail}
          </p>

          <Button className="w-full mb-7" onPress={handleCheckEmail}>
            <FormattedMessage
              id="mobile.screens.login.verifyMFAEmail.checkEmail"
              defaultMessage="Check Email"
            />
          </Button>

          <Button
            className="w-full text-body-1"
            variant="text"
            disabled={remainingSeconds > 0 || isLoading}
            loading={isLoading}
            onPress={handleResend}
          >
            {remainingSeconds === 0 ? (
              <FormattedMessage
                id="mobile.screens.login.verifyMFAEmail.resend.action"
                defaultMessage="Resend"
              />
            ) : (
              <FormattedMessage
                id="mobile.screens.login.verifyMFAEmail.resend.cooldown"
                defaultMessage="Resend in {remainingSeconds}s"
                values={resendValues}
              />
            )}
          </Button>

          {errorMessage ? (
            <p className="mt-4 text-body-2 text-error">{errorMessage}</p>
          ) : null}
        </MobileScreenLayout.Body>
      </MobileScreenLayout>

      {isLoading ? <LoadingOverlay /> : null}
    </>
  );
}
