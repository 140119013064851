import { useMemo, useState } from "react";
import { useDeclareScreenName } from "../../../tracking/hooks";
import { useLocation } from "react-router-dom";
import { WorkflowReauthForgotPassword } from "../../../states/workflows";
import { useForgotPasswordProceed } from "../../../hooks/forgot-password/useForgotPasswordProceed";
import { maskPhoneNumber } from "../../../utils/phone";
import { Brand } from "../../../shell/brand";
import { AppScreen, getScreenName } from "../../../tracking/screen";

export interface ReauthForgotPasswordBySMSProceedScreenProps {
  currentWorkflow: string | undefined;
  maskedPhoneNumber: string | undefined;
  canBack: boolean;
  handleActionOnPress: () => void;
  handleBackActionOnPress: () => void;
}

export function useReauthForgotPasswordBySMSProceedScreen(
  bu: Brand | null
): ReauthForgotPasswordBySMSProceedScreenProps {
  useDeclareScreenName(getScreenName(bu, AppScreen.ForgotPasswordBySMSProceed));

  const { state } = useLocation();

  const [workflow] = useState<WorkflowReauthForgotPassword | undefined>(
    state?.workflow
  );

  const { canBack, handleActionOnPress, handleBackActionOnPress } =
    useForgotPasswordProceed({
      finishURI: workflow?.finishURI,
      channel: "sms",
    });

  const maskedPhoneNumber = useMemo(
    () => maskPhoneNumber(workflow?.phoneNumber ?? ""),
    [workflow?.phoneNumber]
  );

  return {
    currentWorkflow: workflow?.current,
    maskedPhoneNumber,
    canBack,
    handleActionOnPress,
    handleBackActionOnPress,
  };
}
