import { useEffect } from "react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  createBrowserRouter,
} from "react-router-dom";
import {
  init,
  reactRouterV6Instrumentation,
  wrapCreateBrowserRouter,
} from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export function sentryInit(dsn: string): void {
  init({
    dsn,
    // Do not enable performance monitoring.
    // tracesSampleRate: 0,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
  });
}

export const sentryCreateBrowserRouter =
  wrapCreateBrowserRouter(createBrowserRouter);
