import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../../components/web/Button";
import { WebScreenLayout } from "../../../../components/WebScreenLayout";
import { useErrorMessage } from "../../../../hooks/errors";
import { useInteractionStep } from "../../../../hooks/interaction";
import { useWorkflowNavigate } from "../../../../shell/hooks";
import { useWorkflow } from "../../../../shell/workflow";
import { verifyLoginLink } from "../../../../states/interaction/steps";
import { WorkflowVerifyLoginLink } from "../../../../states/workflows";
import { useDeclareScreenName } from "../../../../tracking/hooks";
import { useInteractionIsLoading } from "../../../../states/states";

export function WebLoginLinkScreen(): React.ReactElement {
  useDeclareScreenName("Auth-Mfa-Email-Verify");

  const workflow = useWorkflow<WorkflowVerifyLoginLink>(
    "latte.IntentVerifyLoginLink"
  );
  const navigate = useWorkflowNavigate();

  const [error, setError] = useState<unknown>(null);
  const errorMessage = useErrorMessage(error);

  const { trigger } = useInteractionStep(workflow, verifyLoginLink);
  const isLoading = useInteractionIsLoading();
  const handleVerifyOnPress = useCallback(() => {
    setError(null);
    trigger(workflow).then(
      async (workflow) => navigate(workflow),
      (err) => setError(err)
    );
  }, [navigate, trigger, workflow]);

  return (
    <WebScreenLayout>
      <WebScreenLayout.Body>
        <h1 className="font-heading text-heading-2 mb-3 text-center">
          <FormattedMessage
            id="web.screens.loginLink.title"
            defaultMessage="Please verify"
          />
        </h1>
        <p className="text-body-1 mb-12 text-center">
          <FormattedMessage
            id="web.screens.loginLink.description"
            defaultMessage="Please click and continue login in the website"
          />
        </p>
        <Button
          variant="primary"
          className="w-full"
          loading={isLoading}
          onPress={handleVerifyOnPress}
        >
          <FormattedMessage
            id="web.screens.loginLink.action"
            defaultMessage="Verify to login"
          />
        </Button>
        {errorMessage ? (
          <p className="mt-4 text-body-2 text-error">{errorMessage}</p>
        ) : null}
      </WebScreenLayout.Body>
    </WebScreenLayout>
  );
}
