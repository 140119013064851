import { Platform } from "./platform";

export type Brand = "K11" | "DP" | "AL" | "KDP";

export const brands: Brand[] = ["K11", "DP", "AL", "KDP"];

const platformValidBrands: Record<Platform, Brand[]> = {
  mobile: ["K11", "AL", "KDP"],
  web: ["K11", "AL"],
};

export function tryMapBrand(
  raw: string | null | undefined,
  platform?: Platform
): Brand | null {
  if (raw == null) {
    return null;
  }

  const brand = brands.find((b) => b.toLowerCase() === raw.toLowerCase());
  if (brand == null) {
    return null;
  }

  if (platform != null && !platformValidBrands[platform].includes(brand)) {
    return null;
  }

  return brand;
}

export function mapBrand(rawStr: string | null, platform: Platform): Brand {
  return tryMapBrand(rawStr, platform) ?? "K11";
}
