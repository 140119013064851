import { useCallback, useEffect } from "react";
import { routes } from "../../shell/routes";
import { useAppCanGoBack, useAppNavigate } from "../../shell/hooks";
import { ForgotPasswordChannel } from "../../states/workflows";
import { LatteSignalType, listenToSignal } from "../../signal";

export interface ForgotPasswordProceedState {
  canBack: boolean;
  handleActionOnPress: () => void;
  handleBackActionOnPress: () => void;
}

export function useForgotPasswordProceed(options: {
  channel: ForgotPasswordChannel;
  finishURI?: string;
}): ForgotPasswordProceedState {
  const { finishURI, channel } = options;
  const navigate = useAppNavigate();
  const canBack = useAppCanGoBack();

  const handleActionOnPress = useCallback(() => {
    document.dispatchEvent(
      new CustomEvent("latte:event", {
        detail: {
          type: channel === "email" ? "openEmailClient" : "openSMSClient",
        },
      })
    );

    if (finishURI) {
      navigate(finishURI, { replace: true });
    } else if (canBack) {
      // Stay on the same page, the user can click the "Back to Login" button
      // to go back
    } else {
      navigate(routes.mobile.auth.default, { replace: true });
    }
  }, [channel, finishURI, canBack, navigate]);

  const handleBackActionOnPress = useCallback(() => {
    history.back();
  }, []);

  useEffect(() => {
    return listenToSignal(LatteSignalType.ResetPasswordCompleted, () => {
      handleBackActionOnPress();
    });
  }, [handleBackActionOnPress]);

  return {
    canBack,
    handleActionOnPress,
    handleBackActionOnPress,
  };
}
