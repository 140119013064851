import React, { useMemo } from "react";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
import { PasswordChecks } from "../../states/interaction/password";

interface RequirementEntryProps extends React.PropsWithChildren {
  className?: string;
  isPassed: boolean | undefined;
}

const RequirementEntry: React.FC<RequirementEntryProps> = (props) => {
  const { isPassed, className, children } = props;

  const color = useMemo(() => {
    switch (isPassed) {
      case true:
        return "text-success";
      case false:
        return "text-error";
      default:
        return "text-c-text";
    }
  }, [isPassed]);

  return <p className={cn("text-body-2", color, className)}>{children}</p>;
};

interface PasswordRequirementProps {
  className?: string;
  checks: PasswordChecks;
  isFilled: boolean;
}

export const PasswordRequirement: React.FC<PasswordRequirementProps> = (
  props
) => {
  const { className, checks, isFilled } = props;

  const minLengthValues = useMemo(
    () => ({ minLength: checks.minLength[1] }),
    [checks]
  );

  return (
    <section className={cn(className)}>
      <h3 className="text-body-2 font-semibold mb-2">
        <FormattedMessage
          id="web.components.passwordRequirement.title"
          defaultMessage="Password Requirement"
        />
      </h3>

      <RequirementEntry
        isPassed={isFilled ? checks.minLength[0] : undefined}
        className="mb-1"
      >
        <FormattedMessage
          id="web.components.passwordRequirement.minLength"
          defaultMessage="Minimum {minLength} characters"
          values={minLengthValues}
        />
      </RequirementEntry>

      <RequirementEntry
        isPassed={isFilled ? checks.alphabetic : undefined}
        className="mb-1"
      >
        <FormattedMessage
          id="web.components.passwordRequirement.alphabetic"
          defaultMessage="Include english letter"
        />
      </RequirementEntry>

      <RequirementEntry isPassed={isFilled ? checks.numeric : undefined}>
        <FormattedMessage
          id="web.components.passwordRequirement.numeric"
          defaultMessage="Include number"
        />
      </RequirementEntry>
    </section>
  );
};
