import zxcvbn from "zxcvbn";
import cn from "classnames";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";

type GuessableLevel = 0 | 1 | 2 | 3 | 4 | 5;

function extractGuessableLevel(
  result: zxcvbn.ZXCVBNResult | null
): GuessableLevel {
  if (result == null) {
    return 0;
  }
  return Math.floor(
    Math.min(5, Math.max(1, result.score + 1))
  ) as GuessableLevel;
}

const INDICATORS = new Array(5).fill(null);

function StrengthLevel({ level }: { level: GuessableLevel }) {
  switch (level) {
    case 0: {
      return <>{""}</>;
    }
    case 1: {
      return (
        <FormattedMessage
          id="web.components.passwordStrength.level.1"
          defaultMessage="Extremely guessable"
        />
      );
    }
    case 2: {
      return (
        <FormattedMessage
          id="web.components.passwordStrength.level.2"
          defaultMessage="Very guessable"
        />
      );
    }
    case 3: {
      return (
        <FormattedMessage
          id="web.components.passwordStrength.level.3"
          defaultMessage="Fair"
        />
      );
    }
    case 4: {
      return (
        <FormattedMessage
          id="web.components.passwordStrength.level.4"
          defaultMessage="Very unguessable"
        />
      );
    }
    case 5: {
      return (
        <FormattedMessage
          id="web.components.passwordStrength.level.5"
          defaultMessage="Extremely unguessable"
        />
      );
    }
  }
}

export const PasswordStrength: React.FC<{
  password: string;
  className?: string;
}> = ({ password, className }) => {
  const level = useMemo<GuessableLevel>(() => {
    if (!password) {
      return 0;
    }
    return extractGuessableLevel(zxcvbn(password));
  }, [password]);

  const activeIndicatorColor =
    level > 3 ? "bg-success" : level > 1 ? "bg-warning" : "bg-error";

  return (
    <div className={className}>
      <div className="flex flex-row space-x-1">
        {INDICATORS.map((_, idx) => (
          <div
            key={idx}
            className={cn(
              "h-1 w-9",
              level > idx ? activeIndicatorColor : "bg-[#D9D9D9]"
            )}
          />
        ))}
      </div>
      <p className="mt-1 font-sans text-c-text text-icon-text">
        <FormattedMessage
          id="web.components.passwordStrength.desc"
          defaultMessage="Password Strength: {level}"
          values={{ level: <StrengthLevel level={level} /> }}
        />
      </p>
    </div>
  );
};
