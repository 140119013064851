import React from "react";
import { FormattedMessage } from "react-intl";
import { WebScreenLayout } from "../../../components/WebScreenLayout";
import { ReactComponent as IconSuccess } from "../../../assets/success.svg";
import { useDeclareScreenName } from "../../../tracking/hooks";

export function WebResetPasswordSuccessScreen(): React.ReactElement {
  useDeclareScreenName("Forgot-Password-Reset-Success");

  return (
    <WebScreenLayout>
      <WebScreenLayout.Body>
        <IconSuccess className="text-c-button-primary w-25 h-25 self-center mb-3" />
        <h1 className="font-heading text-heading-2 mb-12 text-center">
          <FormattedMessage
            id="web.screens.resetPasswordSuccess.title"
            defaultMessage="Your password has been reset successfully"
          />
        </h1>

        <p className="text-body-2 text-c-button-text-disabled">
          <FormattedMessage
            id="web.screens.resetPasswordSuccess.description"
            defaultMessage="You can now login with the new password."
          />
        </p>
      </WebScreenLayout.Body>
    </WebScreenLayout>
  );
}
