import { REDIRECT_URI, STATE } from "../../search";
import { withLoading } from "../states";
import { checkRedirectURI } from "./api";
import { AuthgearError } from "./errors";

export interface InteractionResultFailure {
  error: string;
  workflowError?: AuthgearError;
}

export interface InteractionResultSuccess {
  error?: never;
  code: string;
}
export type InteractionResult =
  | InteractionResultFailure
  | InteractionResultSuccess;

export async function completeInteraction(
  result: InteractionResult
): Promise<void> {
  const query = new URLSearchParams(location.search);

  const redirectURI = new URL(query.get(REDIRECT_URI) ?? "");
  if (result.error != null) {
    redirectURI.searchParams.set("error", result.error);
    if (result.workflowError != null) {
      redirectURI.searchParams.set(
        "x_latte_error",
        btoa(result.workflowError.stringify()) // base64url
          .replace(/\+/g, "-")
          .replace(/\//g, "_")
          .replace(/=+$/, "")
      );
    }
  } else {
    redirectURI.searchParams.set("code", result.code);
  }

  const state = query.get(STATE);
  if (state != null) {
    redirectURI.searchParams.set(STATE, state);
  }

  // Keep UI in loading state while redirecting.
  await withLoading(async () => {
    await checkRedirectURI(redirectURI.toString());
    window.location.href = redirectURI.toString();
  });
}
