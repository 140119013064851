import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../../components/Button";
import { FormField } from "../../../components/FormField";
import { MobileScreenLayout } from "../../../components/MobileScreenLayout";
import { ChangeEmailScreenProps } from "./useChangeEmailScreen";

export function ChangeEmailScreen(
  props: ChangeEmailScreenProps
): React.ReactElement {
  const {
    email,
    maskedPhoneNumber,
    errorMessage,
    immediateEmailError,
    isLoading,
    handleEmailOnChange,
    handleOnFocus,
    handleOnBlur,
    handleFormSubmit,
  } = props;

  const intl = useIntl();

  return (
    <form className="h-full" noValidate={true} onSubmit={handleFormSubmit}>
      <MobileScreenLayout>
        <MobileScreenLayout.Body>
          <h1 className="font-heading text-heading-2 mb-1">
            <FormattedMessage
              id="mobile.screens.changeEmail.title"
              defaultMessage="Update OneLogin Credentials"
            />
          </h1>
          <p className="text-body-1 mb-6">
            <FormattedMessage
              id="mobile.screens.changeEmail.description"
              defaultMessage="Please enter your new email"
            />
          </p>

          <FormField className="mb-4">
            <FormField.Label>
              <FormattedMessage
                id="mobile.screens.changeEmail.phoneNumber.label"
                defaultMessage="Verified Mobile"
              />
            </FormField.Label>
            <FormField.TextInput value={maskedPhoneNumber} readOnly={true} />
          </FormField>

          <FormField className="mb-4">
            <FormField.Label>
              <FormattedMessage
                id="mobile.screens.changeEmail.email.label"
                defaultMessage="Email"
              />
            </FormField.Label>
            <FormField.InputContainer>
              <FormField.TextInput
                value={email}
                type="email"
                onChange={handleEmailOnChange}
                required={true}
                readOnly={isLoading}
                autoComplete="email"
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                placeholder={intl.formatMessage({
                  id: "mobile.screens.changeEmail.email.placeholder",
                  defaultMessage: "Enter Email Address",
                })}
              />
            </FormField.InputContainer>
            {errorMessage ? (
              <FormField.ErrorMessage>{errorMessage}</FormField.ErrorMessage>
            ) : null}
          </FormField>
        </MobileScreenLayout.Body>

        <MobileScreenLayout.FixedFooter>
          <Button
            className="w-full"
            type="submit"
            preventFocusOnPress={true}
            disabled={email.length === 0 || immediateEmailError != null}
            loading={isLoading}
          >
            <FormattedMessage
              id="mobile.screens.changeEmail.action"
              defaultMessage="Continue"
            />
          </Button>
        </MobileScreenLayout.FixedFooter>
      </MobileScreenLayout>
    </form>
  );
}
