import {
  SendForgotPasswordCodeV2State,
  useSendEmailForgotPasswordCodeV2,
} from "../../../../hooks/forgot-password/useSendForgotPasswordCodeV2";
import { useInteractionIsLoading } from "../../../../states/states";
import { useDeclareScreenName } from "../../../../tracking/hooks";

export interface ForgotPasswordBySMSProps
  extends SendForgotPasswordCodeV2State {
  isLoading: boolean;
}

export function useForgotPasswordBySMSScreen(): ForgotPasswordBySMSProps {
  useDeclareScreenName("Forgot-Password-SMS");

  const state = useSendEmailForgotPasswordCodeV2("sms");
  const isLoading = useInteractionIsLoading();

  return {
    ...state,
    isLoading,
  };
}
