import React from "react";
import { useShell } from "../../../shell/context";
import { useReauthForgotPasswordBySMSProceedScreen } from "./useReauthForgotPasswordBySMSProceedScreen";
import { KDPForgotPasswordBySMSProceedView } from "../../../components/forgot-password/ForgotPasswordBySMSProceedView.kdp";
import { ForgotPasswordBySMSProceedView } from "../../../components/forgot-password/ForgotPasswordBySMSProceedView";

export function ReauthForgotPasswordBySMSProceedScreen(): React.ReactElement {
  const { brand } = useShell();
  const props = useReauthForgotPasswordBySMSProceedScreen(brand);

  switch (brand) {
    case "KDP":
      return <KDPForgotPasswordBySMSProceedView {...props} />;
    default:
      return <ForgotPasswordBySMSProceedView {...props} />;
  }
}
